<template>
    <section v-if="current_alert_type" id="alert-modal" class="modal-default"
             :class="{'modal-default_active': current_alert_type}">
        <div class="modal-default__container">

            <button class="button modal-default__close" @click="closeAlertHandler">
                <img src="/assets/img/close.svg" alt="">
            </button>

            <h2 v-if="current_alert_type" :class="'alert-title-' + current_alert_type" class="title title__h2 modal-default__title">
                {{ generateAlertTitle(current_alert_type) }}
            </h2>

            <p class="text modal-default__text">
                {{ current_alert_message }}
            </p>

            <button class="button modal-default__confirm" @click="closeAlertHandler">
                {{ $t( "Closure" ) }}
            </button>

        </div>
    </section>
</template>

<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name: 'Alert',
        props: {
            msg: String
        },
        methods: {
            ...mapActions('alert', {
                initCurrentAlert: 'initCurrentAlert',
                clearCurrentAlert: 'clearCurrentAlert'
            }),
            generateAlertTitle(alert_type) {
                let title = ''

                switch (alert_type) {
                    case 'success':
                        title = this.$t("The transaction completed successfully")
                        break;
                    case 'info':
                        title = this.$t("Information")
                        break;
                    case 'error':
                        title = this.$t("Error")
                        break;
                    default:
                        title = this.$t("Message")
                }

                return title
            },
            closeAlertHandler() {
                let obj = this
                obj.closeAlert()
                setTimeout(function () {
                    obj.showAlert()
                }, 500)
            },
            closeAlert() {
                let obj = this
                obj.clearCurrentAlert()
                $('html').css("overflow", "auto")
            },
            showAlert() {
                let obj = this
                if (obj.current_alert_type) {
                    obj.closeAlert()
                }
                if (obj.alerts) {
                    obj.initCurrentAlert()

                    if (obj.current_alert_type) {
                        $('html').css("overflow", "hidden")
                    }
                }
            }
        },
        computed: {
            ...mapState({
                alerts: state => state.alert.alerts,
                current_alert_type: state => state.alert.current_alert_type,
                current_alert_message: state => state.alert.current_alert_message
            }),
        },
        created() {},
        watch: {
            alerts: {
                deep: true,
                handler(newValue) {
                    if (!this.current_alert_type
                        && newValue && newValue.length) {
                        this.showAlert()
                    }
                }
            },
        }
    }
</script>
<style>
    #alert-modal {
        z-index: 99999;
    }
    .alert-title-success {
        color: green;
    }
    .alert-title-info {
        color: blue;
    }
    .alert-title-error {
        color: red;
    }
</style>