import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const tourSupplierService = {
    saveTourSupplier,
    saveAlltourSupplier,
    getTourSupplier,
    getTags
};

function saveTourSupplier(item) {
    let params = {
    	"amadeus_code_supplier": item.amadeus_code_supplier,
        "tour_supplier_name": item.tour_supplier_name,
        "phone": item.phone,
        "contact_person": item.contact_person,
        "contact_person_phone": item.contact_person_phone,
        "active": item.active,
        "display": item.display,
        "flight": item.flight,
        "noftshon": item.noftshon,
        "organized_tours": item.organized_tours
    };
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/supplier/tour_supplier/' + item.id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function saveAlltourSupplier(item) {
    let params = {tourSuppliers: item};
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/tour/tourSuppliers/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getTourSupplier() {
    let params = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/supplier/tour_supplier',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getTags() {
    // let params = {
    //     type: 5
    // }
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tag/tag?type=5',
        headers: {
            'Content-Type': 'application/json',
        },
        // params: params,
        // data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}