import * as lang from "vuejs-datepicker/src/locale";
import i18n from '../common/plugins/vue-i18n';


function calculateItemPerSlide() {
    if (window.innerWidth < 650) {
      return 1;
    }
  
    if (window.innerWidth < 1080) {
      return 2;
    }
  
    return 4;
}
export default {
    filters: {
        formatDate: function (value) {
          return moment(String(value)).format("DD/MM/YYYY");
        },
        formatTime: function (value) {
          return moment(String(value)).format("HH:mm:ss");
        },
        formatDateTime: function (value) {
          return moment(String(value)).format("DD/MM/YYYY HH:mm");
        },
        getDay: function (value) {
          return moment(String(value)).day();
        },
        formatPrice: function (num) {
            if (!num) {
                return '0.00';
            }
            const number = (num / 1).toFixed(2).replace(',', '.');
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    data() {
        return {
            dateRangeLocal: {
                direction: this.sliderDirection,
                applyLabel: i18n.t("Apply"),
                cancelLabel: i18n.t("Cancel"),
                daysOfWeek: [i18n.t("Sun"), i18n.t("Mon"), i18n.t("Tue"), i18n.t("Wed"), i18n.t("Thu"), i18n.t("Fri"), i18n.t("Sat")],
                monthNames: [
                    i18n.t("Jan"), i18n.t("Feb"), i18n.t("Mar"), i18n.t("Apr"), i18n.t("May"), i18n.t("Jun"), i18n.t("Jul"), i18n.t("Aug"), i18n.t("Sep"), i18n.t("Oct"), i18n.t("Nov"), i18n.t("Dec")],
                firstDay: 0
            },
            languages: lang,
            enLogo: "/assets/img/logo/main-logo.png",
            heLogo: "/assets/img/logo/main-logo.png",
            currentLogo: "",
            itemPerSlide: calculateItemPerSlide(),
            dayString: [
                i18n.t('Sunday'),
                i18n.t('Monday'),
                i18n.t('Tuesday'),
                i18n.t('Wednesday'),
                i18n.t('Thursday'),
                i18n.t('Friday'),
                i18n.t('Saturday')
            ],
            phoneInputTranslations: {
                countrySelectorLabel: i18n.t("Country Code"),
                countrySelectorError: i18n.t("Choose a country"),
                phoneNumberLabel: i18n.t("Phone number"),
                example: i18n.t("Exemple :")
            },
        }
    },
    computed: {
        currentLang: function() {
            let currentLang = localStorage.getItem("language");
            currentLang = currentLang ? currentLang.toLowerCase() : 'he';
            return currentLang;
        },
        isOpenAside: function() {
            let isOpenAside = localStorage.getItem("isOpenAside");
            return isOpenAside;
        },
        sliderDirection() {
            if (this.currentLang=="en") {
              return "ltr"
            } else {
              return "rtl"
            }
        },
        user_type_logged_in () {
            return localStorage.getItem("user_type_logged_in");
        }
    },
    created() {
        window.addEventListener('resize', this.onResize);
        if (this.currentLang=="en") {
            this.$vuetify.rtl = false
          } else {
            this.$vuetify.rtl = true
          }
    },
    methods: {
        gsv(o, p, default_v=null) {
            return p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : default_v, o)
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        removeA(arr) {
            var what, a = arguments, L = a.length, ax;
            while (L > 1 && arr.length) {
                what = a[--L];
                while ((ax= arr.indexOf(what)) !== -1) {
                    arr.splice(ax, 1);
                }
            }
            return arr;
        },
        onResize(event) {
            this.itemPerSlide = calculateItemPerSlide();
        },
        calcEndDate(depDate, len) {
            return this.$moment(depDate).add(len, 'days').format('DD/MM/YYYY');
        },
        calcEndTime(depTime, len) {
            const h = len.split(":")[0]*1;
            const m = len.split(":")[1]*1;
            return this.$moment(depTime, 'HH:mm').add(h, 'hours').add(m, 'minutes').format('HH:mm');
        },
        getDayFromTimeStamp(str) {
            const dayNum =  moment(String(str)).day();
            return this.dayString[dayNum];
        },
        getDealNameFromType(type) {
            if (type == "NOFSHON") {
                return "Package deals";
            } else if (type == "ORGANIZED_TOUR") {
                return "Organized tours";
            } else if (type == "CHARTER") {
                return "Flights";
            }
        },
        getDealViewAllTextFromType(type) {
            if (type == "NOFSHON") {
                return "View all Vacations deals";
            } else if (type == "ORGANIZED_TOUR") {
                return "View all organized deals";
            } else if (type == "CHARTER") {
                return "View all Flights";
            }
        },
        formatDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();
      
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
      
            return [year, month, day].join("-");
        }
    },
    mounted() {
        if (this.$i18n && this.$i18n.locale == "he") {
            this.currentLogo = this.heLogo;
        } else {
            this.currentLogo = this.enLogo;
        }

        if (this.$i18n.locale == "en") {
            this.$vuetify.rtl = false
            document.getElementsByTagName("body")[0].classList.remove("rtl-type");
            document.getElementsByTagName("body")[0].classList.add("ltr-type");
        } else {
            this.$vuetify.rtl = true
            document.getElementsByTagName("body")[0].classList.remove("ltr-type");
            document.getElementsByTagName("body")[0].classList.add("rtl-type");
        }

        document.getElementsByTagName("html")[0].style.background = "#dfe0e3";
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
};