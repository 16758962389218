import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import vuetify from './common/plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import i18n from './common/plugins/vue-i18n';
import 'print-js';

import globalMixin from './utility/mixin';

import VModal from 'vue-js-modal';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Datepicker from 'vuejs-datepicker';

import VueCarousel from 'vue-carousel';

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import JsonExcel from 'vue-json-excel';

import Fragment from 'vue-fragment';

import { Chrome } from 'vue-color'

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import VueSocialSharing from 'vue-social-sharing'

import { VueEditor } from "vue2-editor";

import Print from 'vue-print-nb';

import VueGoogleAutocomplete from 'vue-google-autocomplete';
import './registerServiceWorker';

import CountryFlag from 'vue-country-flag';

import VueMask from 'v-mask'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const VueSweetalert2Options = {
  confirmButtonColor: '#29abe2',
};
Vue.use(VueSweetalert2, VueSweetalert2Options);

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

import draggable from 'vuedraggable'
Vue.component('draggable', draggable);

Vue.use(VueMask);

Vue.component('vue-select', vSelect);
Vue.component('Loading', Loading);
Vue.component('DateRangePicker', DateRangePicker);
Vue.component('Datepicker', Datepicker);
Vue.component('VueSlickCarousel', VueSlickCarousel);
Vue.component('downloadExcel', JsonExcel);
Vue.component('VueSlider', VueSlider);
Vue.component('chrome-picker', Chrome);

Vue.component('vue-editor', VueEditor);
Vue.component('vue-google-autocomplete', VueGoogleAutocomplete);
Vue.component('country-flag', CountryFlag);

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(VueCarousel);

Vue.use(require('vue-moment'));
// Vue.use(print);

Vue.use(Fragment.Plugin);
Vue.use(PerfectScrollbar);
Vue.use(VueSocialSharing);

Vue.use(Print);

Vue.mixin(globalMixin);

axios.interceptors.response.use(
  (response) => {
    if (response.status == 401 || response.data == 'Unauthorized') {
      router.push('/login').catch(err => {});
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
});
