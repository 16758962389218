import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const reseller_settingsService = {
    addSetting,
    updateSetting,
    getAllData,
    getOneSetting,
    delSetting
};

function addSetting(setting) {
    let params = setting;
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/tour/reseller_setting/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateSetting(id, setting) {
    let params = {
        affiliate_sale_fee: setting.affiliate_sale_fee,
        credit_sale_fee: setting.credit_sale_fee,
        direct_front_sale_fee: setting.direct_front_sale_fee,
        direct_sale_fee: setting.direct_sale_fee,
        front_sale_fee: setting.front_sale_fee,
        link_sale_fee: setting.link_sale_fee,
        second_link_sale_fee: setting.second_link_sale_fee
    };
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/tour/reseller_setting/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getAllData() {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/reseller_setting/',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getOneSetting(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/reseller_setting/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function delSetting(id) {
    let params = {
    };
    const config = {
        method: 'DELETE',
        url: appConfig.apiUrl + '/tour/reseller_setting/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}