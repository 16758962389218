import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const supplierService = {
    getAll,
    getSupplier,
    setFavoriteProduct,
    getSupplierDataById,
    updateSupplierDataById,
    getProductsById,
    syncSupplierProducts,
    updateOrder,
    activeSIMRamiLevi,
    numberPortingRamiLevi,
    sendVerifyCodeRamiLevi,
    getActiveSims
};

function getAll(params) {
    params = params || {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/supplier',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getSupplier(supplier_id) {
    let params = {
        supplier_id: supplier_id
    }
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/supplier/' + supplier_id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getSupplierDataById(supplier_id) {
    let params = {
        supplier_id: supplier_id
    }
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/supplier/' + supplier_id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function setFavoriteProduct(product_id, favorite) {
    let params = {
        favorite: favorite
    }
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/product/favorite/' + product_id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateSupplierDataById(supplier_id, supplier_data) {
    let params = supplier_data;
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/supplier/' + supplier_id,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getProductsById(supplier_id) {
    let params = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/product/products/' + supplier_id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function syncSupplierProducts(supplier_id) {
    const params = {};
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/product/sync/' + supplier_id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateOrder(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/supplier/order',
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function activeSIMRamiLevi(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/mobile/AreenPrepaid/ActiveSIM_NewRamiLevy_Save',
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function sendVerifyCodeRamiLevi(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/mobile/AreenPrepaid/ActiveSIM_NeodRamiLevy_SendVerficationCode',
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function numberPortingRamiLevi(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/mobile/AreenPrepaid/ActiveSIM_NeodRamiLevy_Save',
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getActiveSims() {
    const config = {
        method: 'get',
        url: appConfig.apiUrl + '/mobile/AreenPrepaid/get_active_sims',
        data: {},
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}