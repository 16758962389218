import Vue from 'vue';
import VueRouter from 'vue-router';
// import VueCookies from 'vue-cookies';
import store from '../store';
// import i18n from '../common/plugins/vue-i18n';
// import appConfig from '../appConfig';
// import axios from 'axios';
import menuConfig from '../common/menu.config.json';
// import { isMobile } from 'mobile-device-detect';

const DefaultContainer = () => import('../containers/DefaultContainer');
const AuthContainer = () => import('../containers/AuthContainer');
const MobileContainer = () => import('../containers/MobileContainer');
const PublicContainer = () => import('../containers/PublicContainer');
const VuetifyContainer = () => import('../containers/VuetifyContainer');
const TvContainer = () => import('../containers/TvContainer');
const EndUserContainer = () => import('../containers/EndUserContainer');

const Login = () => import('../views/Login');
const Reseller_Login = () => import('../views/Reseller_Login');
const User_Login = () => import('../views/end_user/User_Login');
const Dashboard = () => import('../views/Dashboard');
const Communication = () => import('../views/communication/Communication');
const CommunicationSupplier = () =>
  import('../views/communication/CommunicationSupplier');
const CommunicationProduct = () =>
  import('../views/communication/CommunicationProduct');
const Payment = () => import('../views/Payment');
const SupplierPayment = () => import('../views/SupplierPayment');
const SupplierPaymentConfirmation = () =>
  import('../views/SupplierPaymentConfirmation');
const SupplierPaymentResult = () => import('../views/SupplierPaymentResult');
const SupplierPaymentSuccess = () => import('../views/SupplierPaymentSuccess');
const CommunicationPaymentSuccess = () =>
  import('../views/communication/CommunicationPaymentSuccess');
const ResellerAccount = () => import('../views/ResellerAccount');

const Tourism = () => import('../views/tourism/Tourism');
const TourismNofshonAll = () => import('../views/tourism/Tourism2');
const TourismOrganizedAll = () => import('../views/tourism/Tourism3');
const TourismProduct = () => import('../views/tourism/TourismProduct');
const TourismOrganized = () => import('../views/tourism/TourismOrganized');
const TradeShows = () => import('../views/tourism/trade_shows/TradeShows');
const TradeShowsCategory = () =>
  import('../views/tourism/trade_shows/TradeShowsCategory');
const TradeShowsDetails = () =>
  import('../views/tourism/trade_shows/TradeShowsDetails');
const TradeShowsUpcoming = () =>
  import('../views/tourism/trade_shows/TradeShowsUpcoming');
const SelectedResult = () =>
  import('../views/tourism/trade_shows/SelectedResult');

const FlightHomePage = () => import('../views/tourism/flight/FlightHomePage');
const FlightHome = () => import('../views/tourism/flight/FlightHome');
const FlightAll = () => import('../views/tourism/flight/FlightAll');
const FlightDestinationDates = () =>
  import('../views/tourism/flight/FlightDestinationDates');
const FlightOrderSummary = () =>
  import('../views/tourism/flight/FlightOrderSummary');
const FlightOrderSummaryBooking = () =>
  import('../views/tourism/flight/FlightOrderSummaryBooking');
const MobileFlight = () =>
  import('../views/tourism/flight/mobile/MobileFlight');
const MobileFlightHome = () =>
  import('../views/tourism/flight/mobile/FlightHome');
const MobileFlightAll = () =>
  import('../views/tourism/flight/mobile/FlightAll');
const OrderApproved = () =>
  import('../views/tourism/flight/mobile/OrderApproved');
const MobileFlightDestinationDates = () =>
  import('../views/tourism/flight/mobile/FlightDestinationDates');
const MobileFlightOrderReseller = () =>
  import('../views/tourism/flight/mobile/FlightOrderReseller');

const GiftCard = () => import('../views/giftcard/GiftCard');
const GiftCardSupplier = () => import('../views/giftcard/GiftCardSupplier');
const GiftCardProduct = () => import('../views/giftcard/GiftCardProduct');
const GiftCardPaymentVerificationPublic = () =>
  import('../views/giftcard/GiftCardPaymentVerificationPublic');
const GiftCardPaymentResultPublic = () =>
  import('../views/giftcard/GiftCardPaymentResultPublic');
const GiftCardPaymentResult = () =>
  import('../views/giftcard/GiftCardPaymentResult');
const Game = () => import('../views/game/Game');
const GameSupplier = () => import('../views/game/GameSupplier');
const GameProduct = () => import('../views/game/GameProduct');
const GamePaymentVerificationPublic = () =>
  import('../views/game/GamePaymentVerificationPublic');
const GamePaymentResultPublic = () =>
  import('../views/game/GamePaymentResultPublic');
const GamePaymentResult = () => import('../views/game/GamePaymentResult');

const Page404 = () => import('../views/Page404');
const ManageTranslation = () => import('../views/ManageTranslation');
const TourOrder1 = () => import('../views/tour_order/TourOrder1');
const OrderDetails = () => import('../views/tour_order/OrderDetails');
const OrderPassport = () => import('../views/tour_order/OrderPassport');
const PassportDetail = () => import('../views/tour_order/PassportDetail');
const OrderConfirmation = () => import('../views/tour_order/OrderConfirmation');
const TourOrder6 = () => import('../views/tour_order/TourOrder6');
const ResellerTourSettings = () => import('../views/ResellerTourSettings');
const RegisterReseller1 = () =>
  import('../views/register_reseller/RegisterReseller1');
const RegisterReseller2 = () =>
  import('../views/register_reseller/RegisterReseller2');
const RegisterReseller3 = () =>
  import('../views/register_reseller/RegisterReseller3');
const RegisterReseller4 = () =>
  import('../views/register_reseller/RegisterReseller4');
const SalesReport = () => import('../views/report/SalesReport');
const TourismReport = () => import('../views/report/TourismReport');
const PaymentReport = () => import('../views/report/PaymentReport');
const SalesPaymentReport = () => import('../views/report/SalesPaymentReport');
const ReceiptsInvoices = () => import('../views/report/ReceiptsInvoices');
const ObligoReport = () => import('../views/report/ObligoReport');

const TourCategory = () => import('../views/TourCategory');
const TourPlaces = () => import('../views/TourPlaces');
const TourPlacesDetail = () => import('../views/TourPlacesDetail');
const Sastech = () => import('../views/sasa_tech/Sastech');
const SastechByCompany = () => import('../views/sasa_tech/Sastech.by_company');
const SastechProduct = () => import('../views/sasa_tech/Product');
const SastechPackage = () => import('../views/sasa_tech/Package');
const ReportDetails = () => import('../views/report/ReportDetails');
const Tv1 = () => import('../views/tv/Tv1');
const Tv2 = () => import('../views/tv/Tv2');
const Tv3 = () => import('../views/tv/Tv3');

const CartSystem = () => import('../views/cart_system/CartSystem');
const CartOrderHistory = () => import('../views/cart_system/CartOrderHistory');
const CartTempOrder = () => import('../views/cart_system/CartTempOrder');
const OrderSuccess = () => import('../views/cart_system/OrderSuccess');
const OrderPayment = () => import('../views/cart_system/OrderPayment');

const MenuManagementCancelations = () =>
  import('../views/management/MenuManagementCancelations');
const AdminSalesReport = () => import('../views/report/AdminSalesReport');
const CustomerLists = () => import('../views/management/CustomerLists');
const ProductManagement = () =>
  import('../views/management/category_products/ProductManagement');
const CancelFreeChargeLoading = () =>
  import('../views/management/CancelFreeChargeLoading');
const CancelFreeChargeUpload = () =>
  import('../views/management/CancelFreeChargeUpload');
const PermissionsProfile = () =>
  import('../views/permissions_profile/PermissionsProfile');

const ShanivPackage = () => import('../views/management/ShanivPackage');
const CancellationSystem = () =>
  import('../views/management/cancel/CancellationSystem');
const Cancellation = () => import('../views/management/cancel/Cancellation');
const CancellationsTransaction = () =>
  import('../views/management/cancel/CancellationsTransaction');
const CancelPayment = () => import('../views/management/cancel/CancelPayment');
const CancelPrepaid = () => import('../views/management/cancel/CancelPrepaid');
const CancellationCellcom = () =>
  import('../views/management/cancel/CancellationCellcom');
const CancellationGiftcart = () =>
  import('../views/management/cancel/CancellationGiftcart');
const CancellationSuccess = () =>
  import('../views/management/cancel/CancellationSuccess');
const CancellationPartialSuccess = () =>
  import('../views/management/cancel/CancellationPartialSuccess');
const CancellationFaild = () =>
  import('../views/management/cancel/CancellationFaild');

const SCPercentage = () =>
  import('../views/management/system_configuration/Percentage');
const SCCommissions = () =>
  import('../views/management/system_configuration/Commissions');
const SCProductCommission = () =>
  import('../views/management/system_configuration/ProductCommission');
const SCProviderPermission = () =>
  import('../views/management/system_configuration/ProviderPermission');
const SCResellerProfile = () =>
  import('../views/management/system_configuration/ResellerProfile');
const SCInvoiceManagment = () =>
  import('../views/management/system_configuration/InvoiceManagment');
const SCAds = () => import('../views/management/system_configuration/Ads');
const SCAdsAdvertisers = () =>
  import('../views/management/system_configuration/AdsAdvertisers');
const SCTagManagement = () =>
  import(
    '../views/management/system_configuration/tag_management/TagManagement'
  );
const SCTagEditCategory = () =>
  import('../views/management/system_configuration/TagEditCategory');
const SCVat = () => import('../views/management/system_configuration/Vat');
const SCPermissionsProfiles = () =>
  import('../views/management/system_configuration/PermissionsProfiles');

const CPProductPromotion = () =>
  import('../views/management/category_products/ProductPromotion');
const CPShanivManagement = () =>
  import('../views/management/category_products/ShanivManagement');
const CPSuppliers = () =>
  import('../views/management/category_products/suppliers/Suppliers');
const CPSupplierProfiles = () =>
  import('../views/management/category_products/SupplierProfiles');
const CPProductsProfiles = () =>
  import('../views/management/category_products/ProductsProfiles');
const CPCommissionsProfile = () =>
  import('../views/management/category_products/CommissionsProfile');
const CPTourismProfiles = () =>
  import('../views/management/category_products/TourismProfiles');
const CPProductCodes = () =>
  import('../views/management/category_products/ProductCodes');
const CPManualCards = () =>
  import('../views/management/category_products/ManualCards');
const CPGiftsPoints = () =>
  import('../views/management/category_products/GiftsPoints');
const CPCustomerHomeProducts = () =>
  import('../views/management/category_products/CustomerHomeProducts');

const TGeneralSetting = () =>
  import('../views/management/tourism/GeneralSetting');
const TTourSupplier = () => import('../views/management/tourism/TourSupplier');
const TDestination = () => import('../views/management/tourism/Destination');
const TApiSettings = () => import('../views/management/tourism/ApiSettings');

const DRBusinessDetails = () =>
  import('../views/management/distributers_resellers/BusinessDetails');
const DRContractDetails = () =>
  import('../views/management/distributers_resellers/ContractDetails');
const DRProviders = () =>
  import('../views/management/distributers_resellers/Providers');
const DRPercentageProfit = () =>
  import('../views/management/distributers_resellers/PercentageProfit');
const DRPaymentProfit = () =>
  import('../views/management/distributers_resellers/PaymentProfit');
const DRTourismPercentage = () =>
  import('../views/management/distributers_resellers/TourismPercentage');
const DRUsers = () =>
  import('../views/management/distributers_resellers/Users');

const SastechCategoryM = () =>
  import('../views/sasa_tech/mobile/SastechCategoryM');
const PackagesM = () => import('../views/sasa_tech/mobile/PackagesM');
const ProductsM = () => import('../views/sasa_tech/mobile/ProductsM');
const CartM = () => import('../views/sasa_tech/mobile/CartM');

const TourOrder = () => import('../views/tourism/TourOrder');
const MTourOrderDetail = () => import('../views/tourism/MTourOrderDetail');
const TourismOrderDetail = () => import('../views/tourism/TourismOrderDetail');

// End User Routers
const EndUserHome = () => import('../views/end_user/Home');
const EndUserAccount = () => import('../views/end_user/Account');
const EndUserGame = () => import('../views/end_user/game/Game');
const EndUserGameSupplier = () => import('../views/end_user/game/GameSupplier');
const EndUserGameProduct = () => import('../views/end_user/game/GameProduct');
const EndUserGiftCard = () => import('../views/end_user/giftcard/GiftCard');
const EndUserGiftCardSupplier = () =>
  import('../views/end_user/giftcard/GiftCardSupplier');
const EndUserGiftCardProduct = () =>
  import('../views/end_user/giftcard/GiftCardProduct');
const EndUserCommunication = () =>
  import('../views/end_user/communication/Communication');
const EndUserCommunicationSupplier = () =>
  import('../views/end_user/communication/CommunicationSupplier');
const EndUserCommunicationProduct = () =>
  import('../views/end_user/communication/CommunicationProduct');

const PaymentVerificationPublic = () =>
  import('../views/PaymentVerificationPublic');
const PaymentResultPublic = () => import('../views/PaymentResultPublic');

Vue.use(VueRouter);
// Vue.use(VueCookies);

const routes = [
  {
    path: '/',
    component: DefaultContainer,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: { root: 0 },
      },
      {
        path: 'communication',
        name: 'Communication',
        component: Communication,
        meta: { root: 0 },
      },
      {
        path: 'communication/supplier/:id',
        name: 'CommunicationSupplier',
        component: CommunicationSupplier,
      },
      {
        path: 'communication/supplier/:supplier_id/product/:id',
        name: 'CommunicationProduct',
        component: CommunicationProduct,
      },
      {
        path: 'communication/payment-success/:id',
        name: 'CommunicationPaymentSuccess',
        component: CommunicationPaymentSuccess,
      },
      {
        path: 'payment',
        name: 'Payment',
        component: Payment,
        meta: { root: 0 },
      },
      {
        path: 'payment/:supplier_id',
        name: 'SupplierPayment',
        component: SupplierPayment,
      },
      {
        path: 'payment/:supplier_id/confirmation',
        name: 'SupplierPaymentConfirmation',
        component: SupplierPaymentConfirmation,
      },
      {
        path: 'payment/:supplier_id/result',
        name: 'SupplierPaymentResult',
        component: SupplierPaymentResult,
      },
      {
        path: 'payment/:supplier_id/success/:transaction_id?',
        name: 'SupplierPaymentSuccess',
        component: SupplierPaymentSuccess,
      },
      {
        path: 'tourism',
        name: 'Tourism',
        component: Tourism,
        meta: { root: 0 },
      },
      {
        path: 'tourism/NOFSHON',
        name: 'TourismNofshonAll',
        component: TourismNofshonAll,
        meta: { root: 0 },
      },
      {
        path: 'tourism/ORGANIZED_TOUR',
        name: 'TourismOrganizedAll',
        component: TourismOrganizedAll,
        meta: { root: 0 },
      },
      {
        path: 'tourism-product/:id/:rowId/:destinationId?/:dealType?',
        name: 'TourismProduct',
        component: TourismProduct,
      },
      {
        path: 'tourism-organized/:id/:rowId/:destinationId?/:dealType?',
        name: 'TourismOrganized',
        component: TourismOrganized,
      },
      {
        path: 'tourism/trade-shows/trade-shows',
        name: 'TradeShows',
        component: TradeShows,
      },
      {
        path: 'tourism/trade-shows/category',
        name: 'TradeShowsCategory',
        component: TradeShowsCategory,
      },
      {
        path: 'tourism/trade-shows/details',
        name: 'TradeShowsDetails',
        component: TradeShowsDetails,
      },
      {
        path: 'tourism/trade-shows/selected-result',
        name: 'SelectedResult',
        component: SelectedResult,
      },
      {
        path: 'tourism/trade-shows/upcoming',
        name: 'TradeShowsUpcoming',
        component: TradeShowsUpcoming,
      },
      {
        path: 'tourism/flight',
        name: 'FlightHomePage',
        component: FlightHomePage,
      },
      {
        path: 'tourism/flight/home',
        name: 'FlightHome',
        component: FlightHome,
      },
      {
        path: 'tourism/m/flight/home',
        name: 'MobileFlightHome',
        component: MobileFlightHome,
      },
      {
        path: 'tourism/flight/all',
        name: 'FlightAll',
        component: FlightAll,
      },
      {
        path: 'tourism/m/flight/all',
        name: 'MobileFlightAll',
        component: MobileFlightAll,
      },
      {
        path: 'tourism/flight/destination-dates',
        name: 'FlightDestinationDates',
        component: FlightDestinationDates,
      },
      {
        path: 'tourism/m/flight/destination-dates',
        name: 'MobileFlightDestinationDates',
        component: MobileFlightDestinationDates,
      },
      {
        path: 'tourism/flight/order-summary',
        name: 'FlightOrderSummary',
        component: FlightOrderSummary,
      },
      {
        path: 'tourism/flight/order-summary-booking',
        name: 'FlightOrderSummaryBooking',
        component: FlightOrderSummaryBooking,
      },
      {
        path: 'tourism/m/flight',
        name: 'MobileFlight',
        component: MobileFlight,
      },
      {
        path: 'tourism/m/flight/order-approved',
        name: 'OrderApproved',
        component: OrderApproved,
      },
      {
        path: 'tourism/m/flight/order-reseller',
        name: 'MobileFlightOrderReseller',
        component: MobileFlightOrderReseller,
      },
      {
        path: 'gift-card',
        name: 'GiftCard',
        component: GiftCard,
        meta: { root: 0 },
      },
      {
        path: 'gift-card/supplier/:id',
        name: 'GiftCardSupplier',
        component: GiftCardSupplier,
      },
      {
        path: 'gift-card/supplier/:supplier_id/product/:id',
        name: 'GiftCardProduct',
        component: GiftCardProduct,
      },
      {
        path: 'gift-card/payment-result/:transaction_id',
        name: 'GiftCardPaymentResult',
        component: GiftCardPaymentResult,
      },
      {
        path: 'game',
        name: 'Game',
        component: Game,
        meta: { root: 0 },
      },
      {
        path: 'game/supplier/:id',
        name: 'GameSupplier',
        component: GameSupplier,
      },
      {
        path: 'game/supplier/:supplier_id/product/:id',
        name: 'GameProduct',
        component: GameProduct,
      },
      {
        path: 'game/payment-result/:transaction_id',
        name: 'GamePaymentResult',
        component: GamePaymentResult,
      },
      {
        path: 'register-reseller1',
        name: 'RegisterReseller1',
        component: RegisterReseller1,
      },
      {
        path: 'register-reseller2',
        name: 'RegisterReseller2',
        component: RegisterReseller2,
      },
      {
        path: 'register-reseller3',
        name: 'RegisterReseller3',
        component: RegisterReseller3,
      },
      {
        path: 'register-reseller4',
        name: 'RegisterReseller4',
        component: RegisterReseller4,
      },
      {
        path: 'tour-category',
        name: 'TourCategory',
        component: TourCategory,
      },
      {
        path: 'tour-places',
        name: 'TourPlaces',
        component: TourPlaces,
      },
      {
        path: 'tour-placesdetail',
        name: 'TourPlacesDetail',
        component: TourPlacesDetail,
      },
      {
        path: 'products',
        name: 'Sastech',
        component: Sastech,
        meta: { root: 3, mobileRedirect: 'PackagesM' },
      },
      {
        path: 'products/:company?/catalogs/:catalog?',
        name: 'Sastech By Company',
        component: SastechByCompany,
        meta: { root: 0, mobileRedirect: 'ProductsM' },
      },
      {
        path: 'products/:company?/product/:id',
        name: 'SastechProduct',
        component: SastechProduct,
        meta: { root: 0 },
      },
      {
        path: 'products/:company?/package/:id',
        name: 'SastechPackage',
        component: SastechPackage,
        meta: { root: 0 },
      },
      {
        path: 'cart',
        name: 'CartSystem',
        component: CartSystem,
        meta: { root: 3, mobileRedirect: 'CartM' },
      },
      {
        path: 'cart/order-history',
        name: 'CartOrderHistory',
        component: CartOrderHistory,
        meta: { root: 3 },
      },
      {
        path: 'cart/temp-order',
        name: 'CartTempOrder',
        component: CartTempOrder,
        meta: { root: 3 },
      },
      {
        path: 'cart/order/:id/success',
        name: 'OrderSuccess',
        component: OrderSuccess,
      },
      {
        path: 'permissions-profile',
        name: 'Permissions Profile',
        component: PermissionsProfile,
        meta: { root: 0 },
      },
      {
        path: 'account',
        name: 'ResellerAccount',
        component: ResellerAccount,
      },
    ],
  },
  {
    path: '/',
    component: AuthContainer,
    children: [
      {
        path: 'user-login',
        name: 'User_Login',
        component: User_Login,
      },
      {
        path: 'login',
        name: 'Reseller_Login',
        component: Reseller_Login,
      },
    ],
  },
  {
    path: '/',
    component: VuetifyContainer,
    children: [
      /* {
                path: 'tourism-report',
                name: 'TourismReport',
                component: TourismReport,
                meta: {root: 1, parent: 0 }
            }, */
      {
        path: 'resellertour-settings',
        name: 'ResellerTourSettings',
        component: ResellerTourSettings,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'tourism/order/:orderId',
        name: 'TourismOrderDetail',
        component: TourismOrderDetail,
      },
      {
        path: 'cart/order/:id/payment',
        name: 'OrderPayment',
        component: OrderPayment,
      },
    ],
  },
  {
    path: '/tourism-review/',
    component: VuetifyContainer,
    children: [
      {
        path: 'tour-order',
        name: 'TourOrder',
        component: TourOrder,
        meta: { root: 2, parent: 0 },
      },
      {
        path: 'tour-order-detail',
        name: 'MTourOrderDetail',
        component: MTourOrderDetail,
        meta: { root: 2, parent: 0 },
      },
    ],
  },
  {
    path: '/management/',
    component: VuetifyContainer,
    children: [
      {
        path: 'reports/sales',
        name: 'AdminSalesReport',
        component: AdminSalesReport,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'menuManagementCancelations',
        name: 'MenuManagementCancelations',
        component: MenuManagementCancelations,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel-free-charge-loading',
        name: 'CancelFreeChargeLoading',
        component: CancelFreeChargeLoading,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel-free-charge-upload',
        name: 'CancelFreeChargeUpload',
        component: CancelFreeChargeUpload,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'customer-lists',
        name: 'CustomerLists',
        component: CustomerLists,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'shaniv-package/:id',
        name: 'ShanivPackage',
        component: ShanivPackage,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'shaniv-package',
        name: 'ShanivPackageCreate',
        component: ShanivPackage,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancellation-system',
        name: 'CancellationSystem',
        component: CancellationSystem,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancel-payments',
        name: 'CancelPayment',
        component: CancelPayment,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancel-payments/prepaid/:id',
        name: 'CancelPrepaid',
        component: CancelPrepaid,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancellation',
        name: 'Cancellation',
        component: Cancellation,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/transactions',
        name: 'CancellationsTransaction',
        component: CancellationsTransaction,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancellation-cellcom',
        name: 'CancellationCellcom',
        component: CancellationCellcom,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancellation-giftcard',
        name: 'CancellationGiftcart',
        component: CancellationGiftcart,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancellation-success',
        name: 'CancellationSuccess',
        component: CancellationSuccess,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancellation-partial-success',
        name: 'CancellationPartialSuccess',
        component: CancellationPartialSuccess,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'cancel/cancellation-faild',
        name: 'CancellationFaild',
        component: CancellationFaild,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'sc/vat',
        name: 'SCVat',
        component: SCVat,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'sc/percentage',
        name: 'SCPercentage',
        component: SCPercentage,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/commissions',
        name: 'SCCommissions',
        component: SCCommissions,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/product-commission',
        name: 'SCProductCommission',
        component: SCProductCommission,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/provider-permission',
        name: 'SCProviderPermission',
        component: SCProviderPermission,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/reseller-profile',
        name: 'SCResellerProfile',
        component: SCResellerProfile,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/invoice-managment',
        name: 'SCInvoiceManagment',
        component: SCInvoiceManagment,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/ads',
        name: 'SCAds',
        component: SCAds,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/ads-advertisers',
        name: 'SCAdsAdvertisers',
        component: SCAdsAdvertisers,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/tag-management',
        name: 'SCTagManagement',
        component: SCTagManagement,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/tag-management/edit-category',
        name: 'SCTagEditCategory',
        component: SCTagEditCategory,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/permissions-profiles',
        name: 'SCPermissionsProfiles',
        component: SCPermissionsProfiles,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'sc/manage-translation',
        name: 'ManageTranslation',
        component: ManageTranslation,
        meta: { root: 2, parent: 2 },
      },
      {
        path: 'category-products/product-promotion',
        name: 'CPProductPromotion',
        component: CPProductPromotion,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/shaniv-management',
        name: 'CPShanivManagement',
        component: CPShanivManagement,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/providers',
        name: 'CPSuppliers',
        component: CPSuppliers,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/product-management',
        name: 'ProductManagement',
        component: ProductManagement,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/customer-home-products',
        name: 'CPCustomerHomeProducts',
        component: CPCustomerHomeProducts,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/provider-profiles',
        name: 'CPSupplierProfiles',
        component: CPSupplierProfiles,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/products-profiles',
        name: 'CPProductsProfiles',
        component: CPProductsProfiles,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/commissions-profiles',
        name: 'CPCommissionsProfile',
        component: CPCommissionsProfile,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/tourism-profiles',
        name: 'CPTourismProfiles',
        component: CPTourismProfiles,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/product-codes',
        name: 'CPProductCodes',
        component: CPProductCodes,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/manual-cards',
        name: 'CPManualCards',
        component: CPManualCards,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'category-products/gifts-points',
        name: 'CPGiftsPoints',
        component: CPGiftsPoints,
        meta: { root: 2, parent: 3 },
      },
      {
        path: 'tourism/general',
        name: 'TGeneralSetting',
        component: TGeneralSetting,
        meta: { root: 2, parent: 5 },
      },
      {
        path: 'tourism/suppliers',
        name: 'TTourSupplier',
        component: TTourSupplier,
        meta: { root: 2, parent: 5 },
      },
      {
        path: 'tourism/destinations',
        name: 'TDestination',
        component: TDestination,
        meta: { root: 2, parent: 5 },
      },
      {
        path: 'tourism/api-settings',
        name: 'TApiSettings',
        component: TApiSettings,
        meta: { root: 2, parent: 5 },
      },
      {
        path: 'dr/business-details',
        name: 'DRBusinessDetails',
        component: DRBusinessDetails,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'dr/contract-details',
        name: 'DRContractDetails',
        component: DRContractDetails,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'dr/providers',
        name: 'DRProviders',
        component: DRProviders,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'dr/percentage-profit',
        name: 'DRPercentageProfit',
        component: DRPercentageProfit,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'dr/payment-profit',
        name: 'DRPaymentProfit',
        component: DRPaymentProfit,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'dr/tourism-percentage',
        name: 'DRTourismPercentage',
        component: DRTourismPercentage,
        meta: { root: 2, parent: 4 },
      },
      {
        path: 'dr/users',
        name: 'DRUsers',
        component: DRUsers,
        meta: { root: 2, parent: 4 },
      },
    ],
  },
  {
    path: '/main/report/',
    component: VuetifyContainer,
    children: [
      {
        path: 'sales',
        name: 'SalesReport',
        component: SalesReport,
        meta: { root: 1, parent: 0 },
      },
      {
        path: '0',
        name: 'TourismReport',
        component: TourismReport,
        meta: { root: 1, parent: 0 },
      },
      {
        path: '1',
        name: 'PaymentReport',
        component: PaymentReport,
        meta: { root: 1, parent: 0 },
      },
      {
        path: '2',
        name: 'SalesPaymentReport',
        component: SalesPaymentReport,
        meta: { root: 1, parent: 0 },
      },
      {
        path: 'obligo',
        name: 'ObligoReport',
        component: ObligoReport,
        meta: { root: 1, parent: 0 },
      },
      {
        path: 'receipt-invoice',
        name: 'ReceiptsInvoices',
        component: ReceiptsInvoices,
        meta: { root: 1, parent: 0 },
      },
    ],
  },
  // {
  //   path: '/',
  //   component: AuthContainer,
  //   children: [
  //     {
  //       path: 'login',
  //       name: 'Login',
  //       component: Login,
  //     },
  //     {
  //       path: 'report-details/:transaction_id',
  //       name: 'ReportDetails',
  //       component: ReportDetails,
  //     },
  //   ],
  // },

  {
    path: '/',
    component: PublicContainer,
    children: [
      {
        path: 'payment-verification/:transaction_id/:phone_number/:user_type',
        name: 'PaymentVerificationPublic',
        component: PaymentVerificationPublic,
      },
      {
        path: 'payment-result/:transaction_id/:phone_number/:user_type',
        name: 'PaymentResultPublic',
        component: PaymentResultPublic,
      },
      // {
      //   path: 'gift-card/payment-verification/:transaction_id/:phone_number/:user_type',
      //   name: 'GiftCardPaymentVerificationPublic',
      //   component: GiftCardPaymentVerificationPublic,
      // },
      // {
      //   path: 'gift-card/payment-result/:transaction_id/:phone_number',
      //   name: 'GiftCardPaymentResultPublic',
      //   component: GiftCardPaymentResultPublic,
      // },
      // {
      //   path: 'game/payment-verification/:transaction_id/:phone_number/:user_type',
      //   name: 'GameCardPaymentVerificationPublic',
      //   component: GamePaymentVerificationPublic,
      // },
      // {
      //   path: 'game/payment-result/:transaction_id/:phone_number',
      //   name: 'GameCardPaymentResultPublic',
      //   component: GamePaymentResultPublic,
      // },
      {
        path: '404',
        name: '404',
        component: Page404,
      },
      {
        path: 'tour-order1',
        name: 'tour-order1',
        component: TourOrder1,
      },
      {
        path: 'tourism/order-details/:transaction_id/:phone_number',
        name: 'order-details',
        component: OrderDetails,
      },
      {
        path: 'tourism/order-passport/:transaction_id/:phone_number',
        name: 'order-passport',
        component: OrderPassport,
      },
      {
        path: 'passport-detail/:id',
        name: 'passport-detail',
        component: PassportDetail,
      },
      {
        path: '/tourism/order-confirmation/:transaction_id/:phone_number',
        name: 'order-confirmation',
        component: OrderConfirmation,
      },
      {
        path: '/tourism/order-result/:transaction_id/:phone_number',
        name: 'tour-order6',
        component: TourOrder6,
      },
    ],
  },
  {
    path: '/tv/',
    component: TvContainer,
    children: [
      {
        path: 'tv1',
        name: 'tv1',
        component: Tv1,
      },
      {
        path: 'tv2',
        name: 'tv2',
        component: Tv2,
      },
      {
        path: 'tv3',
        name: 'tv3',
        component: Tv3,
      },
    ],
  },
  {
    path: '/m/',
    component: MobileContainer,
    children: [
      {
        path: 'category',
        name: 'SastechCategoryM',
        component: SastechCategoryM,
        meta: { desktopRedirect: 'Sastech By Company' },
      },
      {
        path: 'packages',
        name: 'PackagesM',
        component: PackagesM,
        meta: { desktopRedirect: 'Sastech' },
      },
      {
        path: 'products',
        name: 'ProductsM',
        component: ProductsM,
        meta: { desktopRedirect: 'Sastech By Company' },
      },
      {
        path: 'cart',
        name: 'CartM',
        component: CartM,
        meta: { desktopRedirect: 'Cart System' },
      },
    ],
  },
  {
    path: '/logout',
    redirect: '/login',
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/enduser/',
    component: EndUserContainer,
    children: [
      {
        path: 'home',
        name: 'EndUserHome',
        component: EndUserHome,
      },
      {
        path: 'account',
        name: 'EndUserAccount',
        component: EndUserAccount,
      },
      {
        path: 'communication',
        name: 'EndUserCommunication',
        component: EndUserCommunication,
      },
      {
        path: 'communication/supplier/:id',
        name: 'EndUserCommunicationSupplier',
        component: EndUserCommunicationSupplier,
      },
      {
        path: 'communication/supplier/:supplier_id/product/:id',
        name: 'EndUserCommunicationProduct',
        component: EndUserCommunicationProduct,
      },
      {
        path: 'communication/payment-success/:id',
        name: 'EndUserCommunicationPaymentSuccess',
        component: CommunicationPaymentSuccess,
      },
      {
        path: 'gift-card',
        name: 'EndUserGiftCard',
        component: EndUserGiftCard,
      },
      {
        path: 'gift-card/supplier/:id',
        name: 'EndUserGiftCardSupplier',
        component: EndUserGiftCardSupplier,
      },
      {
        path: 'gift-card/supplier/:supplier_id/product/:id',
        name: 'EndUserGiftCardProduct',
        component: EndUserGiftCardProduct,
      },
      {
        path: 'gift-card/payment-result/:transaction_id',
        name: 'EndUserGiftCardPaymentResult',
        component: GiftCardPaymentResult,
      },
      {
        path: 'game',
        name: 'EndUserGame',
        component: EndUserGame,
      },
      {
        path: 'game/supplier/:id',
        name: 'EndUserGameSupplier',
        component: EndUserGameSupplier,
      },
      {
        path: 'game/supplier/:supplier_id/product/:id',
        name: 'EndUserGameProduct',
        component: EndUserGameProduct,
      },
      {
        path: 'game/payment-result/:transaction_id',
        name: 'EndUserGamePaymentResult',
        component: GamePaymentResult,
      },
      {
        path: 'tourism',
        name: 'EndUserTourism',
        component: Tourism,
      },
      {
        path: 'tourism-product/:id/:rowId/:destinationId?/:dealType?',
        name: 'EndUserTourismProduct',
        component: TourismProduct,
      },
      {
        path: 'tourism/NOFSHON',
        name: 'EndUserTourismNofshonAll',
        component: TourismNofshonAll,
      },
      {
        path: 'tourism/ORGANIZED_TOUR',
        name: 'EndUserTourismOrganizedAll',
        component: TourismOrganizedAll,
      },
      {
        path: 'tourism-organized/:id/:rowId/:destinationId?/:dealType?',
        name: 'EndUserTourismOrganized',
        component: TourismOrganized,
      },
      {
        path: 'tourism/trade-shows/trade-shows',
        name: 'EndUserTradeShows',
        component: TradeShows,
      },
      {
        path: 'tourism/trade-shows/category',
        name: 'EndUserTradeShowsCategory',
        component: TradeShowsCategory,
      },
      {
        path: 'tourism/trade-shows/details',
        name: 'EndUserTradeShowsDetails',
        component: TradeShowsDetails,
      },
      {
        path: 'tourism/trade-shows/selected-result',
        name: 'EndUserSelectedResult',
        component: SelectedResult,
      },
      {
        path: 'tourism/trade-shows/upcoming',
        name: 'EndUserTradeShowsUpcoming',
        component: TradeShowsUpcoming,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// router.beforeEach((to, from, next) => {
//   // redirect to mobile/desktop component
//   let redirectName = null;
//   if (isMobile) {
//     if (to && to.meta && to.meta.mobileRedirect) {
//       redirectName = to.meta.mobileRedirect;
//     }
//   } else {
//     if (to && to.meta && to.meta.desktopRedirect) {
//       redirectName = to.meta.desktopRedirect;
//     }
//   }
//   if (redirectName) {
//     let redirectObject = {
//       name: redirectName,
//       params: to.params,
//       query: to.query,
//     };
//     next(redirectObject);
//   } else {
//     next();
//   }
// });

router.beforeEach(async (to, from, next) => {
  // load translations
  // let lang = localStorage.getItem('language') || 'he';
  // let loadLanguagePromise = loadLanguageAsync(lang);

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/login',
    '/user-login',
    // '/user-login',
    '/register',
    '/404',
    '/payment-verification/:transaction_id/:phone_number/:user_type',
    '/payment-result/:transaction_id/:phone_number/:user_type',
    // '/gift-card/payment-verification/:transaction_id/:phone_number/:user_type',
    // '/gift-card/payment-result/:transaction_id/:phone_number',
    // '/game/payment-verification/:transaction_id/:phone_number/:user_type',
    // '/game/payment-result/:transaction_id/:phone_number',

    '/tour-order1',
    '/tourism/order-details/:transaction_id/:phone_number',
    '/tourism/order-passport/:transaction_id/:phone_number',
    '/passport-detail/:id',
    '/tourism/order-confirmation/:transaction_id/:phone_number',
    '/tourism/order-result/:transaction_id/:phone_number',
  ];
  const currentPath = [to.path];
  if (to.matched && to.matched.length) {
    to.matched.forEach(function (item) {
      if (!item.path) {
        return;
      }
      currentPath.push(item.path);
    });
  }

  const authRequired = !publicPages.filter(function (path) {
    return currentPath.includes(path);
  }).length;
  if (currentPath.includes('/enduser')) {
    let loggedIn = store.state.end_user.isLoggedIn;
    if (authRequired && !loggedIn) {
      await store.dispatch('end_user/isAuth');
      loggedIn = store.state.end_user.isLoggedIn;
    }
    if (loggedIn) {
      next();
    } else {
      let redirectRouteObject = { path: '/login' };
      if (to.path !== '/') {
        redirectRouteObject.query = { redirect: to.path };
      }
      next({ path: '/login' });
    }
  } else {
    if (currentPath.includes('/login') || currentPath.includes('/user-login')) {
      await store.dispatch('account/isAuth');
      const isLoggedIn = store.state.account.loggedIn;
      const user_type_logged_in = localStorage.getItem('user_type_logged_in');
      if (isLoggedIn && user_type_logged_in === 'Reseller') {
        next({ path: '/' });
      } else if (isLoggedIn && user_type_logged_in === 'EndUser') {
        next({ path: '/enduser/home' });
      }
      next();
    } else {
      // const connectSid = Vue.$cookies.get('connect.sid');
      let loggedIn = store.state.account.loggedIn;

      // if (authRequired && connectSid && !loggedIn) {
      if (authRequired && !loggedIn) {
        await store.dispatch('account/isAuth');
        loggedIn = store.state.account.loggedIn;
      }

      if (loggedIn) {
        // load VAT percent
        if (!store.state.general_settings.VAT_percent_loaded) {
          store.dispatch('general_settings/getVATPercent');
        }
      }

      // await loadLanguagePromise;
      if (authRequired) {
        if (loggedIn) {
          // load permissions if they are not loaded yet
          if (
            !store.state.permission.permissions_data ||
            !store.state.permission.permissions_data.length
          ) {
            await store.dispatch('permission/getUserPermissionsData');
          }

          // access denied if there is no permissions info in the store
          let accessDenied =
            !store.state.permission.permissions_data ||
            !store.state.permission.permissions_data.length;

          // if we have permissions info in the store -> check if user has access to the to.path
          if (!accessDenied) {
            accessDenied = !store.getters['permission/hasPathAccess'](to.path);
          }

          if (accessDenied) {
            // if "to" route is "/" -> redirect to the first allowed page (from the our pages list)
            // else show access denied page (404 for now)
            let allowedPathToRedirect = null;
            if (to.path === '/') {
              // todo: complete this list
              let pathsList = [];
              const asideMenuItems = menuConfig.aside.items || null;
              if (asideMenuItems && asideMenuItems.length) {
                for (let item of asideMenuItems) {
                  if (item.page) {
                    pathsList.push(item.page);
                  }
                  if (item.submenu && item.submenu.length) {
                    for (let subItem of item.submenu) {
                      if (subItem.page) {
                        pathsList.push(subItem.page);
                      }
                      if (subItem.submenu && subItem.submenu.length) {
                        for (let subSubItem of subItem.submenu) {
                          if (subSubItem.page) {
                            pathsList.push(subSubItem.page);
                          }
                        }
                      }
                    }
                  }
                }
              } else {
                const mainPathsList = [
                  // '/',
                  '/tourism',
                  '/communication',
                  '/payment',
                  '/gift-card',
                  '/game',
                  '/products',
                ];
                pathsList = mainPathsList;
              }
              for (let path of pathsList) {
                if (store.getters['permission/hasPathAccess'](path)) {
                  allowedPathToRedirect = path;
                  break;
                }
              }
            }
            if (allowedPathToRedirect) {
              next({ path: allowedPathToRedirect, replace: true });
            } else {
              next('/404');
            }
          } else {
            next();
          }
        } else {
          let redirectRouteObject = { path: '/login' };
          if (to.path !== '/') {
            redirectRouteObject.query = { redirect: to.path };
          }
          next(redirectRouteObject);
        }
      } else {
        next();
      }
    }
  }
});

// todo: create store and service for translations loading
// todo: move api call to the services
// todo: save translations data in the translations store
// let loadedLanguages = [];
// async function loadLanguageAsync(lang) {
//   if (loadedLanguages.includes(lang)) {
//     if (i18n.locale !== lang) {
//       i18n.locale = lang;
//     }
//     return Promise.resolve();
//   }
//   let params = {};
//   const config = {
//     method: 'GET',
//     url: appConfig.apiUrl + '/translation/',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     params: params,
//     data: params,
//     withCredentials: 0,
//   };

//   return axios(config).then((res) => {
//     if (res.status == 200) {
//       let languages = ['he', 'en', 'ar'];

//       for (let language of languages) {
//         let jsonData = [];
//         for (let j = 0; j < res.data.length; j++) {
//           if (!jsonData[res.data[j].group]) {
//             jsonData[res.data[j].group] = [];
//           }
//           jsonData[res.data[j].group][res.data[j].key] = res.data[j][language];
//         }
//         loadedLanguages.push(language);
//         i18n.mergeLocaleMessage(language, jsonData);
//       }
//     }
//   });
// }

export default router;
