import {destinationService} from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
    destinationData_receipts: [],
    destinationSelectedData_receipts: [],
    tagsData_receipts: [],
    loading: false
}
const actions = {
    getDestination({ dispatch, commit }) {
        commit('setLoadingTrue');
        return destinationService.getDestination()
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        commit('setDestinationData', {receipt_data: receipt_data})
                    }
                },
                error => {
                    commit('setLoadingFalse');
                    dispatch('alert/error', error);
                }
            );
    },
    getDestinationById({ dispatch, commit }, {id}) {
        commit('setLoadingTrue');
        return destinationService.getDestinationById(id)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        commit('setDestinationSelectedData', {receipt_data: receipt_data})
                    }
                },
                error => {
                    commit('setLoadingFalse');
                    dispatch('alert/error', error);
                }
            );
    },
    addDestination({ dispatch, commit }, {destination_data}) {
        commit('setLoadingTrue');
        return destinationService.addDestination(destination_data)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        dispatch('getDestination');
                        dispatch('alert/success', '', {root: true});
                    }
                },
                error => {
                    commit('setLoadingFalse');
                    dispatch('alert/error', error);
                }
            );
    },
    getTags({ commit }) {
        return destinationService.getTags()
            .then(
                receipt_data => {
                    if (receipt_data) {
                        commit('setTagsData', {receipt_data: receipt_data})
                    }
                },
                // error => {}
            );
    },
    changeTagsState({commit}, {id, tag_data}) {
        commit('changeTagsState', {id: id, tag_data:tag_data})
    },
    addAirportstore({commit}, {id, airport_data}) {
        commit('addAirport', {id: id, airport_data:airport_data})
    },
    save_OneDes({dispatch, commit}, {id}) {
        commit('setLoadingTrue');
        const destinationData = state.destinationData_receipts.find(item => {
            return item.id == id;
        });
        const updateData = {
            active: destinationData.active || false,
            airports: destinationData.airports || [],
            country: destinationData.country || "",
            country_ar: destinationData.country_ar || "",
            country_en: destinationData.country_en || "",
            country_he: destinationData.country_he || "",
            description: destinationData.description || null,
            description_ar: destinationData.description_ar || null,
            description_en: destinationData.description_en || null,
            description_he: destinationData.description_he || null,
            favorite: destinationData.favorite || true,
            flight: destinationData.flight || true,
            id: destinationData.id || null,
            name: destinationData.name || "",
            name_ar: destinationData.name_ar || "",
            name_en: destinationData.name_en || "",
            name_he: destinationData.name_he || "",
            newTags: destinationData.newTags || [],
            nofhson: destinationData.nofhson || true,
            organzied_tour: destinationData.organzied_tour || true,
            sort_order: destinationData.sort_order || null,
            tags: destinationData.tags || [],
            view: destinationData.view || false,
            visa: destinationData.visa || false,
            video_links: destinationData.video_links || null
        };
        destinationService.saveDestination(updateData)
        .then(
            receipt_data => {
                commit('setLoadingFalse');
                if (receipt_data) {
                    dispatch('alert/success', '', {root: true});
                }
            },
            error => {
                commit('setLoadingFalse');
                dispatch('alert/error', '', {root: true});
                dispatch('getDestination');
                return Promise.reject(error);

            }
        );
    },

    save_AllDes({dispatch, commit}) {
        commit('setLoadingTrue');
        destinationService.saveAllDestination(state.destinationData_receipts)
        .then(
            receipt_data => {
                commit('setLoadingFalse');
                if (receipt_data) {
                    dispatch('alert/success', '', {root: true});
                }
            },
            error => {
                commit('setLoadingFalse');
                dispatch('alert/error', '', {root: true});
                dispatch('getDestination');
                return Promise.reject(error);

            }
        );
    }

    
};

const mutations = {
    setLoadingTrue(state) {
        state.loading = true;
    },
    setLoadingFalse(state) {
        state.loading = false;
    },
    setDestinationData(state, {receipt_data}) {
        let destinationData_receipts = state.destinationData_receipts || []
        destinationData_receipts = receipt_data
        state.destinationData_receipts = null;
        state.destinationData_receipts = destinationData_receipts;
    },
    setDestinationSelectedData(state, {receipt_data}) {
        state.destinationSelectedData_receipts = receipt_data;
    },
    setTagsData(state, {receipt_data}) {
        let tagsData_receipts = state.tagsData_receipts || []
        tagsData_receipts = receipt_data
        state.tagsData_receipts = null;
        state.tagsData_receipts = tagsData_receipts;
    },
    changeTagsState(state, {id, tag_data}) {
        state.destinationData_receipts.forEach(item => {
            if(item.id == id) {
                item.tags = tag_data;
            }
        })
    },
    addAirport(state, {id, airport_data}) {
        state.destinationData_receipts.forEach(item => {
            if(item.id == id) {
                item.airports.push(airport_data);
            }
        })
    }

};

export const destination = {
    namespaced: true,
    state,
    actions,
    mutations
};