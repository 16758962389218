import { chargeRavkavService } from '../services/chargeRavkav.service';
import { userService } from '../services/user.service';
import RavKavConnection from '../components/supplier_payments/999/ravkav-loader';
import router from '../router/index';

const state = {
  failureCardExtracted: false,
  readerState: null,
  cardDump: {},
  isConfirm: false,
  paymentDetails: false,
  contracts: [],
  contracts_display: [],
  cancellables: [],
  uid: null,
  userId: null,
  businessId: null,
  remote_id: null,
  location: {},
  connectionState: null,
  noCard: false,
  contractApproval: {},
  profit: null,
  loading: false,

  supplier_id: null,
  payment_data: {},
  contract_approved: null,
  current_transaction_id: [],
  choosen_contract: {},
  dumpUrl: null,
  connection: null,
  contrats_acc_value: [],
  contrats_free_monthly: [],
  contrats_free_weekly: [],
  contrats_free_flexible: [],
  contrats_free_daily: [],
};
let connectToReader = (activationLink) => {
  const frame = window.document.createElement('iframe');
  frame.src = activationLink;
  frame.style.display = 'None';
  window.document.body.appendChild(frame);
};
let onGetCardDumpExecuteSuccess = (cardDump) => {
  state.cardDump = cardDump.result;
  actions.charge();
};

let onGetCardDumpExecuteFailure = (error) => {
  console.log('onGetCardDumpExecuteFailure() error:', error);
};

let onStateChange = (status, commit) => {
  state.connectionState = status;
  console.log('ravkav status', status);
  commit('setReaderState', status);
  if (status === 'READY') {
    state.connection.execute(
      state.dumpUrl,
      45000,
      onGetCardDumpExecuteSuccess,
      onGetCardDumpExecuteFailure,
    );
  }
  if (status === 'NO_CARD') {
    commit('clearArrays');
  }
  return status;
};
let onSuccess = (connection) => {
  console.log(connection);
  connectToReader(connection.getDesktopActivationLink());
  state.connection = connection;

  return;
};

let onError = (errorCode, commit) => {
  console.log('onError', errorCode);
  commit('setReaderState', errorCode);
  return;
};
let reloadRavkavCard = (reloadingUrl) => {
  state.connection.execute(
    reloadingUrl,
    45000,
    onReloadingExecuteSuccess,
    onReloadingExecuteFailure,
  );
};

/* reloading execute callbacks */
let onReloadingExecuteSuccess = (result) => {
  state.contract_approved = result.finish_status;
};
let onReloadingExecuteFailure = (error) => {
  console.log('onReloadingExecuteFailure() error:', error);
};
const actions = {
  connectReader({ commit }) {
    commit('setLoadingTrue');
    return chargeRavkavService
      .connectReader()
      .then((res) => {
        if (res) {
          commit('dumpUrl', res);
          RavKavConnection.connect(
            res.sessionUrl,
            10000000,
            onStateChange,
            onSuccess,
            onError,
            commit,
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  charge() {
    if (state.connectionState === 'READY') {
      state.isConfirm = true;
      return userService.user_details().then((receipt_data) => {
        if (receipt_data) {
          state.userId = receipt_data.details.id;
          return chargeRavkavService
            .getBusinessId(state.userId)
            .then((businessId) => {
              state.businessId = businessId.business_id;
              return chargeRavkavService.getProfit(999).then((profit) => {
                state.profit = profit.max_percentage_profit;
                return (
                  chargeRavkavService
                    .getStationId(state.businessId)
                    //return chargeRavkavService.getStationId(state.userId)
                    .then((result) => {
                      state.remote_id = result.remote_id;
                      state.location = `${result.lat},${result.lon}`;
                      return chargeRavkavService
                        .getContracts(
                          state.remote_id,
                          state.location,
                          state.cardDump,
                        )
                        .then((contracts) => {
                          state.loading = false;
                          state.contracts = contracts.contracts;
                          state.contracts_display = contracts.contracts_display;
                          for (let item in state.contracts) {
                            switch (
                              state.contracts[item].display.detail[3].split(
                                ',',
                              )[0]
                            ) {
                              case 'חופשי יומי':
                                state.contrats_free_daily.push(
                                  state.contracts[item],
                                );
                                break;
                              case 'חופשי חודשי':
                                state.contrats_free_monthly.push(
                                  state.contracts[item],
                                );
                                break;
                              case 'חופשי שבועי':
                                state.contrats_free_weekly.push(
                                  state.contracts[item],
                                );
                                break;
                              case 'חופשי גמיש':
                                state.contrats_free_flexible.push(
                                  state.contracts[item],
                                );
                                break;
                              case 'ערך צבור':
                                state.contrats_acc_value.push(
                                  state.contracts[item],
                                );
                                break;
                            }
                          }
                        });
                    })
                );
              });
            });
        }
      });
    }
    if (state.connectionState === 'NO_CARD') {
      state.noCard = true;
    }
  },

  getContractDetails({ commit }, { contract_number }) {
    commit('showContract');
    for (let index in state.contracts) {
      if (state.contracts[index].transaction.contract === contract_number) {
        commit('setChoosenConract', state.contracts[index]);
        return chargeRavkavService
          .createTransaction(
            state.choosen_contract,
            state.remote_id,
            state.location,
          )
          .then((uid) => {
            commit('setUid', uid);
            return chargeRavkavService
              .payRavKav(state.remote_id, state.location, state.uid)
              .then((res) => {
                if (res === 'charged') {
                  return chargeRavkavService
                    .getReloadingStatus(
                      state.uid,
                      state.remote_id,
                      state.location,
                    )
                    .then((status) => {
                      if (state.connectionState === 'READY') {
                        reloadRavkavCard(status);
                      }
                      if (state.connectionState === 'NO_CARD') {
                        commit('setFailureCardExtracted', true);
                        return chargeRavkavService
                          .getRavKavFailure(
                            state.uid,
                            state.remote_id,
                            state.location,
                          )
                          .then((res) => {
                            console.log(res);
                          });
                      }
                    });
                }
              });
          });
      }
    }
  },

  ravkavPaymentRequest({ dispatch, commit }, { supplier_id, payment_data }) {
    commit('setLoadingTrue');
    setTimeout(() => {
      if (state.contract_approved === 'SUCCESS') {
        commit('setPaymentDetails', true);
        return chargeRavkavService
          .getRavKavTransaction(state.uid, state.remote_id, state.location)
          .then((res) => {
            commit('setContractApprove', res);
            let price =
              state.choosen_contract.transaction.price +
              (state.choosen_contract.transaction.price * state.profit) / 100;
            let contract_details = `${
              state.choosen_contract.display.detail[3].split(',')[0]
            },${state.choosen_contract.description}`;
            return chargeRavkavService
              .ravkavPayment(
                supplier_id,
                payment_data,
                state.uid,
                state.cardDump.serial_number,
                price,
                state.choosen_contract.transaction.price,
                contract_details,
              )
              .then(
                (result_data) => {
                  if (
                    result_data &&
                    result_data.hasOwnProperty('currentTransaction') &&
                    result_data.currentTransaction &&
                    result_data.hasOwnProperty('success') &&
                    result_data.success
                  ) {
                    commit('setCurrentTransactionId', {
                      transaction_id: result_data.currentTransaction,
                      supplier_id: supplier_id,
                    });
                    dispatch('account/getUserDetails', {}, { root: true });
                    commit('setLoadingFalse');
                    router.push({
                      name: 'SupplierPaymentSuccess',
                      params: {
                        supplier_id: supplier_id,
                        transaction_id: result_data.currentTransaction,
                      },
                    });
                  } else {
                    dispatch(
                      'alert/error',
                      result_data.error || 'Something went wrong',
                      { root: true },
                    );
                    console.log('router');
                    router.push({
                      name: 'SupplierPayment',
                      params: { supplier_id: supplier_id },
                    });
                  }

                  commit('setLoadingFalse');
                  //return result_data
                },
                (error) => {
                  if ('data' in error && 'error' in error.data) {
                    dispatch('alert/error', error.data.error, { root: true });
                  }
                  commit('setLoadingFalse');
                  return error;
                },
              );
          });
      }
      if (state.contract_approved === 'FAILED') {
        commit('setLoadingFalse');
        return chargeRavkavService
          .getRavKavFailure(state.uid, state.remote_id, state.location)
          .then((res) => {
            console.log(res);
          });
      }
    }, 1000);
  },

  closeNoCard({ commit }) {
    router.push({ name: 'SupplierPayment', params: { supplier_id: '999' } });
    commit('setLoadingFalse');
    commit('closeNoCard', false);
    commit('clearArrays');
    state.connection.ws.close();
  },

  closeConnection({ commit }) {
    state.connection.ws.close();
    commit('clearArrays');
    commit('setPaymentDetails', false);
  },
  showResult({ commit }) {
    commit('changeScreen', true);
  },
};

const mutations = {
  setFailureCardExtracted(state, failureCardExtracted) {
    state.failureCardExtracted = failureCardExtracted;
  },
  setReaderState(state, readerState) {
    state.readerState = readerState;
  },
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setCurrentTransactionId(state, { transaction_id, supplier_id }) {
    let current_transaction_id = state.current_transaction_id || [];
    current_transaction_id[supplier_id] = transaction_id;
    state.current_transaction_id = null;
    state.current_transaction_id = current_transaction_id;
  },
  setState(state, cardDump) {
    state.connectionState = cardDump.state;
  },
  setCardDump(state, cardDump) {
    state.cardDump = cardDump.cardDump;
  },
  dumpUrl(state, res) {
    state.dumpUrl = res.dumpUrl;
  },
  setId(state, id) {
    state.userId = id;
  },
  setBusinessId(state, businessId) {
    state.businessId = businessId.business_id;
  },
  showContract(state) {
    state.show_contracts = false;
  },
  setPaymentDetails(state, status) {
    state.paymentDetails = true;
  },
  setProfit(state, profit) {
    state.profit = profit.max_percentage_profit;
  },
  setUid(state, uid) {
    state.uid = uid;
  },
  setRemoteId(state, remote_id) {
    state.remote_id = remote_id;
  },
  setLocation(state, result) {
    state.location = `${result.lat},${result.lon}`;
  },
  setContractApprove(state, res) {
    state.contractApproval = res;
  },
  changeScreen(state, change) {
    state.isConfirm = change;
  },
  setNoCard(state, change) {
    state.noCard = change;
  },
  closeNoCard(state, change) {
    state.noCard = change;
  },
  setConnection(state, connection) {
    state.connection = connection;
  },
  setChoosenConract(state, contractChoosen) {
    state.choosen_contract = contractChoosen;
  },
  clearArrays(state) {
    (state.contrats_acc_value = []), (state.contrats_free_monthly = []);
    state.contrats_free_weekly = [];
    state.contrats_free_flexible = [];
    state.contrats_free_daily = [];
    state.failureCardExtracted = false;
  },
  setError(state, { error }) {
    state.error = error;
  },
};

export const chargeRavkav_module = {
  namespaced: true,
  state,
  actions,
  mutations,
};
