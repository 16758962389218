import ApiService from "../services/api.service";

const state = {
  permissions: [],
  rolesList: [],
  permissionRoles: []
}

const actions = {
  getPermissions({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/permissions", {})
        .then(data => {
          commit('setPermissions', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getRoles({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/roles", {})
        .then(data => {
          commit('setRolesList', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  createRole({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/roles", params)
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getPermissionRoles({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/permission_roles", {})
        .then(data => {
          commit('setPermissionRoles', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updatePermissionRoles({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/permission_roles", params)
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
};

const mutations = {
  setPermissions(state, { receipt_data }) {
    state.permissions = receipt_data;
  },
  setRolesList(state, { receipt_data }) {
    state.rolesList = receipt_data;
  },
  setPermissionRoles(state, { receipt_data }) {
    state.permissionRoles = receipt_data;
  },
};

export const system_settings = {
  namespaced: true,
  state,
  actions,
  mutations
};

