import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const dealService = {
    getDeal,
    getDealDetail,
    getDealsByIdType,
    getCheapestDeals,
    getCheapestDealsAll,
    getBooking,
    sendBooking,
    getHotelData,
};


function getDeal(filters) {
    let params = filters;
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/deals',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getDealDetail(id, rowId) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/deals/' + id + '/' + rowId,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err && err.response && err.response.status && err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getDealsByIdType(to, type, page) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/deals?to=' + to + '&type=' + type + '&per_page=' + 40 + '&page=' + page,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getHotelData(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/deals/' + id + '/hotels',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getCheapestDeals(params) {
    params = params || {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/deals/cheapest_deals',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getCheapestDealsAll(params) {
    params = params || {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/deals/cheapest',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getBooking(params) {
    params = params;
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/tour/order/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }
    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return false;
        })
}

function sendBooking(params) {
    let new_params = {
        discount_amount: params.discount_amount,
        discount_type: params.discount_type
    }
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/tour/order/' + params.transaction_id + '/discount',
        headers: {
            'Content-Type': 'application/json',
        },
        data: new_params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
