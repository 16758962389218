import {reportService} from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
    salesReportData_receipts: [],
    salesPrintData_receipts: [],
    paymentReportData_receipts: [],
    paymentPrintData_receipts: [],
    paymentReportSum_recepipts: 0,
    paymentReport_length: 0,
    salesPaymentReportData_receipts: [],
    salesPaymentPrintData_receipts: [],
    salesPaymentReportBalance_recepipts: 0,
    salesPaymentReport_length: 0,
    loading: false,
    exportInProgress: false,
    statuses: {
        1: i18n.t("Opened"),
        2: '2',
        3: i18n.t("Success"),
        4: i18n.t("Failed"),
        5: i18n.t("Pending"),
        6: i18n.t("Cancelled")
    },
    oldPaymentFilter: {},
    oldSalesPaymentFilter: {},
    salesDetails: [],
}
const actions = {
    /* sales report */
    filter_sales_report({ dispatch, commit }, {limit, skip, filters}) {
        commit('setLoadingTrue');
        return reportService.filter_sales_report(limit, skip, filters)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    if (receipt_data) {
                        commit('setSalesReportData', {receipt_data: receipt_data})
                    }
                },
                error => {
                    commit('setLoadingFalse');
                    dispatch('alert/error', error, {root: true});
                }
            );
    },
    async filter_sales_report_print({ dispatch, commit }, {filters, total}) {
        commit('setLoadingTrue');
        let tempData = [];
        for (let i=0; i < total; i += 1000) {
            await reportService.filter_sales_report(1000, i, filters)
            .then(
                receipt_data => {
                    if (receipt_data) {
                        tempData  = [...tempData, ...receipt_data.data];
                    }
                },
                error => {
                    commit('setLoadingFalse');
                    dispatch('alert/error', error, {root: true});
                }
            );
        }

        tempData.forEach( item => {
            if (item.business_name == null || item.business_name == undefined) {
                item.business_name = "";
            }
            if (item.phone_number == null || item.phone_number == undefined) {
                item.phone_number = "";
            }
            if (item.supplier_name == null || item.supplier_name == undefined) {
                item.supplier_name = "";
            }
            if (item.product_name_he == null || item.product_name_he == undefined) {
                item.product_name_he = "";
            }
            if (item.product_name_en == null || item.product_name_en == undefined) {
                item.product_name_en = "";
            }
            if (item.product_name_ar == null || item.product_name_ar == undefined) {
                item.product_name_ar = "";
            }
            if (item.transaction_end_timestamp) {
                item.date = moment(item.transaction_end_timestamp).format('YYYY-MM-DD');
                item.hour = moment(item.transaction_end_timestamp).format('HH:mm:ss');
            }
            if (item.price == null || item.price == undefined) {
                item.price = "";
            }
            if (item.business_price == null || item.business_price == undefined) {
                item.business_price = "";
            }
            if (item.status == null || item.status == undefined) {
                item.status = "";
            } else {
                item.status = state.statuses[item.status];
            }
            if (item.payment_cancel_status == null || item.payment_cancel_status == undefined) {
                item.payment_cancel_status = "";
            }
        })
        commit('setSalesPrintData', {receipt_data: tempData});
        commit('setLoadingFalse');
        return tempData;
    },
    export_sales_report({ dispatch, commit }, {format, fields, filters}) {
        commit('setExportInProgressTrue');
        return reportService.export_sales_report(format, fields, filters)
            .then(
                data => {
                    commit('setExportInProgressFalse');
                    return data;
                },
                error => {
                    commit('setExportInProgressFalse');
                    dispatch('alert/error', error, {root: true});
                    return error;
                }
            );
    },

    /* payment report */
    filter_payment_report({ dispatch, commit }, {limit, skip, filters}) {
        commit('setLoadingTrue');
        return reportService.filter_payment_reportLength(limit, skip, filters)
            .then(
                receipt_data => {
                    commit('setPaymentReportSum', {receipt_data: receipt_data.sum_payment});
                    commit('setPaymentReport_length', {receipt_data: parseInt(receipt_data.data.length)});
                    if (receipt_data.data.length > 0) {
                        commit('setPaymentReportData', {receipt_data: receipt_data})
                        // reportService.filter_payment_reportData(limit, skip, filters)
                        // .then(
                        //     rec_data => {
                                // commit('setPaymentReportData', {receipt_data: rec_data})
                        //     } 
                        // )
                    } else {
                        commit('setPaymentReportData', {receipt_data: []})
                    }
                    commit('setLoadingFalse');
                },
                error => {
                    commit('setPaymentReportSum', {receipt_data: 0});
                    commit('setPaymentReport_length', {receipt_data: 0});
                    commit('setPaymentReportData', {receipt_data: []})
                    commit('setLoadingFalse');
                    dispatch('alert/error', error, {root: true});
                }
            );
    },
    async filter_payment_report_print({ dispatch, commit }, {filters, total}) {
        commit('setLoadingTrue');
        if (JSON.stringify(state.oldPaymentFilter) === JSON.stringify(filters)) {
            commit('setLoadingFalse');
            return state.paymentPrintData_receipts
        } else {
            let tempData = [];
            for (let i=0; i < total; i += 1000) {
                await reportService.filter_payment_reportData(1000, i, filters)
                .then(
                    rec_data => {
                        if (rec_data) {
                            tempData  = [...tempData, ...rec_data.data];
                        }
                    },
                    error => {
                        commit('setLoadingFalse');
                        dispatch('alert/error', error, {root: true});
                    }
                );
            }

            tempData.forEach( item => {
                if (item.payment_type == null || item.payment_type == undefined) {
                    item.payment_type = "";
                }
            })
            commit('setPaymentReportPrintData', {receipt_data: tempData});
            commit('setOldPaymentFilters', {receipt_data: filters});
            commit('setLoadingFalse');
            return tempData;
        }
    },

    /* sales payment report */
    filter_sales_payment_report({ dispatch, commit }, {limit, skip, filters}) {
        commit('setLoadingTrue');
        return reportService.filter_sales_payment_reportLength(limit, skip, filters)
            .then(
                receipt_data => {
                    commit('setSalesPaymentCurrentBalance', {receipt_data: receipt_data.currentBalance});
                    commit('setSalesPaymentReport_length', {receipt_data: receipt_data.length});
                    if (receipt_data.length > 0) {
                        reportService.filter_sales_payment_reportData(limit, skip, filters)
                        .then(
                            rec_data => {
                                commit('setSalesPaymentReportData', {receipt_data: rec_data})
                            } 
                        )
                    } else {
                        commit('setSalesPaymentReportData', {receipt_data: []})
                        commit('setSalesPaymentCurrentBalance', {receipt_data: 0});
                        commit('setSalesPaymentReport_length', {receipt_data: 0});
                    }
                    commit('setLoadingFalse');
                },
                error => {
                    commit('setSalesPaymentCurrentBalance', {receipt_data: 0});
                    commit('setSalesPaymentReport_length', {receipt_data: 0});
                    commit('setSalesPaymentReportData', {receipt_data: []})
                    commit('setLoadingFalse');
                    console.log("error===", error)
                    dispatch('report/alert/error', error);
                }
            );
    },
    async filter_sales_payment_report_print({ dispatch, commit }, {filters, total}) {
        commit('setLoadingTrue');
        if (JSON.stringify(state.oldSalesPaymentFilter) === JSON.stringify(filters)) {
            commit('setLoadingFalse');
            return state.salesPaymentPrintData_receipts
        } else {
            let tempData = [];
            for (let i=0; i < total; i += 1000) {
                await reportService.filter_sales_payment_reportData(1000, i, filters)
                .then(
                    rec_data => {
                        if (rec_data) {
                            tempData  = [...tempData, ...rec_data.data];
                        }
                    },
                    error => {
                        commit('setLoadingFalse');
                        dispatch('alert/error', error, {root: true});
                    }
                );
            }

            tempData.forEach( item => {
                if (item.action == null || item.action == undefined) {
                    item.action = "";
                }
                if (item.consumer_price == null || item.consumer_price == undefined) {
                    item.consumer_price = "";
                }
                if (item.product == null || item.product == undefined) {
                    item.product = "";
                }
                if (item.balance2 == null || item.balance2 == undefined) {
                    item.balance2 = "";
                }
            })
            commit('setSalesPaymentReportPrintData', {receipt_data: tempData});
            commit('oldSalesPaymentFilter', {receipt_data: filters});
            commit('setLoadingFalse');
            return tempData;
        }
    },
    cancel_payment({dispatch, commit}, {supplierId, params}) {
        commit('setLoadingTrue');
        return reportService.cancel_payment(supplierId, params)
        .then(
            result => {
                commit('setLoadingFalse');
                return result;
                // if (result.success == "Transaction successfully canceled") {
                //     dispatch('alert/success', '', {root: true});
                // } else {
                //     dispatch('alert/error', '', {root: true});
                // }
            },
            error => {
                commit('setLoadingFalse');
                return error;
            }
        )
    }
};

const mutations = {
    setLoadingTrue(state) {
        state.loading = true;
    },
    setLoadingFalse(state) {
        state.loading = false;
    },
    setExportInProgressTrue(state) {
        state.exportInProgress = true;
    },
    setExportInProgressFalse(state) {
        state.exportInProgress = false;
    },
    /* sales report */
    setSalesReportData(state, {receipt_data}) {
        state.salesReportData_receipts = receipt_data;
    },
    setSalesPrintData(state, {receipt_data}) {
        state.salesPrintData_receipts = receipt_data;
    },
    /* payment report */
    setPaymentReportData(state, {receipt_data}) {
        state.paymentReportData_receipts = receipt_data;
    },
    setPaymentReportSum(state, {receipt_data}) {
        state.paymentReportSum_recepipts = receipt_data;
    },
    setPaymentReport_length(state, {receipt_data}) {
        state.paymentReport_length = receipt_data;
    },  
    setPaymentReportPrintData(state, {receipt_data}) {
        state.paymentPrintData_receipts = receipt_data;
    },
    setOldPaymentFilters(state, {receipt_data}) {
        state.oldPaymentFilter = receipt_data;
    },
    /* sales payment report */
    setSalesPaymentReportData(state, {receipt_data}) {
        state.salesPaymentReportData_receipts = receipt_data;
    },    
    setSalesPaymentCurrentBalance(state, {receipt_data}) {
        state.salesPaymentReportBalance_recepipts = receipt_data;
    },    
    setSalesPaymentReport_length(state, {receipt_data}) {
        state.salesPaymentReport_length = receipt_data;
    },
    oldSalesPaymentFilter(state, {receipt_data}) {
        state.oldSalesPaymentFilter = receipt_data;
    },
    setSalesPaymentReportPrintData(state, {receipt_data}) {
        state.salesPaymentPrintData_receipts = receipt_data;
    },
    setSalesDetails(state, {details}) {
        state.salesDetails = details;
    },
};

export const report = {
    namespaced: true,
    state,
    actions,
    mutations
};