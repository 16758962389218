import ApiService from "../services/api.service";

const state = {
  supplierProfile: [],
  supplierProfileData: [],
  profitProfile: [],
  profitProfileData: [],
  commissionsProfile: [],
  commissionsProfileData: [],
  banners: [],
  locations: [],
  tags: [],
  tourismProfile: [],
  manualCards: [],
  giftList: []
}

const actions = {
  getSupplierProfile({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/business/profile", {})
        .then(data => {
          commit('setSupplierProfile', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getSupplierProfileData({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/business/profile/" + id, {})
        .then(data => {
          commit('setSupplierProfileData', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateSupplierProfileData({ dispatch, commit }, { id, params }) {
    return new Promise((resolve, reject) => {
      ApiService.put("/business/profile/" + id, params)
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  createSupplierProfileData({ dispatch, commit }, { params }) {
    return new Promise((resolve, reject) => {
      ApiService.post("/business/profile/", params)
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getProfitProfile({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/profit/profile/profit/", {})
        .then(data => {
          commit('setProfitProfile', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getProfitProfileData({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/profit/profile/profit/" + id, {})
        .then(data => {
          commit('setProfitProfileData', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  createProfitProfile({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/profit/profile/", params)
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateProductProfit({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("/profit/profile/product", params)
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateProfitProfile({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      ApiService.put("/profit/profile", params)
        .then(data => {
          if (data != "success") {
            dispatch('alert/error', data.message, {root: true});
          } else {
            dispatch('alert/success', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getCommissionsProfile({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/profit/profile/commission/", {})
        .then(data => {
          commit('setCommissionsProfile', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getCommissionsProfileData({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/profit/profile/commission/" + id, {})
        .then(data => {
          commit('setCommissionsProfileData', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getBanners({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/banner", {})
        .then(data => {
          commit('setBanners', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  deleteCommissionProfile({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/business/profile/" + id, {})
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getLocations({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/banner/locations", {})
        .then(data => {
          commit('setLocations', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getTagsByType({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("/tag/tag?type=" + params.type, {})
        .then(data => {
          commit('setTags', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateBanner({ dispatch }, {id, params}) {
    return new Promise((resolve, reject) => {
      ApiService.put("/banner/" + id, params)
        .then(data => {
          if (data != "success") {
            dispatch('alert/error', data.message, {root: true});
          } else {
            dispatch('alert/success', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  createBanner({ dispatch }, {params}) {
    return new Promise((resolve, reject) => {
      ApiService.post("/banner", params)
        .then(data => {
          if (data != "success") {
            dispatch('alert/error', data.message, {root: true});
          } else {
            dispatch('alert/success', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getTourismProfile({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/tourism_profile", {})
        .then(data => {
          commit('setTourismProfile', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  createTourismProfile({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/tourism_profile/create", params)
        .then(data => {
          if (data.name == "Error") {
            dispatch('alert/error', '', {root: true});
            resolve("error");
          } else {
            dispatch('alert/success', '', {root: true});
            resolve(data);
          }
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateTourismProfile({ dispatch }, { id, params }) {
    return new Promise((resolve, reject) => {
      ApiService.put("/tourism_profile/update/" + id, params)
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  deleteTourismProfile({ dispatch }, id ) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/tourism_profile/delete/" + id, {})
        .then(data => {
          if (!data.error) {
            dispatch('alert/success', '', {root: true});
          } else {
            dispatch('alert/error', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getManualCards({ commit }, { id, params }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/product/manual_card/" + id, {params})
        .then(data => {
          commit('setManualCards', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  createManualCards({ dispatch }, {params}) {
    return new Promise((resolve, reject) => {
      ApiService.post("/product/manual_card", params)
        .then(data => {
          if (data != "success") {
            dispatch('alert/error', data.message, {root: true});
          } else {
            dispatch('alert/success', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  deleteManualCards({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/product/manual_card/" + id, {})
        .then(data => {
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateManualCards({ dispatch }, { id, params }) {
    return new Promise((resolve, reject) => {
      ApiService.put("/product/manual_card/" + id, params)
        .then(data => {
          if (data != "success") {
            dispatch('alert/error', data.message, {root: true});
          } else {
            dispatch('alert/success', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getGiftList({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/gift/gift", {})
        .then(data => {
          commit('setGiftList', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateGift({ dispatch }, { id, params }) {
    return new Promise((resolve, reject) => {
      ApiService.put("/gift/gift/" + id, params)
        .then(data => {
          if (data != "success") {
            dispatch('alert/error', data.message, {root: true});
          } else {
            dispatch('alert/success', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  createGift({ dispatch }, { params }) {
    return new Promise((resolve, reject) => {
      ApiService.post("/gift/gift", params)
        .then(data => {
          if (data != "success") {
            dispatch('alert/error', data.message, {root: true});
          } else {
            dispatch('alert/success', '', {root: true});
          }
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
};

const mutations = {
  setSupplierProfile(state, { receipt_data }) {
    state.supplierProfile = receipt_data;
  },
  setSupplierProfileData(state, { receipt_data }) {
    state.supplierProfileData = receipt_data;
  },
  setProfitProfile(state, { receipt_data }) {
    state.profitProfile = receipt_data;
  },
  setProfitProfileData(state, { receipt_data }) {
    state.profitProfileData = receipt_data;
  },
  setCommissionsProfile(state, { receipt_data }) {
    state.commissionsProfile = receipt_data;
  },
  setCommissionsProfileData(state, { receipt_data }) {
    state.commissionsProfileData = receipt_data;
  },
  setBanners(state, { receipt_data }) {
    state.banners = receipt_data;
  },
  setLocations(state, { receipt_data }) {
    state.locations = receipt_data;
  },
  setTags(state, { receipt_data }) {
    state.tags = receipt_data;
  },
  setTourismProfile(state, { receipt_data }) {
    state.tourismProfile = receipt_data;
  },
  setManualCards(state, { receipt_data }) {
    state.manualCards = receipt_data;
  },
  setGiftList(state, { receipt_data }) {
    state.giftList = receipt_data;
  },
};

export const category_products = {
  namespaced: true,
  state,
  actions,
  mutations
};

