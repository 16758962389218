import {giftCardPaymentService} from '../services';
import router from '../router/index';

const state = {
    current_payment_data: [],
    current_transaction_id: null,
    current_transaction_product_id: null,
    current_transaction_supplier_id: null,
    current_transaction_verification_code: null,
    transactions_data: [],
    transactions_receipts: [],
    transactions_statuses: [],
    verificationRequestInProgress: false,
    checkTransactionStatusInProgress: false,
    getTransactionReceiptInProgress: false,
    giftCardPaymentRequestInProgress: false
}
const actions = {
    giftCardPaymentRequest({ dispatch, commit }, {supplier_id, payment_data}) {
        commit('setGiftCardPaymentRequestInProgressTrue');
        commit('setCurrentPaymentData', {product_id: payment_data.itemId, payment_data: payment_data})

        return giftCardPaymentService.giftCardPayment(supplier_id, payment_data)
            .then(
                result_data => {
                    if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                        && result_data.hasOwnProperty('success') && result_data.success) {
                        commit('setCurrentTransactionId', {transaction_id: result_data.currentTransaction})
                        commit('setCurrentTransactionProductId', {product_id: payment_data.itemId})
                        commit('setCurrentTransactionSupplierId', {supplier_id: supplier_id})
                        if ('verificationCode' in result_data.success) {
                            commit('setCurrentTransactionVerificationCode', {verification_code: result_data.success.verificationCode})
                        }
                    }

                    commit('setGiftCardPaymentRequestInProgressFalse');
                    return result_data
                },
                error => {
                    if ('data' in error && 'error' in error.data) {
                        dispatch('alert/error', error.data.error, {root: true});
                    }

                    commit('setGiftCardPaymentRequestInProgressFalse');
                    return error
                }
            );
    },
    giftCardPaymentVerificationRequest({ dispatch, commit }, {supplier_id, payment_data}) {
        commit('setVerificationRequestInProgressTrue');

        return giftCardPaymentService.giftCardPayment(supplier_id, payment_data)
            .then(
                result_data => {
                    commit('setVerificationRequestInProgressFalse');
                    return result_data
                },
                error => {
                    commit('setVerificationRequestInProgressFalse');
                    return error
                }
            );
    },
    getTransactionData({ commit }, {transaction_id}) {
        return giftCardPaymentService.getTransactionData(transaction_id)
            .then(
                transaction_data => {
                    if (transaction_data) {
                        commit('setTransactionData', {transaction_id: transaction_id, transaction_data: transaction_data})
                    }

                    return transaction_data;
                },
                error => {
                    return error;
                }
            );
    },
    getTransactionReceipt({ commit }, {transaction_id, phoneNumber}) {
        commit('setGetTransactionReceiptInProgressTrue');
        return giftCardPaymentService.getTransactionReceipt(transaction_id, phoneNumber)
            .then(
                receipt_data => {
                    if (receipt_data && receipt_data.hasOwnProperty('transId') && receipt_data.transId) {
                        commit('setTransactionReceipt', {transaction_id: receipt_data.transId, receipt_data: receipt_data})
                    }
                    commit('setGetTransactionReceiptInProgressFalse');
                    return receipt_data;
                },
                error => {
                    commit('setGetTransactionReceiptInProgressFalse');
                    return error;
                }
            );
    },
    checkTransactionStatus({ dispatch, commit }, {transaction_id, phoneNumber}) {
        commit('setCheckTransactionStatusInProgressTrue');

        return giftCardPaymentService.checkTransactionStatus(transaction_id, phoneNumber)
            .then(
                status_data => {
                    if (status_data && status_data.hasOwnProperty('status')) {
                        commit('setTransactionStatus', {transaction_id: transaction_id, status_data: status_data})
                        if (status_data.status == 3) {
                            dispatch('account/getUserDetails', {check_if_user_logged: true}, {root:true})
                        }
                    }

                    commit('setCheckTransactionStatusInProgressFalse');
                    return status_data
                },
                error => {
                    commit('setCheckTransactionStatusInProgressFalse');
                    return error
                }
            );
    },
    cancelTransactionRequest({ dispatch, commit }, {transaction_id}) {
        return giftCardPaymentService.cancelTransaction(transaction_id)
            .then(
                result_data => {
                    return result_data
                },
                error => {
                    return error
                }
            );
    }
};

const mutations = {
    setCurrentPaymentData(state, {product_id, payment_data}) {
        let current_payment_data = state.current_payment_data
        current_payment_data[product_id] = payment_data
        state.current_payment_data = null
        state.current_payment_data = current_payment_data
    },
    setCurrentTransactionId(state, {transaction_id}) {
        state.current_transaction_id = transaction_id;
    },
    setCurrentTransactionProductId(state, {product_id}) {
        state.current_transaction_product_id = product_id;
    },
    setCurrentTransactionSupplierId(state, {supplier_id}) {
        state.current_transaction_supplier_id = supplier_id;
    },
    setCurrentTransactionVerificationCode(state, {verification_code}) {
        state.current_transaction_verification_code = verification_code;
    },
    setTransactionData(state, {transaction_id, transaction_data}) {
        let transactions_data = state.transactions_data || []
        transactions_data[transaction_id] = transaction_data
        state.transactions_data = null;
        state.transactions_data = transactions_data;
    },
    setTransactionReceipt(state, {transaction_id, receipt_data}) {
        let transactions_receipts = state.transactions_receipts || []
        transactions_receipts[transaction_id] = receipt_data
        state.transactions_receipts = null;
        state.transactions_receipts = transactions_receipts;
    },
    setTransactionStatus(state, {transaction_id, status_data}) {
        let transactions_statuses = state.transactions_statuses || []
        transactions_statuses[transaction_id] = status_data
        state.transactions_statuses = null;
        state.transactions_statuses = transactions_statuses;
    },
    setVerificationRequestInProgressTrue(state) {
        state.verificationRequestInProgress = true;
    },
    setVerificationRequestInProgressFalse(state) {
        state.verificationRequestInProgress = false;
    },
    setCheckTransactionStatusInProgressTrue(state) {
        state.checkTransactionStatusInProgress = true;
    },
    setCheckTransactionStatusInProgressFalse(state) {
        state.checkTransactionStatusInProgress = false;
    },
    setGiftCardPaymentRequestInProgressTrue(state) {
        state.giftCardPaymentRequestInProgress = true;
    },
    setGiftCardPaymentRequestInProgressFalse(state) {
        state.giftCardPaymentRequestInProgress = false;
    },
    setGetTransactionReceiptInProgressTrue(state) {
        state.getTransactionReceiptInProgress = true;
    },
    setGetTransactionReceiptInProgressFalse(state) {
        state.getTransactionReceiptInProgress = false;
    }
};

export const gift_card_payment = {
    namespaced: true,
    state,
    actions,
    mutations
};