import {supplierPaymentService} from '../services';
import router from '../router/index';

const state = {
    current_payment_data: [],
    current_transaction_id: [],
    transactions_receipts: [],
    supplierPaymentInProgress: false,
    getTransactionReceiptInProgress: false,
    getSupplierPaymentDetailsInProgress: false
}
const actions = {
    supplierPayment({ dispatch, commit }, {supplier_id, payment_data}) {
        commit('setSupplierPaymentInProgressTrue');

        return supplierPaymentService.supplierPayment(supplier_id, payment_data)
            .then(
                result_data => {
                    if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                        && result_data.hasOwnProperty('success') && result_data.success) {
                        commit('setCurrentTransactionId', {transaction_id: result_data.currentTransaction, supplier_id: supplier_id})
                        dispatch('account/getUserDetails', {}, {root:true})
                        router.push({name: 'SupplierPaymentSuccess', params: {supplier_id: supplier_id, transaction_id: result_data.currentTransaction}})
                    } else {
                        dispatch('alert/error', (result_data.error || 'Something went wrong'), {root: true});
                        router.push({name: 'SupplierPayment', params: {supplier_id: supplier_id}})
                    }

                    commit('setSupplierPaymentInProgressFalse');
                    return result_data
                },
                error => {
                    let errorText = error.response && 'data' in error.response && 'error' in error.response.data
                        ? error.response.data.error : 'Something went wrong';
                    dispatch('alert/error', errorText, {root: true});
                    router.push({name: 'SupplierPayment', params: {supplier_id: supplier_id}})

                    commit('setSupplierPaymentInProgressFalse');
                    return error
                }
            );
    },
    getTransactionReceipt({ commit }, {transaction_id}) {
        commit('setGetTransactionReceiptInProgressTrue');

        return supplierPaymentService.getTransactionReceipt(transaction_id)
            .then(
                receipt_data => {
                    if (receipt_data && receipt_data.hasOwnProperty('transId') && receipt_data.transId) {
                        commit('setTransactionReceipt', {transaction_id: receipt_data.transId, receipt_data: receipt_data})
                    }
                    commit('setGetTransactionReceiptInProgressFalse');
                    return receipt_data;
                },
                error => {
                    commit('setGetTransactionReceiptInProgressFalse');

                    return error;
                }
            );
    },
    getSupplierPaymentDetails({ dispatch, commit }, {supplier_id, payment_data}) {
        commit('setGetSupplierPaymentDetailsInProgressTrue');

        return supplierPaymentService.getSupplierPaymentDetails(supplier_id, payment_data)
            .then(
                result_data => {
                    if (!result_data || result_data.hasOwnProperty('error')) {
                        dispatch('alert/error', (result_data.error || 'Something went wrong'), {root: true});
                    }

                    commit('setGetSupplierPaymentDetailsInProgressFalse');
                    return result_data
                },
                error => {
                    let errorText = error.response && 'data' in error.response && 'error' in error.response.data
                        ? error.response.data.error : 'Something went wrong';
                    dispatch('alert/error', errorText, {root: true});

                    commit('setGetSupplierPaymentDetailsInProgressFalse');
                    return error
                }
            );
    },
};

const mutations = {
    setCurrentPaymentData(state, {supplier_id, payment_data}) {
        let current_payment_data = state.current_payment_data
        current_payment_data[supplier_id] = payment_data
        state.current_payment_data = null
        state.current_payment_data = current_payment_data
    },
    unsetCurrentPaymentData(state, {supplier_id}) {
        let current_payment_data = state.current_payment_data
        delete current_payment_data[supplier_id]
        state.current_payment_data = null
        state.current_payment_data = current_payment_data
    },
    setTransactionReceipt(state, {transaction_id, receipt_data}) {
        let transactions_receipts = state.transactions_receipts || []
        transactions_receipts[transaction_id] = receipt_data
        state.transactions_receipts = null;
        state.transactions_receipts = transactions_receipts;
    },
    setCurrentTransactionId(state, {transaction_id, supplier_id}) {
        let current_transaction_id = state.current_transaction_id || []
        current_transaction_id[supplier_id] = transaction_id
        state.current_transaction_id = null;
        state.current_transaction_id = current_transaction_id;
    },
    setSupplierPaymentInProgressTrue(state) {
        state.supplierPaymentInProgress = true;
    },
    setSupplierPaymentInProgressFalse(state) {
        state.supplierPaymentInProgress = false;
    },
    setGetTransactionReceiptInProgressTrue(state) {
        state.fetTransactionReceiptInProgress = true;
    },
    setGetTransactionReceiptInProgressFalse(state) {
        state.fetTransactionReceiptInProgress = false;
    },
    setGetSupplierPaymentDetailsInProgressTrue(state) {
        state.getSupplierPaymentDetailsInProgress = true;
    },
    setGetSupplierPaymentDetailsInProgressFalse(state) {
        state.getSupplierPaymentDetailsInProgress = false;
    }
};

export const supplier_payment = {
    namespaced: true,
    state,
    actions,
    mutations
};