// import { languageService } from '../services';
import i18n from '../common/plugins/vue-i18n';
import ApiService from "../services/api.service";

const state = {
  langData_receipts: []
};

const actions = {
  addLangData({ dispatch, commit }, { group, key, en, he, ar }) {
    return new Promise((resolve, reject) => {
      ApiService.post("/translation/", {
        'group': group,
        'key': key,
        'en': en, 
        'he': he,
        'ar': ar })
        .then(data => {
          dispatch('getAllData');
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  updateLangData({ dispatch, commit }, { id, group, key, en, he, ar }) {
    return new Promise((resolve, reject) => {
      ApiService.put("/translation/" + id, {
        'group': group,
        'key': key,
        'en': en, 
        'he': he,
        'ar': ar })
        .then(data => {
          dispatch('getAllData');
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  delLangData({ dispatch, commit }, { id }) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/translation/" + id, {})
        .then(data => {
          for (let i = 0; i < state.langData_receipts.length; i++) {
            if (state.langData_receipts[i].id == id) {
              state.langData_receipts.splice(i, 1);
              break;
            }
          }
          dispatch('alert/success', '', {root: true});
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  getAllData({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("/translation/", {})
        .then(data => {
          commit('setLangData', { receipt_data: data })
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },
  changeCurrentLang({ commit }, { lang }) {
    localStorage.removeItem("Cheapest_deals");
    return new Promise((resolve, reject) => {
      ApiService.put("/user/switch_language", {
        "lang": lang
      })
        .then(data => {
          resolve(data);
        })
        .catch(data => {
          reject(data);
        });
    });
  },


};

const mutations = {
  setLangData(state, { receipt_data }) {
    let langData_receipts = state.langData_receipts || []
    langData_receipts = receipt_data
    state.langData_receipts = null;
    state.langData_receipts = langData_receipts;
  }
};

export const language = {
  namespaced: true,
  state,
  actions,
  mutations
};