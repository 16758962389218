<template>
  <div>
    <add-to-home-screen/>
    <Alert></Alert>
    <AlertTwo></AlertTwo>
    <router-view></router-view>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </div>
</template>

<style lang="scss">
@import 'assets/scss/main';

</style>

<script>
import { mapState} from "vuex";
import Alert from './components/Alert'
import AlertTwo from './components/AlertTwo'
import AddToHomeScreen from "./components/AddToHomeScreen";
export default {
    name: 'app',
    components: {AddToHomeScreen, Alert, AlertTwo},
    computed: {
    ...mapState({
        loading: state => state.loading.loading
      })
    }
}
</script>