import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const paymentService = {
    productPayment,
    getTransactionReceipt,
    getWeComNumbers
};

function productPayment(
  supplier_id,
  product_id,
  contract_number,
  phone_number,
  isPorted,
  provider_type,
) {
  let params = {
    supplier_id: supplier_id,
    itemId: product_id,
    contractNumber: contract_number,
    phoneNumber: phone_number,
    isPorted: isPorted,
    provider_type: provider_type,
  };
  const config = {
    method: 'POST',
    url: appConfig.apiUrl + '/payment/payment/' + supplier_id,
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getTransactionReceipt(transaction_id) {
    let params = {
        transaction_id: transaction_id
    }
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/transaction/receipt/' + transaction_id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getWeComNumbers() {
    let params = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/payment/getWeComNumbers',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}