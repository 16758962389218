import Vue from "vue";
import VueI18n from "vue-i18n";

import he from 'vuetify/lib/locale/he'
import ar from 'vuetify/lib/locale/ar'
import en from 'vuetify/lib/locale/en'

Vue.use(VueI18n);

const messages = {
  en: {
    ...require('@/common/config/i18n/en.json'),
    $vuetify: en,
  },
  ar: {
    ...require('@/common/config/i18n/ar.json'),
    $vuetify: ar,
  },
  he: {
    ...require('@/common/config/i18n/he.json'),
    $vuetify: he,
  },
}

let defaultLanguage = "he";
// get current selected language
const lang = localStorage.getItem("language") || defaultLanguage;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;
