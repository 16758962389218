import appConfig from '../appConfig';
import axios from 'axios';
import router from '../router';

//declare functions
export const chargeRavkavService = {
  connectReader,
  getReloadingStatus,
  getContracts,
  createTransaction,
  payRavKav,
  getRavKavTransaction,
  getRavKavFailure,
  getStationId,
  getProfit,
  getBusinessId,
  ravkavPayment,
};

function connectReader() {
  const url = appConfig.apiUrl + `/ravkav/openSession/`;
  const config = {
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {},
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getReloadingStatus(uid, remote_id, location) {
  const url = appConfig.apiUrl + `/ravkav/get_reloading_status/`;
  const config = {
    method: 'GET',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      uid: uid,
      remote_id: remote_id,
      location: location,
    },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getContracts(remote_id, location, cardDump) {
  const url = appConfig.apiUrl + `/ravkav/getContracts/`;
  const config = {
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      remote_id: remote_id,
      location: location,
      // cardDump: cardDump
    },
    data: { cardDump },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function createTransaction(choosen_contract, remote_id, location) {
  const url = appConfig.apiUrl + `/ravkav/createTransaction/`;
  const config = {
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      contract: choosen_contract,
      remote_id: remote_id,
      location: location,
    },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function payRavKav(remote_id, location, uid) {
  const url = appConfig.apiUrl + `/ravkav/payWithBalance/`;
  const config = {
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      remote_id: remote_id,
      location: location,
      uid: uid,
    },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      console.log(res);
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getRavKavTransaction(uid, remote_id, location, status) {
  const url = appConfig.apiUrl + `/ravkav/get_reloading_result`;
  const config = {
    method: 'GET',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      uid: uid,
      remote_id: remote_id,
      location: location,
      status: status,
    },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getRavKavFailure(uid, remote_id, location) {
  const url = appConfig.apiUrl + `/ravkav/get_reloading_result_fail`;
  const config = {
    method: 'GET',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      uid: uid,
      remote_id: remote_id,
      location: location,
    },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getStationId(id) {
  const url = appConfig.apiUrl + `/ravkav/get_station/`;
  const config = {
    method: 'GET',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      id: id,
    },
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getProfit(supplier_id) {
  const url = appConfig.apiUrl + '/supplier/' + supplier_id;
  const config = {
    method: 'GET',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {},
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      console.log('catch');
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function ravkavPayment(
  supplier_id,
  payment_data,
  uid,
  serial_number,
  price,
  priceForSupplier,
  contract_details,
) {
  //uid, price, serial_number
  let params = {
    ...payment_data,
    supplier_id: supplier_id ? supplier_id : null,
    transaction: {
      price: price,
      serial_number: serial_number,
    },
    uid: uid,
    priceForSupplier: priceForSupplier,
    contract_details: contract_details,
  };
  const config = {
    method: 'POST',
    url: appConfig.apiUrl + '/payment/payment/' + '999',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {},
    data: params,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getBusinessId(id) {
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/business/getUserid',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    params: {
      userId: id,
    },
    data: {
      userId: id,
    },
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
