const state = {
    alerts: [],
    current_alert_type: null,
    current_alert_message: null
};

const actions = {
    success({commit}, message) {
        commit('success', message)
    },
    info({commit}, message) {
        commit('info', message)
    },
    error({commit}, message) {
        commit('error', message)
    },
    initCurrentAlert({commit}) {
        commit('initCurrentAlert')
    },
    clearCurrentAlert({commit}) {
        commit('clearCurrentAlert')
    }
};

const mutations = {
    success(state, message) {
        let alert = {
            type: 'success',
            message: message
        }
        state.alerts.push(alert)
    },
    info(state, message) {
        let alert = {
            type: 'info',
            message: message
        }
        state.alerts.push(alert)
    },
    error(state, message) {
        let alert = {
            type: 'error',
            message: message
        }
        state.alerts.push(alert)
    },
    initCurrentAlert(state) {
        if (state.alerts && state.alerts.length) {
            let currentAlert = state.alerts.shift()

            state.current_alert_type = currentAlert.type
            state.current_alert_message = currentAlert.message
        }
    },
    clearCurrentAlert(state) {
        state.current_alert_type = null
        state.current_alert_message = null
    }
};

export const alertTwo = {
    namespaced: true,
    state,
    actions,
    mutations
};