<template>

</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
    name: "AddToHomeScreen",
    data() {return {}},
    computed: {
        ...mapState({
            deferredPrompt: state => state.add_to_home_screen.deferredPrompt,
        }),
    },
    created() {
    },
    methods: {
        ...mapActions('add_to_home_screen', ['setDeferredPrompt']),
        captureEvent() {
            console.log("add_to_home_screen captureEvent called");

            window.removeEventListener('beforeinstallprompt', this.beforeinstallpromptEventHandler);
            window.addEventListener('beforeinstallprompt', this.beforeinstallpromptEventHandler);

            window.removeEventListener("click", this.addToHomeClickHandler);
            window.addEventListener("click", this.addToHomeClickHandler);
        },
        beforeinstallpromptEventHandler(e) {
            console.log("beforeinstallprompt event triggered");
            // ! Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.setDeferredPrompt({deferredPrompt: e});
            console.log("this.deferredPrompt was set to", this.deferredPrompt);
        },
        addToHomeClickHandler(event) {
            // Show the prompt
            if (event.target
                && event.target.classList
                && event.target.classList.contains("add-to-home-screen")
                && this.deferredPrompt) {
                event.preventDefault();
                console.log('add-to-home-screen clicked')

                this.deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                this.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        // Call another function?
                    }
                    this.setDeferredPrompt({deferredPrompt: e});
                })
            }
        },
    },
    mounted() {
        console.log("add_to_home_screen mounted");
        this.captureEvent();
    },
    beforeDestroy() {
        window.removeEventListener('beforeinstallprompt', this.beforeinstallpromptEventHandler);
        window.removeEventListener('click', this.addToHomeClickHandler);
    },
};
</script>