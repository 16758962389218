import appConfig from "../appConfig";
import axios from "axios";
import router from "../router";

export const resetService = {
    changeSuffix,
    handleApprove,
    handleVerificationCode,
    handleNewPassword
}

function changeSuffix(loginName) {
    const url = appConfig.apiUrl + `/reset/suffix_phone_new/`
    const config = {
        method: 'GET',
        url,
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            username: loginName,
        },
        withCredentials: 1,
    };

    return axios(config)
        .then((res) => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data;
        })
        .catch((err) => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        });
}

function handleApprove(loginName, phone_number) {
    const url = appConfig.apiUrl + `/reset/check_phone_new/`
    const config = {
        method: 'GET',
        url,
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            username: loginName,
            phone_number: phone_number,
        },
        withCredentials: 1,
    };

    return axios(config)
        .then((res) => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data;
        })
        .catch((err) => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        });
}

function handleVerificationCode(loginName, verificationCode) {
    const url = appConfig.apiUrl + `/reset/check_verification_code/`
    const config = {
        method: 'POST',
        url,
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            username: loginName,
            verificationCode: verificationCode
        },
        withCredentials: 1,
    };

    return axios(config)
    .then((res) => {
        if (res.status !== 200) {
            const err = (res.data && res.data.message) || res.statusText;
            return Promise.reject(err);
        }

        return res.data;
    })
    .catch((err) => {
        if (err.response.status === 401) {
            router.push('/login');
        }

        return Promise.reject(err);
    });
}

 function handleNewPassword(loginName, verificationCode, newPassword) {
    const bodyData = {
        new_password: newPassword
    }

    const url = appConfig.apiUrl + `/reset/reset_with_verification_code/`
    const config = {
        method: 'POST',
        url,
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            username: loginName,
            verificationCode: verificationCode,
        },
        data: bodyData,
        withCredentials: 1,
    };
    return axios(config)
    .then((res) => {
        if (res.status !== 200) {
            const err = (res.data && res.data.message) || res.statusText;
            return Promise.reject(err);
        }

        return res.data;
    })
    .catch((err) => {
        if (err.response.status === 401) {
            router.push('/login');
        }

        return Promise.reject(err);
    });
 }
