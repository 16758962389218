import {productShaniv as service, shaniv_managementService} from '../services';

const state = {
    products: [],
    totalProducts: 0,
    getProductsInProgress: false,
    shanivTags: [],
    priceList:null,
    getProductDataInProgress: false,
    productData: {}
}

const actions = {
    async getProducts({commit}, params) {
        try {
            commit('setGetProductsInProgressTrue');
            let result = await service.getProducts(params);
            result.data.forEach(el => {
                el.isChecked = false;
            });
            if (params.page > 0) {
                commit('setProducts', {
                    items: result != "" && result.hasOwnProperty('data') ? result.data : [],
                    isForAdd: true
                });
            } else {
                commit('setProducts', {
                    items: result != "" && result.hasOwnProperty('data') ? result.data : [],
                    isForAdd: false
                });
            }
            commit('setTotalProducts', {totalProducts: result != "" && result.hasOwnProperty('total') ? result.total : 0});
            commit('setGetProductsInProgressFalse');
        } catch (er) {
            commit('setProducts', {items: []});
            commit('setTotalProducts', {totalProducts: 0});
            commit('setGetProductsInProgressFalse');
        }
    },
    getShanivTags({ commit }) {
        commit('setGetProductsInProgressTrue');
        return service.getShanivTags()
          .then(
            receipt_data => {
              commit('setGetProductsInProgressFalse');
              if (receipt_data) {
                commit('setShanivTags', { receipt_data: receipt_data })
              }
            },
            error => {
              commit('setGetProductsInProgressFalse');
            }
        );
    },
    getPriceList({commit}) {
        return service.getPriceList()
        .then(
            priceList => {
                commit('setPriceList', priceList)
            }
        )
    },
    getProductData({commit}, params) {
        commit('setGetProductDataInProgressTrue');
        return service.getProducts(params).then(
            (result) => {
                commit('setGetProductDataInProgressFalse');
                if (result && result.hasOwnProperty('data')) {
                    const productData = result.data[0];
                    commit('setProductData', {productData: productData});
                }
            },
            (error) => {
                commit('setGetProductDataInProgressFalse');
            },
        );
    },
};

const mutations = {
    setProducts(state, { items, isForAdd }) {
        if (isForAdd) {
            state.products = state.products.concat(items);
        } else {
            state.products = items;
        }
    },
    setProductData(state, { productData }) {
        state.productData = Object.assign({}, productData)
    },
    setTotalProducts(state, { totalProducts }) {
        state.totalProducts = totalProducts
    },
    setShanivTags(state, { receipt_data }) {
        state.shanivTags = receipt_data
    },
    setGetProductsInProgressTrue(state) {
        state.getProductsInProgress = true;
    },
    setGetProductsInProgressFalse(state) {
        state.getProductsInProgress = false;
    },
    setGetProductDataInProgressTrue(state) {
        state.getProductDataInProgress = true;
    },
    setGetProductDataInProgressFalse(state) {
        state.getProductDataInProgress = false;
    },
    setPriceList(state, list){
        state.priceList = list
    }
};

const getters = {
    products: state => state.products
}

export const productShaniv = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};