import { shaniv_customerService } from '../services';

const state = {
    customerData: {},
    minOrderAmount: 500,
    customerBalance: 1500,
    PLNAME: '',
    loading: false,
};

const actions = {
  getCustomerData ({ commit }) {
    commit('setLoadingTrue');
    return shaniv_customerService.getCustomerData().then(
      (receipt_data) => {
        commit('setCustomerData', receipt_data);
        commit('setLoadingFalse');
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
};

const mutations = {
    setLoadingTrue(state) {
        state.loading = true;
    },
    setLoadingFalse(state) {
        state.loading = false;
    },
    setCustomerData(state, customersData) {
        customersData = Array.isArray(customersData)
            ? customersData : [customersData];
        for (let customer of customersData) {
            if (!customer.company || state.customerData[customer.company]) {
                continue;
            }

            state.customerData[customer.company] = {...(customer)};
        }
        state.customerData = {...(state.customerData)};
    }
};

const getters = {
    minOrderAmount: (state) => state.minOrderAmount,
    customerBalance: (state) => state.customerBalance,
    PLNAME: (state) => state.PLNAME,
};

export const shaniv_customer = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};