import appConfig from '../appConfig';
import axios from 'axios';
import router from '../router';

export const cartService = {
  createOrder,
  saveTempOrder,
  getShanivOrder,
  getTempOrder,
  removeTempOrder,
  getShanivOrderStatuses,
  getShanivOrderData,
  getPaymentDetails,
  payOrder
};

function createOrder(postOrderData) {
  const config = {
    method: 'post',
    url: appConfig.apiUrl + '/shaniv/order',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
    data: postOrderData,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function saveTempOrder(params) {
  params = params || {};
  const config = {
    method: 'post',
    url: appConfig.apiUrl + '/shaniv/temp_order',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
    withCredentials: 1
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function removeTempOrder(id) {
  const params = {};
  const config = {
    method: 'DELETE',
    url: appConfig.apiUrl + '/shaniv/temp_order/' + id,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getShanivOrder(filters) {
  const params = {...filters};
  const config = {
    method: 'get',
    url: appConfig.apiUrl + '/shaniv/order',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getShanivOrderData(id) {
  const params = {};
  const config = {
    method: 'get',
    url: appConfig.apiUrl + '/shaniv/order/' + id,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getPaymentDetails(id) {
  const params = {};
  const config = {
    method: 'get',
    url: appConfig.apiUrl + '/shaniv/order/' + id + '/payment_details',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function payOrder(id, data) {
    const params = {...data};
    const config = {
        method: 'post',
        url: appConfig.apiUrl + '/shaniv/order/' + id + '/pay',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    };

    return axios(config)
        .then((res) => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data;
        })
        .catch((err) => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        });
}
function getShanivOrderStatuses() {
  const params = {};
  const config = {
    method: 'get',
    url: appConfig.apiUrl + '/shaniv/order/status',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
function getTempOrder() {
  const params = {};
  const config = {
    method: 'get',
    url: appConfig.apiUrl + '/shaniv/temp_order',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
