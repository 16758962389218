import { end_userService } from '../services';
import ApiMainService from "../services/api_main.service";
import Vue from 'vue';

import i18n from '../common/plugins/vue-i18n';

const state = {
  isLoggedIn: false,
  user: {},
  creditCardsData: [],
  addressesData: [],
  banksData: [],
  invoicesData: [],
  tripsData: [],
  homeSuppliersData: []
};

const actions = {
  /*--------------------------- auth actions ---------------------------*/
  register({}, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/auth/register", params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/auth/login", params)
        .then(res => {
          if (res.message.title === 'Check 2fa') {
            commit('setUserId', res.user_id);
          } else if (res.message.title === 'Success') {
            localStorage.setItem("User", JSON.stringify(res.user));
            commit('setLoggedIn', true);
          }
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  isAuth({ commit }) {
    return new Promise((resolve, reject) => {
      ApiMainService.get("/enduser/auth/isAuth")
        .then(res => {
          if (res === true || res === 'true') {
            commit('setLoggedIn', true)
          } else {
            commit('setLoggedIn', false)
          }
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  confirmVerifyCode({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/auth/confirmVerifyCode", params)
        .then(res => {
          localStorage.setItem("User", JSON.stringify(res.user));
          commit('setLoggedIn', true);
          resolve(res);
        })
        .catch(res => {
          commit('setLoggedIn', false);
          reject(res);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/auth/logout")
        .then(res => {
          localStorage.removeItem("User");
          localStorage.removeItem("user_type_logged_in");
          commit('setLoggedIn', false)
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  /*--------------------------- account setting tab actions ---------------------------*/
  getEndUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiMainService.get("/enduser/account/" + id)
        .then(res => {
          commit('setUserData', res);
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  updateEndUser({}, {id, params}) {
    return new Promise((resolve, reject) => {
      ApiMainService.put("/enduser/account/" + id, params)
        .then(res => {
          localStorage.setItem("User", JSON.stringify(res.user));
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  resetPassword({}, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/account/reset_password", params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  checkEmail({}, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/account/checkEmail", params)
        .then(res => {
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  changeEmail({}, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/account/changeEmail", params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  /*--------------------------- account billing tab actions ---------------------------*/
  getCreditCards({ commit }) {
    return new Promise((resolve, reject) => {
      ApiMainService.get("/enduser/payment/creditCards")
        .then(res => {
          commit('setCreditCardsData', res);
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  createCreditCard({}, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/payment/creditCard", params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  updateCreditCard({}, {id, params}) {
    return new Promise((resolve, reject) => {
      ApiMainService.put("/enduser/payment/creditCard/" + id, params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  deleteCreditCard({}, {id}) {
    return new Promise((resolve, reject) => {
      ApiMainService.delete("/enduser/payment/creditCard/" + id)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

 /*--------------------------- account billing tab-address actions ---------------------------*/
  getAddresses({ commit }) {
    return new Promise((resolve, reject) => {
      ApiMainService.get("/enduser/address/addresses")
        .then(res => {
          commit('setAddressesData', res);
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  createAddress({}, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/address/address", params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  updateAddress({}, {id, params}) {
    return new Promise((resolve, reject) => {
      ApiMainService.put("/enduser/address/address/" + id, params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  deleteAddress({}, {id}) {
    return new Promise((resolve, reject) => {
      ApiMainService.delete("/enduser/address/address/" + id)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

 /*--------------------------- account billing tab-bank actions ---------------------------*/
  getBanks({ commit }) {
    return new Promise((resolve, reject) => {
      ApiMainService.get("/enduser/payment/banks")
        .then(res => {
          commit('setBanksData', res);
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  createBank({}, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/enduser/payment/bank", params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  updateBank({}, {id, params}) {
    return new Promise((resolve, reject) => {
      ApiMainService.put("/enduser/payment/bank/" + id, params)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  deleteBank({}, {id}) {
    return new Promise((resolve, reject) => {
      ApiMainService.delete("/enduser/payment/bank/" + id)
        .then(res => {
          Vue.swal({
            icon: 'success',
            title: i18n.t(res.message.title),
            text: i18n.t(res.message.text),
          });
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  /*--------------------------- account invoices tab actions ---------------------------*/
  getInvoices({ commit }) {
    return new Promise((resolve, reject) => {
      ApiMainService.get("/enduser/payment/invoices")
        .then(res => {
          commit('setInvoicesData', res);
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },
  
  /*--------------------------- account trips tab actions ---------------------------*/
  getTrips({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiMainService.post("/amadeus/amadeus", params)
        .then(res => {
          commit('setTripsData', res);
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },

  /*--------------------------- account trips tab actions ---------------------------*/
  getHomeSuppliers({ commit }) {
    return new Promise((resolve, reject) => {
      ApiMainService.get("/supplier?filter=true")
        .then(res => {
          commit('setHomeSuppliersData', res);
          resolve(res);
        })
        .catch(res => {
          reject(res);
        });
    });
  },
};

const mutations = {
  setLoggedIn(state, val) {
    state.isLoggedIn = val;
  },
  setUserId(state, user_id) {
    state.user_id = user_id;
  },
  setUserData(state, data) {
    state.user = data;
  },
  setCreditCardsData(state, data) {
    state.creditCardsData = data;
  },
  setAddressesData(state, data) {
    state.addressesData = data;
  },
  setBanksData(state, data) {
    state.banksData = data;
  },
  setInvoicesData(state, data) {
    state.invoicesData = data;
  },
  setTripsData(state, data) {
    state.tripsData = data;
  },
  setHomeSuppliersData(state, data) {
    state.homeSuppliersData = data;
  },
};

export const end_user = {
  namespaced: true,
  state,
  actions,
  mutations
};