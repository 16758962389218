import { settingService } from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
  general_setting: [],
  loading: false
};

const actions = {
  update_generalSetting({ dispatch, commit }) {
    commit('setLoadingTrue');
    return settingService.updateGeneralSetting( state.general_setting )
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data == "Success") {
            dispatch('getAllData');
            dispatch('alert/success', '', {root: true});
          }
        },
        // error => {}
      );
  },
  get_generalSetting({ commit }) {
    commit('setLoadingTrue');
    return settingService.getData()
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setSettingData', { receipt_data: receipt_data })
          }
        },
        // error => {}
      );
  },

};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setSettingData(state, { receipt_data }) {
    state.general_setting = receipt_data;
  }
};

export const setting = {
  namespaced: true,
  state,
  actions,
  mutations
};