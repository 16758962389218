import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const productService = {
    getProduct,
    getSupplierProducts,
    getProductTags,
    updateProduct,
    getProductType,
    saveProduct,
    updateHomeOrder
};

function getProduct(product_id) {
    let params = {
        product_id: product_id
    }
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/product/' + product_id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getSupplierProducts(supplier_id, params) {
    params = params || {}
    params = {
        filter: true,
        ...params
    }
    if (supplier_id) {
        params.supplier_id = supplier_id
    }
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/product/products/' + (supplier_id ? supplier_id : ''),
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getProductTags() {
    let params = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/product/tags',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function updateProduct(id, params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/product/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getProductType() {
    let params = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/product/types',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function saveProduct(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/product',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function updateHomeOrder(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/product/home_order',
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}