import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";
import { createLogger } from 'vuex';


export const reportService = {
    filter_sales_report,
    export_sales_report,
    filter_payment_reportLength,
    filter_payment_reportData,
    filter_sales_payment_reportLength,
    filter_sales_payment_reportData,
    cancel_payment
};
/* sales report */
function filter_sales_report(limit, skip, filters) {
    let params = {...filters, limit: limit, skip: skip};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/report/sales',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }
            

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function export_sales_report(format, fields, filters) {
    let params = {...filters, fields: fields};
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/report/sales/export/' + (format || ''),
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1,
        responseType: 'blob'
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

/* payment report */
function filter_payment_reportLength(limit, skip, filters) {
    let params = {...filters, limit: limit, offset: skip};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/report/report_length/1',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function filter_payment_reportData(limit, skip, filters) {
    let params = {...filters, limit: limit, offset: skip};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/report/report_data/1',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

/* sales payment report */
function filter_sales_payment_reportLength(limit, skip, filters) {
    let params = {...filters, limit: limit, offset: skip};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/report/report_length/2',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function filter_sales_payment_reportData(limit, skip, filters) {
    let params = {...filters, limit: limit, offset: skip};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/report/report_data/2',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }
    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function cancel_payment(supplierId, params) { //uid, price, serial_number
    // let supplierID = supplier_id
    // let params = {
    //     itemId: itemId,
    //     transaction_id: transaction_id,
    //     transactionId: transaction_id,
    //     supplier_id: (supplier_id ? supplier_id : null),
    //     transaction: {
    //         price: price,
    //     }
    // }
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/payment/cancel/' + supplierId,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: 1,
        data: params,
      };
   
      return axios(config)
        .then((res) => {
          if (res.status !== 200) {
            const err = (res.data && res.data.message) || res.statusText;
            return Promise.reject(err);
          }
    
          return res.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            router.push('/login');
          }
    
          return Promise.reject(err);
        });
}