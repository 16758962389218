import { reseller_detailsService } from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
  paymentTypes: [],
  loading: false
};

const actions = {
  update_balance({ dispatch, commit, rootState }, { params }) {
    commit('setLoadingTrue');
    return reseller_detailsService.updateBalance(params)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data == "success") {
            if (rootState.account.isDistributorAgent) {
              dispatch('account/getUserDetails', {}, { root: true });
            }
            dispatch('alert/success', '', {root: true});
          }
        },
        error => {
          commit('setLoadingFalse');
          dispatch('alert/error', error && error.response && error.response.data ? error.response.data : '', {root: true});
        }
      );
  },
  filterSellerPercentage({ dispatch, commit }, { filter }) {
    commit('setLoadingTrue');
    return reseller_detailsService.filterSellerPercentage(filter)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            if (receipt_data.length == 0) {
              dispatch('alert/info', '', {root: true});
            }
            commit('setFilterBusinessData', { receipt_data: receipt_data })
          }
        },
        // error => {}
      );
  },
  getPaymentTypes({ commit }) {
    commit('setLoadingTrue');
    return reseller_detailsService.getPaymentTypes()
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setPaymentTypesData', { receipt_data: receipt_data.data })
          }
        },
        // error => {}
      );
  }
};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setPaymentTypesData(state, { receipt_data }) {
    state.paymentTypes = receipt_data;
  }
};

export const reseller_details = {
  namespaced: true,
  state,
  actions,
  mutations
};