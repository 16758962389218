import Vue from 'vue';
import i18n from '@/common/plugins/vue-i18n';
import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";
import store from "../store";
import { SET_ISLOADING } from "../store/loading.module";

let ApiMainService = axios.create({
  baseURL: appConfig.apiUrl,
  timeout: 60000
});

// request interceptor
ApiMainService.interceptors.request.use(
  config => {    
    store.dispatch(SET_ISLOADING, 'true');
    // config.headers["x-token"] = JwtService.getToken();
    config.headers["Content-Type"] = "application/json";
    // config.headers['Content-Type'] = 'multipart/form-data/';
    config.data = config.data;
    config.withCredentials = 1;
    return config;
  },
  error => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
ApiMainService.interceptors.response.use(
  response => {
    store.dispatch(SET_ISLOADING, 'false');
    if (response.status === 401) {
      router.push('/login');
      return Promise.reject("error");
    } else if (response.status !== 200) {
      const err = (response.data && response.data.message) || response.statusText;
      return Promise.reject(err);
    } else {
      if (response.data == 'Unauthorized') {
        router.push('/login');
        return Promise.reject("error");
      }
      return response.data;
    }
  },
  error => {
    store.dispatch(SET_ISLOADING, 'false');
    Vue.swal({
      icon: 'error',
      title: i18n.t(error.response.data.message?.title) || i18n.t("Error"),
      text: i18n.t(error.response.data.message?.text) || i18n.t("Something went wrong"),
    });
    // return error;
  }
);

export default ApiMainService;
