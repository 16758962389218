export const SET_ISLOADING = "setIsLoading";
export default {
    state: {
        loading: false
    },    
    actions: {
        [SET_ISLOADING]({commit}, payload) {
            if (!payload) {
                return
            }
            let stateValue = (payload == 'true');
            commit('setLoading', stateValue)
        }
    },    
    mutations: {
        setLoading(state, payload) {
            state.loading = payload;
        }
    }
}