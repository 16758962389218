import appConfig from '../appConfig';
import axios from 'axios';
import router from '../router';

export const gameCardPaymentService = {
  gameCardPayment,
  getTransactionData,
  getTransactionReceipt,
  checkTransactionStatus,
  cancelTransaction,
};

function gameCardPayment(supplier_id, payment_data) {
  console.log(payment_data);
  let params = {
    ...payment_data,
    supplier_id: supplier_id ? supplier_id : null,
  };
  const config = {
    method: 'POST',
    url:
      appConfig.apiUrl + '/payment/payment/' + (supplier_id ? supplier_id : ''),
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err.response);
    });
}

function getTransactionData(transaction_id) {
  let params = {
    transaction_id: transaction_id,
  };
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/transaction/data/' + transaction_id,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function getTransactionReceipt(transaction_id, phoneNumber, userType) {
  let params = {
    transaction_id: transaction_id,
    userType: userType,
  };
  if (phoneNumber) {
    params.phoneNumber = phoneNumber;
  }
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/transaction/receipt/' + transaction_id,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function checkTransactionStatus(transaction_id, phoneNumber, userType) {
  let params = {
    transaction_id: transaction_id,
    userType: userType,
  };
  if (phoneNumber) {
    params.phoneNumber = phoneNumber;
  }
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/transaction/status/' + transaction_id,
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}

function cancelTransaction(transaction_id) {
  let params = {
    transaction_id: transaction_id,
  };
  const config = {
    method: 'POST',
    url: appConfig.apiUrl + '/transaction/cancel/' + transaction_id,
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
}
