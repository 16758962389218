import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import Vuetify from "vuetify";
import i18n from './vue-i18n'
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
      iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#29abe2",
      }
    },
  }
});
