import { paymentService } from '../services';
import router from '../router/index';
import i18n from '../common/plugins/vue-i18n';

const state = {
  transactions_receipts: [],
  current_transaction_id: null,
  current_transaction_card_number: null,
  current_transaction_product_id: null,
  current_transaction_supplier_id: null,
  paymentRequestInProgress: false,
  weComNumbers: [],
};
const actions = {
  getTransactionReceipt({ commit }, { transaction_id }) {
    paymentService.getTransactionReceipt(transaction_id).then(
      (receipt_data) => {
        if (
          receipt_data &&
          receipt_data.hasOwnProperty('transId') &&
          receipt_data.transId
        ) {
          commit('setTransactionReceipt', {
            transaction_id: receipt_data.transId,
            receipt_data: receipt_data,
          });
        }
      },
      (error) => {},
    );
  },
  getWeComNumbers({ commit }) {
    commit('setPaymentRequestInProgress', true);
    return new Promise((resolve, reject) => {
      paymentService
        .getWeComNumbers()
        .then((res) => {
          commit('setPaymentRequestInProgress', false);
          commit('setWeComNumbers', res);
          resolve(res);
        })
        .catch((res) => {
          commit('setPaymentRequestInProgress', false);
          reject(res);
        });
    });
  },
  paymentProduct(
    { dispatch, commit },
    {
      supplier_id,
      product_id,
      contract_number,
      phone_number,
      user_type,
      isPorted = false,
      provider_type = '',
    },
  ) {
    commit('setPaymentRequestInProgress', true);
    try {
      return paymentService
        .productPayment(
          supplier_id,
          product_id,
          contract_number,
          phone_number,
          isPorted,
          provider_type,
        )
        .then(
          (result_data) => {
            if (
              supplier_id != 1100 &&
              result_data &&
              result_data.hasOwnProperty('currentTransaction') &&
              result_data.currentTransaction &&
              result_data.hasOwnProperty('success') &&
              result_data.success
            ) {
              commit('setCurrentTransactionId', {
                transaction_id: result_data.currentTransaction,
              });
              commit('setCurrentTransactionCardNumber', {
                card_number: result_data.success,
              });
              commit('setCurrentTransactionProductId', {
                product_id: product_id,
              });
              commit('setCurrentTransactionSupplierId', {
                supplier_id: supplier_id,
              });
              dispatch('account/getUserDetails', {}, { root: true });
              if (user_type == 'enduser') {
                router.push({
                  name: 'EndUserCommunicationPaymentSuccess',
                  params: { id: result_data.currentTransaction },
                });
              } else {
                router.push({
                  name: 'CommunicationPaymentSuccess',
                  params: { id: result_data.currentTransaction },
                });
              }
            }

            commit('setPaymentRequestInProgress', false);
            return result_data;
          },
          (error) => {
            if (
              error &&
              error.hasOwnProperty('response') &&
              error.response &&
              error.response.hasOwnProperty('data') &&
              error.response.data
            ) {
              let data = error.response.data;
              if (data.error) {
                dispatch('alert/error', i18n.t(data.error), { root: true });
              }
            }
            commit('setPaymentRequestInProgress', false);
            return error;
          },
        );
    } catch (e) {
      commit('setPaymentRequestInProgress', false);
    }
  },
};

const mutations = {
  setPaymentRequestInProgress(state, in_progress) {
    state.paymentRequestInProgress = in_progress;
  },
  setTransactionReceipt(state, { transaction_id, receipt_data }) {
    let transactions_receipts = state.transactions_receipts || [];
    transactions_receipts[transaction_id] = receipt_data;
    state.transactions_receipts = null;
    state.transactions_receipts = transactions_receipts;
  },
  setCurrentTransactionId(state, { transaction_id }) {
    state.current_transaction_id = transaction_id;
  },
  setCurrentTransactionCardNumber(state, { card_number }) {
    state.current_transaction_card_number = card_number;
  },
  setCurrentTransactionProductId(state, { product_id }) {
    state.current_transaction_product_id = product_id;
  },
  setCurrentTransactionSupplierId(state, { supplier_id }) {
    state.current_transaction_supplier_id = supplier_id;
  },
  setWeComNumbers(state, receipt_data) {
    state.weComNumbers = receipt_data;
  },
};

export const payment = {
  namespaced: true,
  state,
  actions,
  mutations,
};
