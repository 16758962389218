<template>
    <section v-if="current_alert_type" id="alert-modal" class="modal-default"
             :class="{'modal-default_active': current_alert_type}" @click="closeAlertByBg($event)">
        <div class="modal-default__container"
            :class="{'bg-green5': current_alert_type=='success', 'bg-red2': current_alert_type=='error'}">
            <p class="text color-white weight-700 fs20 my-20">
                {{ current_alert_message }}
            </p>
        </div>
    </section>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    export default {
        name: 'AlertTwo',
        methods: {
            ...mapActions('alertTwo', {
                initCurrentAlert: 'initCurrentAlert',
                clearCurrentAlert: 'clearCurrentAlert'
            }),
            closeAlertByBg(e) {
                if (e.target.closest('.modal-default__container') == null) {
                    this.closeAlert();
                }
            },
            closeAlert() {
                let obj = this
                obj.clearCurrentAlert()
                $('html').css("overflow", "auto")
            },
            showAlert() {
                let obj = this
                if (obj.current_alert_type) {
                    obj.closeAlert()
                }
                if (obj.alerts) {
                    obj.initCurrentAlert()

                    if (obj.current_alert_type) {
                        $('html').css("overflow", "hidden")
                    }
                }
            }
        },
        computed: {
            ...mapState({
                alerts: state => state.alertTwo.alerts,
                current_alert_type: state => state.alertTwo.current_alert_type,
                current_alert_message: state => state.alertTwo.current_alert_message
            }),
        },
        watch: {
            alerts: {
                deep: true,
                handler(newValue) {
                    if (!this.current_alert_type
                        && newValue && newValue.length) {
                        this.showAlert()
                    }
                }
            },
        }
    }
</script>