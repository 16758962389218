import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const destinationService = {
    saveDestination,
    saveAllDestination,
    getDestination,
    getDestinationById,
    addDestination,
    getTags
};

function saveDestination(item) {
    let params = item;
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/tour/destinations/' + item.id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function saveAllDestination(item) {
    let params = {destinations: item};
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/tour/destinations/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getDestination() {
    let params = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/destinations',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getDestinationById(id) {
    let params = {}
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/destinations/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function addDestination(destination_data) {
    let params = destination_data
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/tour/destinations',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getTags() {
    // let params = {
    //     type: 5
    // }
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tag/tag?type=5',
        headers: {
            'Content-Type': 'application/json',
        },
        // params: params,
        // data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}