import { report_detailsService } from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
  reportDetails: [],
  loading: false
};

const actions = {
    getReportDetails({ commit }, { args }) {
        commit('setLoadingTrue');
        return report_detailsService.getReportDetails(args)
        .then(
            receipt_data => {
                commit('setLoadingFalse');
                if (receipt_data) {
                    commit('setReportDetails', { receipt_data: receipt_data })
                }
            },
            error => {
                commit('setLoadingFalse');
            }
        );
    }
};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setReportDetails(state, { receipt_data }) {
    state.reportDetails = receipt_data;
  }
};

export const report_details = {
  namespaced: true,
  state,
  actions,
  mutations
};