import { permissionService } from '../services';

const state = {
  permissions_data: [],
  allowed_paths: [],
  allowed_wildcard_paths: [],
  denied_paths: [],
  denied_wildcard_paths: [],
  allowed_api: [],
  denied_api: [],
  loading: false
};

const actions = {
  getUserPermissionsData({ commit }) {
    commit('setLoadingTrue');
    return permissionService.getUserPermissions()
      .then(
        permissions_data => {
          commit('setLoadingFalse');
          if (permissions_data) {
            localStorage.setItem("permissions", permissions_data);
            commit('setPermissionsData', { permissions_data: permissions_data })
          }
        },
        // error => {}
      );
  },

};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setPermissionsData(state, { permissions_data }) {
    state.permissions_data = JSON.parse(atob(permissions_data));
    if (Array.isArray(state.permissions_data)) {
      for (let permission_data of state.permissions_data) {
        if (!('path' in permission_data) || !('type' in permission_data)) {
          continue;
        }

        if (permission_data.type == 'ui') {
          let accessDenied = true;
          let wildcardPath = false;
          if ('allowEdit' in permission_data
            && (permission_data.allowEdit === true || permission_data.allowEdit === 'true')) {
            accessDenied = false;
          }

          if (permission_data.path.indexOf('*') !== -1) {
            wildcardPath = true;
          }

          if (accessDenied) {
            if (wildcardPath) {
              state.denied_wildcard_paths.push(permission_data.path);
            } else {
              state.denied_paths.push(permission_data.path);
            }
          } else {
            if (wildcardPath) {
              state.allowed_wildcard_paths.push(permission_data.path);
            } else {
              state.allowed_paths.push(permission_data.path);
            }
          }
        } else if (permission_data.type == 'api') {
          let accessDenied = true;
          if ('allowEdit' in permission_data
            && (permission_data.allowEdit === true || permission_data.allowEdit === 'true')) {
            accessDenied = false;
          }

          if (accessDenied) {
            state.denied_api.push(permission_data.path);
          } else {
            state.allowed_api.push(permission_data.path);
          }
        }
      }
    }
  },
  clearPermissionsData(state) {
    state.permissions_data = [];
    state.allowed_paths = [];
    state.allowed_wildcard_paths = [];
    state.denied_paths = [];
    state.denied_wildcard_paths = [];
    state.allowed_api = [];
    state.denied_api = [];
  }
};

const getters = {
  hasPathAccess(state)  {
    return function(path) {
      let accessAllowed = false;

      if (state.denied_paths.includes(path)) {
        accessAllowed = false;
      } else if (state.allowed_paths.includes(path)) {
        accessAllowed = true;
      } else {
        let matchFound = false;
        if (state.denied_wildcard_paths && state.denied_wildcard_paths.length) {
          for (let wildcard_path of state.denied_wildcard_paths) {
            if (!wildcard_path || wildcard_path == '') {
              continue;
            }

            const pathPattern = new RegExp('^' + wildcard_path.replace(/\*/g, '.*') + '$', 'gi');
            if (pathPattern.test(path)) {
              matchFound = true;
              accessAllowed = false;
              break;
            }
          }
        }
        if (!accessAllowed && !matchFound) {
          if (state.allowed_wildcard_paths && state.allowed_wildcard_paths.length) {
            for (let wildcard_path of state.allowed_wildcard_paths) {
              if (!wildcard_path || wildcard_path == '') {
                continue;
              }

              const pathPattern = new RegExp('^' + wildcard_path.replace(/\*/g, '.*') + '$', 'gi');
              if (pathPattern.test(path)) {
                accessAllowed = true;
                break;
              }
            }
          }
        }
      }

      return accessAllowed;
    }
  },
  hasApiAccess(state)  {
    return function(path, method = 'GET') {
      let accessAllowed = false;

      method = method.toUpperCase();
      path = path.toLowerCase();
      if (state.allowed_api.includes(`${method} ${path}`)) {
        accessAllowed = true;
      }

      return accessAllowed;
    }
  },
};

export const permission = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};