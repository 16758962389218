import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const sellerTourismPercentageService = {
    updateResellerSetting,
    getDistributes,
    getBusiness,
    getResellerSetting,
    filterSellerPercentage,
    getTags,
    getBusinessProfile,
    updateBusinessData,
    deleteBusiness,
    freezeBusiness,
    lockBusiness,
    activateBusiness,
    newRavkavStation,
    findLatLon,
    getSuppliers,
    updateSuppliers,
    getBusinessProduct,
    getBusinessCommission,
    getUserList,
    getRoles,
    getActivitySession,
    getAgentsResponsible,
    deleteActivitySessionIp,
    deleteActivitySessionToken,
    updateProductProfit,
    updateProductPoint,
    updateDistributionFee,
    updateBusinessProduct,
    updateCommissionProfit,
    updateAdditionalPrice,
    activateUser,
    freezeUser,
    updateUserPassword,
    updateUserData,
    cancelActivitySessionIp,
    cancelActivitySessionToken,
    activateSessionIp,
    activateSessionToken,
    updateProfileFlags
};

function updateResellerSetting(id, settings) {
    let params = settings;
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/tour/reseller_setting/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getDistributes() {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/distributor/distributor',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getBusiness(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/business/business_of_distributor/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getResellerSetting(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/reseller_setting/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function filterSellerPercentage(filter) {
    let params = filter;
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/business/business',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getTags() {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/business/tags',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getBusinessProfile() {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/business/profiles',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateBusinessData(id, data) {
    let params = data;
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/business/business/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function deleteBusiness(id) {
    const config = {
        method: 'DELETE',
        url: appConfig.apiUrl + '/business/business/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function freezeBusiness(id) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/business/freeze/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function lockBusiness(id) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/business/lock/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function activateBusiness(id) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/business/activate/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function newRavkavStation(city, address, activity_hours, comment, lat, lon, remote_id) {
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/ravkav/create_ravkav_station',
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            city: city,
            address: address,
            activity_hours: activity_hours,
            comment: comment, 
            lat: lat,
            lon: lon, 
            remote_id: remote_id
        },
        //data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function findLatLon(fullAddress) {
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/ravkav/find_lat_lon',
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            address: fullAddress,
        },
        //data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }
            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getSuppliers(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/admin/business_supplier/business_authorization/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function updateSuppliers(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/business/business_supplier/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getBusinessProduct(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/admin/business_product/business_authorization/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getBusinessCommission(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/admin/business_product/commission_business_authorization/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getUserList(params) {
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/admin/user',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getRoles() {
    const params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/roles',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function getActivitySession(id) {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/activity_session/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getAgentsResponsible() {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/business/agent/',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function deleteActivitySessionIp(id) {
    let params = {};
    const config = {
        method: 'DELETE',
        url: appConfig.apiUrl + '/activity_session/ip/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function deleteActivitySessionToken(id) {
    let params = {};
    const config = {
        method: 'DELETE',
        url: appConfig.apiUrl + '/activity_session/token/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}

function updateProductProfit(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/admin/business_product/percentage_profit/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateProductPoint(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/admin/business_product/points/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateDistributionFee(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/admin/business/use_distribution_fee/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateProfileFlags(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/profit/profile/flags',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateBusinessProduct(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/admin/business_product/multi',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateCommissionProfit(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/admin/business_product/commission',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateAdditionalPrice(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/admin/business_product/final_commission',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function activateUser(id) {
    const params = {};
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/user/activate/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function freezeUser(id) {
    const params = {};
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/user/frozen/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateUserPassword(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/user/password/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateUserData(id, params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/user/' + id,
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function cancelActivitySessionIp(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/activity_session/ip/cancel',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function cancelActivitySessionToken(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/activity_session/token/cancel',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function activateSessionIp(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/activity_session/ip',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function activateSessionToken(params) {
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/activity_session/token',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}