import update from 'immutability-helper';
import i18n from '../common/plugins/vue-i18n';

import { cartService } from '../services';
import router from '../router';

/**
 * {
 * comA: {
 *  1111: {
 *    ...,
 *    count: 5
 *  },
 *  5555: {
 *    ...,
 *    count: 2
 *  },
 * }
 */

const KEY = '__CART_SYSTEM__';

// const countCart = (cartObject) => {
//   let count = 0;

//     Object.values(cartObject).forEach((cart) => {
//       Object.values(cart).forEach((product) => {
//         count += product.count || 0;
//       });
//     });

//   return count;
// };
const countCart = (cartObject) => {
  let count = 0;

  if (cartObject) {
    Object.values(cartObject).forEach((product) => {
      count += product.quantity || 0;
    });
  }

  return count;
};

const state = {
  cart: {},
  counter: 0,
  productsCounter: 0,
  packageCounter: 0,

  shanivOrderData: null, // selected order data
  shanivPaymentDetails: null, // selected order payment details
  newOrder: {},
  createOrderLoading: false,
  createOrderError: null,
  shanivOrder: {}, // in fact this is a list of orders...
  tempOrder: [],
  shanivOrderStatuses: [],
  getShanivOrderStatusesLoading: false
};

const actions = {
  addProductToCart({ commit }, product) {
    commit('initializeCart');
    return commit('addProductToCart', product);
  },
  deleteProductToCart({ commit }, product) {
    commit('initializeCart');
    return commit('deleteProductToCart', product);
  },
  addProductsToCart({ commit }, obj) {
    commit('initializeCart');
    return commit('addProductsToCart', obj);
  },
  deleteProductToCartByQuantity({ commit }, obj) {
    commit('initializeCart');
    return commit('deleteProductToCartByQuantity', obj);
  },
  initCart({ commit }) {
    commit('initializeCart');
  },
  changeCount({ commit }, { type, inc }) {
    commit('setCount', { type: type, inc: inc });
  },
  async createOrder({ commit, state, rootState, dispatch, getters, rootGetters }) {
    const minOrderAmount = rootGetters['shaniv_customer/minOrderAmount'] || 500; // min by company

    let minOrderError = false;
    for (const company in state.cart) {
      if (getters.cartTotalDiscountVATPriceByCompany(company) < minOrderAmount) {
        dispatch('alert/error', i18n.t('Minimum amount per order is {num} ({company})', {num: minOrderAmount, company: company}), { root: true });
        minOrderError = true;
      }
    }
    if (minOrderError) {
      return false;
    }


    let isCartOutOfStock = getters.isCartOutOfStock;
    if (isCartOutOfStock) {
        dispatch('alert/error', i18n.t("Please note that some products are out of stock!"), { root: true });
        return false;
    }

    await actions.fetchActualCartData({commit, rootState, getters, dispatch});

    isCartOutOfStock = getters.isCartOutOfStock;
    if (isCartOutOfStock) {
        dispatch('alert/error', i18n.t("Please note that some products are out of stock!"), { root: true });
      return false;
    }

    const postOrderData = getters.createPostOrderData;

    commit('setCreateOrderLoading', { isLoading: true });  
    commit('setCreateOrderError', { error: null });
    return cartService.createOrder(postOrderData).then(
      (newOrderRes) => {
        let isCorrectRes = newOrderRes.orderData && newOrderRes.orderData.id;
        let error_order_companies = [];

        if ("errors" in newOrderRes && newOrderRes.errors) {
          let someProductsNotOrderedError = false;
          let someProductsOutOfStockError = false;
          for (let orderCompany in newOrderRes.errors) {
            for (let propertyName in newOrderRes.errors[orderCompany]) {
              switch (propertyName) {
                case 'out_of_stock':
                  someProductsOutOfStockError = true;
                  break;
                case 'customer_not_found':
                  dispatch('alert/error', i18n.t("The order cannot be placed - you are not a customer of {company}. Please contact the office for more details - * 5409", {company: orderCompany}), { root: true });
                  break;
                case 'min_order_amount_error':
                  dispatch('alert/error', i18n.t('Minimum amount per order is {num} ({company})', {num: minOrderAmount, company: orderCompany}), { root: true });
                  break;
                case 'customer_info_is_missing':
                  break;
                case 'inactive_customer':
                  break;
                case 'insufficient_funds':
                  break;
                case 'not_created':
                  break;
                case 'failed_items':
                  break;
                default:
                  break;
              }

              console.log(`${orderCompany} => ${propertyName}: `, newOrderRes.errors[orderCompany][propertyName]);
              someProductsNotOrderedError = true;
              if (!error_order_companies.includes(orderCompany)) {
                error_order_companies.push(orderCompany);
              }
            }
          }
          if (someProductsOutOfStockError) {
              dispatch('alert/error', i18n.t("Please note that some products are out of stock!"), { root: true });
              // todo: reload cart data from backend
          } else if (someProductsNotOrderedError) {
              dispatch('alert/info', i18n.t("Please note that some products have not been ordered!"), { root: true });
          }
        }
        if (isCorrectRes) {
          let orderId = newOrderRes.orderData.id;
          commit('setNewOrder', { newOrder: newOrderRes });
          commit('setShanivOrderData', { data: newOrderRes.orderData });
          commit('clearShanivCart', {exclude_companies: error_order_companies});
          dispatch('alert/success', i18n.t('Operation Success'), {root: true});
          setTimeout(() => router.push(`/cart/order/${orderId}/success`), 100);
        }
        commit('setCreateOrderLoading', { isLoading: false });
      },
      (error) => {
        commit('setCreateOrderLoading', { isLoading: false });
        commit('setCreateOrderError', { error: 'an error occurred' });
        dispatch('alert/error', error, { root: true });
      },
    );
  },
  saveTempOrder({ commit, dispatch, getters }) {
    commit('setCreateOrderLoading', { isLoading: true });
    const postOrderData = getters.createPostOrderData;
    return cartService.saveTempOrder(postOrderData)
      .then(
        receipt_data => {
            commit('setCreateOrderLoading', { isLoading: false });
            return receipt_data;
        },
        error => {
            commit('setCreateOrderLoading', { isLoading: false });
            dispatch('alert/error', error, { root: true });
        }
      );
  },
  removeTempOrder({ commit, dispatch }, {id}) {
    commit('setCreateOrderLoading', { isLoading: true });
    return cartService.removeTempOrder(id)
      .then(
        receipt_data => {
            commit('setCreateOrderLoading', { isLoading: false });
            dispatch('alert/success', i18n.t('Operation Success'), {root: true});
            return receipt_data;
        },
        error => {
            commit('setCreateOrderLoading', { isLoading: false });
            dispatch('alert/error', error, { root: true });
        }
      );
  },
  getShanivOrder({ commit }, {filters} = {}) { // in fact this is get shaniv orderS action
    commit('setCreateOrderLoading', { isLoading: true });
    return cartService.getShanivOrder(filters)
      .then(
        receipt_data => {
            commit('setCreateOrderLoading', { isLoading: false });
            commit('setShanivOrder', { receipt_data: receipt_data });
        },
        error => {
            commit('setCreateOrderLoading', { isLoading: false });
        }
      );
  },
  getShanivOrderData({ commit }, {id} = {}) {
    commit('setCreateOrderLoading', { isLoading: true });
    return cartService.getShanivOrderData(id)
      .then(
        response_data => {
            commit('setCreateOrderLoading', { isLoading: false });
            commit('setShanivOrderData', { data: response_data });

            return response_data;
        },
        error => {
            commit('setCreateOrderLoading', { isLoading: false });

            return error;
        }
      );
  },
  getPaymentDetails({ commit, dispatch }, {id} = {}) {
    commit('setCreateOrderLoading', { isLoading: true });
    return cartService.getPaymentDetails(id)
      .then(
        response_data => {
          if (response_data && response_data.orderData && response_data.paymentSummary) {
            commit('setShanivPaymentDetails', { data: response_data });
          } else {
            setTimeout(() => router.push(`/cart/order/${id}/success`), 100);
          }
          commit('setCreateOrderLoading', { isLoading: false });

          return response_data;
        },
        error => {
          commit('setCreateOrderLoading', { isLoading: false });
          dispatch('alert/error', error, { root: true });
          setTimeout(() => router.push(`/cart/order/${id}/success`), 100);

          return error;
        }
      );
  },
  payOrder({ commit, dispatch }, {id, data} = {}) {
    commit('setCreateOrderLoading', { isLoading: true });
    return cartService.payOrder(id, data)
      .then(
        response_data => {
          if (response_data.success) {
            // success payment!
            // response format: {
            //   status: number,
            //   success: true/false,
            //   message: 'string',
            //   data: null or {order_id, doc_url, copy_doc_url, confirmation_code}
            // }
            dispatch('alert/success', `Success payment! Confirmation number: ${response_data.data.confirmation_code}`, {root: true});
            setTimeout(() => router.push(`/cart/order/${id}/success`), 100);
          } else {
            dispatch('alert/error', `Payment failed! Error - ${response_data.message}`, { root: true });
          }

          commit('setCreateOrderLoading', { isLoading: false });

          return response_data;
        },
        error => {
          commit('setCreateOrderLoading', { isLoading: false });
          dispatch('alert/error', error, { root: true });

          return error;
        }
      );
  },
  getShanivOrderStatuses({ commit }) {
    commit('setShanivOrderStatusesLoading', { isLoading: true });
    return cartService.getShanivOrderStatuses()
      .then(
        result_data => {
            commit('setShanivOrderStatusesLoading', { isLoading: false });
            commit('setShanivOrderStatuses', { data: result_data });
        },
        error => {
            commit('setShanivOrderStatusesLoading', { isLoading: false });
        }
      );
  },
  getTempOrder({ commit }) {
    commit('setCreateOrderLoading', { isLoading: true });
    return cartService.getTempOrder()
      .then(
        receipt_data => {
            commit('setCreateOrderLoading', { isLoading: false });
            commit('setTempOrder', { receipt_data: receipt_data });
        },
        error => {
            commit('setCreateOrderLoading', { isLoading: false });
        }
      );
  },
  async updateCart({ commit, rootState }) {
    commit('setCreateOrderLoading', { isLoading: true });

    let cart = JSON.parse(JSON.stringify(state.cart));
    commit('clearShanivCart');

    const allPackages = rootState.shaniv_management.package_list;
    const allProducts = rootState.productShaniv.products;

    for (const company in cart) {
      if ('products' in cart[company] && cart[company].products) {
        for (const key in cart[company].products) {
          const cartItem = cart[company].products[key];
          const actualItemData = allProducts.find(p => p.id == cartItem?.id);

          if (!actualItemData) {
            delete cart[company].products[key];
          }
        }
      }

      if ('packages' in cart[company]) {
        for (const key in cart[company].packages) {
          const cartItem = cart[company].packages[key];
          const actualItemData = allPackages.find(p => p.id == cartItem?.id);

          if (!actualItemData) {
            delete cart[company].packages[key];
          }
        }
      }
    }

    localStorage.setItem(KEY, JSON.stringify(cart));
    state.cart = JSON.parse(JSON.stringify(cart));
    commit('setCreateOrderLoading', { isLoading: false });
  },
  async fetchActualCartData({ commit, rootState, getters, dispatch }) {
    commit('setCreateOrderLoading', { isLoading: true });

    let cartProducts = Object.values(getters.products);
    let cartPackages = Object.values(getters.packages);

    const promises = [];

    if (cartProducts && cartProducts.length > 0) {
      let productsIds = cartProducts.map(cp => cp.id);
      promises.push(dispatch('productShaniv/getProducts', {
        active: 1,
        user_prices: 1,
        resync_balance: 1,
        include_out_of_stock: 1,
        id: productsIds,
      }, { root: true }));
    }

    if (cartPackages && cartPackages.length > 0) {
      let packagesIds = cartPackages.map(cp => cp.id);
      promises.push(dispatch('shaniv_management/getPackageList', {
        active: 1,
        user_prices: 1,
        resync_balance: 1,
        include_out_of_stock: 1,
        id: packagesIds,
      }, { root: true }));
    }

    for (const key in promises) {
      promises[key] = await promises[key];
    }

    commit('setCreateOrderLoading', { isLoading: false });
  },
  clearCart({ commit }) {
    commit('clearShanivCart');
    commit('initializeCart');
  },
};

const mutations = {
  initializeCart(state) {
    // const cart = JSON.parse(JSON.stringify(state.cart));

    const newCart = JSON.parse(localStorage.getItem(KEY)) || {};

    // state.counter = countCart(newCart);
    if (newCart.shaniv) {
      state.productsCounter = countCart(newCart.shaniv.products);
      state.packageCounter = countCart(newCart.shaniv.packages);
    }

    state.cart = JSON.parse(JSON.stringify(newCart));
  },
  addProductsToCart(state, { product, quantity, company, type }) {
    let newCart = JSON.parse(localStorage.getItem(KEY)) || {};
    if (!newCart[company]) {
      newCart[company] = {};
    }
    // const cart = JSON.parse(JSON.stringify(state.cart)) || {};
    if (type == 'products') {
      newCart = update(newCart, {
        [company]: {
          products: {
            $apply: (_company = {}) => {
              const _product = _company[product.id] || JSON.parse(JSON.stringify(product));
              _product.quantity = (_product.quantity || 0) + quantity;
              _company[product.id] = _product;
              return JSON.parse(JSON.stringify(_company));
            },
          },
        },
      });
      // state.productsCounter = countCart(newCart.shaniv.products);
    } else {
      newCart = update(newCart, {
        [company]: {
          packages: {
            $apply: (_company = {}) => {
              const _product = _company[product.id] || JSON.parse(JSON.stringify(product));
              _product.quantity = (_product.quantity || 0) + quantity;
              _company[product.id] = _product;
              return JSON.parse(JSON.stringify(_company));
            },
          },
        },
      });
      // state.packageCounter = countCart(newCart.shaniv.packages);
    }

    localStorage.setItem(KEY, JSON.stringify(newCart));

    // state.counter = countCart(newCart);

    state.cart = JSON.parse(JSON.stringify(newCart));
  },
  deleteProductToCartByQuantity(state, { product, quantity, company, type }) {
    const cart = JSON.parse(localStorage.getItem(KEY)) || {};

    if (cart[company]
      && cart[company][type]
      && cart[company][type][product.id]) {

      cart[company][type][product.id].quantity -= quantity;
      if (cart[company][type][product.id].quantity <= 0) {
          delete cart[company][type][product.id];
      }

      if (Object.keys(cart[company][type]).length === 0) {
          delete cart[company][type];
      }

      if (Object.keys(cart[company]).length === 0) {
          delete cart[company];
      }

      localStorage.setItem(KEY, JSON.stringify(cart));
      state.cart = JSON.parse(JSON.stringify(cart));
    }
  },
  deleteProductToCart(state, { company, type, id }) {
    const cart = JSON.parse(localStorage.getItem(KEY)) || {};
    if (cart[company] && cart[company][type][id]) {
      delete cart[company][type][id];

      // if (Object.values(cart[company]['products'] || {}).length < 1 && Object.values(cart[company]['packages'] || {}).length < 1) {
      //   delete cart[company];
      // }
      // debugger

      if (type == 'products') {
        state.productsCounter = countCart(cart[company][type]);
      } else {
        state.packageCounter = countCart(cart[company][type]);
      }

      if (Object.keys(cart[company][type]).length === 0) {
        delete cart[company][type];
      }

      if (Object.keys(cart[company]).length === 0) {
        delete cart[company];
      }


      localStorage.setItem(KEY, JSON.stringify(cart));
      // state.counter = countCart(cart);

      state.cart = JSON.parse(JSON.stringify(cart));
    }
  },
  setCount(state, { type, inc }) {
    if (type == 'products') {
      if (inc == true) {
        state.productsCounter += 1;
      } else {
        state.productsCounter -= 1;
      }
    } else {
      if (inc == true) {
        state.packageCounter += 1;
      } else {
        state.packageCounter -= 1;
      }
    }
  },

  setNewOrder(state, { newOrder }) {
    state.newOrder = newOrder;
  },
  setShanivOrder(state, { receipt_data }) {
    state.shanivOrder = receipt_data;
  },
  setShanivOrderData(state, { data }) {
    state.shanivOrderData = null;
    state.shanivOrderData = data;
  },
  setShanivPaymentDetails(state, { data }) {
    state.shanivPaymentDetails = null;
    state.shanivPaymentDetails = data;
  },
  setShanivOrderStatuses(state, { data }) {
    state.shanivOrderStatuses = data;
  },
  setTempOrder(state, { receipt_data }) {
    state.tempOrder = receipt_data;
  },
  setCreateOrderLoading(state, { isLoading }) {
    state.createOrderLoading = isLoading;
  },
  setShanivOrderStatusesLoading(state, { isLoading }) {
    state.getShanivOrderStatusesLoading = isLoading;
  },
  setCreateOrderError(state, { error }) {
    state.createOrderError = error;
  },
  clearShanivCart(state, {exclude_companies} = {}) {
    if (!exclude_companies || exclude_companies.length === 0) {
        state.cart = {};
        state.productsCounter = 0;
        state.packageCounter = 0;

        localStorage.removeItem(KEY);
    } else {
      for (let company in state.cart) {
        if (!company) {
          continue;
        }

        if (exclude_companies
            && Array.isArray(exclude_companies)
            && !exclude_companies.includes(company)) {
            delete state.cart[company];
        }
      }


      state.cart = Object.assign({}, state.cart);
      localStorage.setItem(KEY, JSON.stringify(state.cart));
    }
  },
};

const getters = {
  cart: (state, getters, rootState, rootGetters) => {
      let cart = JSON.parse(JSON.stringify(state.cart));

      const allPackages = rootGetters['shaniv_management/package_list'];
      const allProducts = rootGetters['productShaniv/products'];
      const inStockItems = getters.inStockItems;
      const outOfStockItems = getters.outOfStockItems;

      for (const company in cart) {
          const inStockProducts = company in inStockItems && 'products' in inStockItems[company]
              ? Object.values(inStockItems[company].products) : [];
          const inStockPackages = company in inStockItems && 'packages' in inStockItems[company]
              ? Object.values(inStockItems[company].packages) : [];
          const outOfStockProducts = company in outOfStockItems && 'products' in outOfStockItems[company]
              ? Object.values(outOfStockItems[company].products) : [];
          const outOfStockPackages = company in outOfStockItems && 'packages' in outOfStockItems[company]
              ? Object.values(outOfStockItems[company].packages) : [];
          if ('products' in cart[company] && cart[company].products) {
              for (const key in cart[company].products) {
                  const cartItem = cart[company].products[key];
                  const actualItemData = allProducts.find(p => p.id == cartItem?.id);
                  const inStockItem = inStockProducts.find(p => p.id == cartItem?.id);
                  const outOfStockItem = outOfStockProducts.find(p => p.id == cartItem?.id);
                  cart[company].products[key].in_stock_quantity = inStockItem?.quantity || 0;
                  cart[company].products[key].out_of_stock_quantity = outOfStockItem?.quantity || 0;

                  if (actualItemData) {
                      cart[company].products[key] = {...cart[company].products[key], ...actualItemData};
                  }
              }
          }

          if ('packages' in cart[company]) {
              for (const key in cart[company].packages) {
                  const cartItem = cart[company].packages[key];
                  const actualItemData = allPackages.find(p => p.id == cartItem?.id);
                  const inStockItem = inStockPackages.find(p => p.id == cartItem?.id);
                  const outOfStockItem = outOfStockPackages.find(p => p.id == cartItem?.id);
                  cart[company].packages[key].in_stock_quantity = inStockItem?.quantity || 0;
                  cart[company].packages[key].out_of_stock_quantity = outOfStockItem?.quantity || 0;

                  if (actualItemData) {
                      cart[company].packages[key] = {...cart[company].packages[key], ...actualItemData};
                  }
              }
          }
      }

      return cart;
  },
  counter: (state) => state.counter,

  products: (state) => {
    if (!state.cart) {
      return {};
    }

    let products = {};

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      products = {...products, ...state.cart[company].products}
    }

    return products;
  },
  packages: (state) => {
    if (!state.cart) {
      return {};
    }

    let packages = {};

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      packages = {...packages, ...state.cart[company].packages}
    }

    return packages;
  },

  cartItemsStockInfo: (state, getters, rootState, rootGetters) => {
      const stockInfo = {
          'in_stock': {},
          'out_of_stock': {},
      };
      if (!state.cart) {
          return stockInfo;
      }

      const cartPackages = Object.values(getters.packages);
      const cartProducts = Object.values(getters.products);

      const allPackages = rootGetters['shaniv_management/package_list'];
      const allProducts = rootGetters['productShaniv/products'];
      const products = {};

      for (const pack of cartPackages) {
          if (!pack.id) {
              continue;
          }
          const company = pack.company;
          if (!(company in stockInfo['in_stock'])) {
              stockInfo['in_stock'][company] = {
                  products: {},
                  packages: {},
              }
          }
          if (!(company in stockInfo['out_of_stock'])) {
              stockInfo['out_of_stock'][company] = {
                  products: {},
                  packages: {},
              }
          }
          let packageData = allPackages.find(ap => ap.id == pack.id) || null;
          if (!packageData) {
              stockInfo['out_of_stock'][company].packages[pack.id] = {...pack};
              continue;
          }

          let minProductsAmountInStock = pack.quantity;
          if (Array.isArray(packageData.products)) {
              for (let packProduct of packageData.products) {
                  if (!packProduct.product_id) {
                      minProductsAmountInStock = 0;
                      stockInfo['out_of_stock'][company].packages[pack.id] = {...pack};
                      break;
                  }
                  let packProductData = 'ShanivProduct' in packProduct
                      ? packProduct.ShanivProduct : null;
                  if (!packProductData) {
                      minProductsAmountInStock = 0;
                      stockInfo['out_of_stock'][company].packages[pack.id] = {...pack};
                      break;
                  }
                  if (!(packProductData.id in products)) {
                      products[packProductData.id] = JSON.parse(JSON.stringify(packProductData));
                  }
                  let productBalance = products[packProductData.id].BALANCE;
                  if (!productBalance) {
                      minProductsAmountInStock = 0;
                      stockInfo['out_of_stock'][company].packages[pack.id] = {...pack};
                      break;
                  }

                  let productQtySteps = packProductData.qtySteps && packProductData.qtySteps > 0
                      ? packProductData.qtySteps : 1;

                  for (let pack_quant = pack.quantity; pack_quant >= 0; pack_quant--) {
                      let productTotalCount = pack_quant * packProduct.quantity * productQtySteps;

                      if (productTotalCount <= productBalance) {
                          minProductsAmountInStock = minProductsAmountInStock > pack_quant
                              ? pack_quant : minProductsAmountInStock;
                          break;
                      }
                  }
              }
          }

          if (minProductsAmountInStock > 0) {
              stockInfo['in_stock'][company].packages[pack.id] = {...pack, quantity: minProductsAmountInStock};

              if (Array.isArray(packageData.products)) {
                  for (let packProduct of packageData.products) {
                      let packProductData = 'ShanivProduct' in packProduct
                          ? packProduct.ShanivProduct : null;
                      let productQtySteps = packProductData.qtySteps && packProductData.qtySteps > 0
                          ? packProductData.qtySteps : 1;
                      let productTotalCount = minProductsAmountInStock * packProduct.quantity * productQtySteps;
                      if (products[packProductData.id].BALANCE) {
                          products[packProductData.id].BALANCE -= productTotalCount;
                      }
                  }
              }
          }
          if (minProductsAmountInStock < pack.quantity) {
              stockInfo['out_of_stock'][company].packages[pack.id] = {...pack, quantity: (pack.quantity - minProductsAmountInStock)};
          }
      }

      for (const product of cartProducts) {
          if (!product.id) {
              continue;
          }
          const company = product.company;
          if (!(company in stockInfo['in_stock'])) {
              stockInfo['in_stock'][company] = {
                  products: {},
                  packages: {},
              }
          }
          if (!(company in stockInfo['out_of_stock'])) {
              stockInfo['out_of_stock'][company] = {
                  products: {},
                  packages: {},
              }
          }
          let productData = allProducts.find(ap => ap.id == product.id) || null;
          if (!productData) {
              stockInfo['out_of_stock'][company].products[product.id] = {...product};
              continue;
          }

          if (!(productData.id in products)) {
              products[product.id] = JSON.parse(JSON.stringify(productData));
          }
          let productBalance = products[product.id].BALANCE;
          if (!productBalance) {
              stockInfo['out_of_stock'][company].products[product.id] = {...product};
              continue;
          }

          let productQtySteps = productData.qtySteps && productData.qtySteps > 0
              ? productData.qtySteps : 1;

          let productInStockCount = product.quantity;
          for (let prod_quant = product.quantity; prod_quant >= 0; prod_quant--) {
              let productTotalCount = prod_quant * productQtySteps;

              if (productTotalCount <= productBalance) {
                  productInStockCount = prod_quant;
                  break;
              }
          }

          if (productInStockCount > 0) {
              stockInfo['in_stock'][company].products[product.id] = {...product, quantity: productInStockCount};

              products[product.id].BALANCE -= productInStockCount * productQtySteps;
          }
          if (productInStockCount < product.quantity) {
              stockInfo['out_of_stock'][company].products[product.id] = {...product, quantity: (product.quantity - productInStockCount)};
          }
      }

      return stockInfo;
  },

  inStockItems: (state, getters) => {
    if (!state.cart) {
      return [];
    }
    let inStockItems = {};
    let cartItemsStockInfo = getters.cartItemsStockInfo;

    if (cartItemsStockInfo
        && cartItemsStockInfo['in_stock']) {
        inStockItems = cartItemsStockInfo['in_stock'];
    }

    return inStockItems;
  },
  outOfStockItems: (state, getters) => {
      if (!state.cart) {
          return [];
      }
      let outOfStockItems = [];
      let cartItemsStockInfo = getters.cartItemsStockInfo;

      if (cartItemsStockInfo
          && cartItemsStockInfo['out_of_stock']) {
          outOfStockItems = cartItemsStockInfo['out_of_stock'];
      }

      return outOfStockItems;
  },

  isCartOutOfStock: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let isCartOutOfStock = false;

    let outOfStockItems = getters.outOfStockItems || {};

    if (Object.values(outOfStockItems).length > 0) {
        for (const company in outOfStockItems) {
          if (outOfStockItems[company].packages && Object.values(outOfStockItems[company].packages).length > 0
            || outOfStockItems[company].products && Object.values(outOfStockItems[company].products).length > 0) {
            isCartOutOfStock = true;
            break;
          }
        }
    }

    return isCartOutOfStock;
  },

  productsTotalQuantity: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalQuantity = 0;

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      for (const product_id in state.cart[company].products) {
        const item = state.cart[company].products[product_id];
        totalQuantity += item.quantity;
      }
    }

    return totalQuantity;
  },
  productsTotalCount: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalCount = 0;

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      for (const product_id in state.cart[company].products) {
        const item = state.cart[company].products[product_id];
        totalCount += item.quantity * (item.qtySteps && item.qtySteps > 0 ? item.qtySteps : 1);
      }
    }

    return totalCount;
  },
  productsTotalPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      for (const product_id in state.cart[company].products) {
        const item = state.cart[company].products[product_id];
        totalPrice += item.quantity * (item.qtySteps && item.qtySteps > 0 ? item.qtySteps : 1) * (item.PRICE >= item.DISCOUNT_PRICE ? item.PRICE : item.DISCOUNT_PRICE);
      }
    }

    totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

    return totalPrice;
  },
  productsTotalVATPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalVATPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      for (const product_id in state.cart[company].products) {
        const item = state.cart[company].products[product_id];
        totalVATPrice += item.quantity * (item.qtySteps && item.qtySteps > 0 ? item.qtySteps : 1) * (item.VATPRICE >= item.DISCOUNT_VATPRICE ? item.VATPRICE : item.DISCOUNT_VATPRICE);
      }
    }

    totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

    return totalVATPrice;
  },
  productsTotalDiscountPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      for (const product_id in state.cart[company].products) {
        const item = state.cart[company].products[product_id];
        totalPrice += item.quantity * (item.qtySteps && item.qtySteps > 0 ? item.qtySteps : 1) * item.DISCOUNT_PRICE;
      }
    }

    totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

    return totalPrice;
  },
  productsTotalDiscountVATPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalVATPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      for (const product_id in state.cart[company].products) {
        const item = state.cart[company].products[product_id];
        totalVATPrice += item.quantity * (item.qtySteps && item.qtySteps > 0 ? item.qtySteps : 1) * item.DISCOUNT_VATPRICE;
      }
    }

    totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

    return totalVATPrice;
  },
  productsTotalDiscount: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalDiscount = getters.productsTotalVATPrice - getters.productsTotalDiscountVATPrice;
    totalDiscount = totalDiscount > 0 ? Math.round((totalDiscount + Number.EPSILON) * 100) / 100 : 0;

    return totalDiscount;
  },
  productsUniqueCount: (state) => {
    if (!state.cart) {
      return 0;
    }

    let productIds = [];

    for (const company in state.cart) {
      if (!state.cart[company].products) {
        continue;
      }
      for (const product_id in state.cart[company].products) {
        const item = state.cart[company].products[product_id];
        if (!productIds.includes(item.id)) {
          productIds.push(item.id);
        }
      }
    }
    let uniqueCount = productIds.length;

    return uniqueCount;
  },

  packagesTotalQuantity: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalQuantity = 0;

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        totalQuantity += item.quantity;
      }
    }

    return totalQuantity;
  },
  packagesProductsTotalQuantity: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalQuantity = 0;

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        if (!('products' in item) || !Array.isArray(item.products) || !item.products.length) {
          continue;
        }
        for (const product_key in item.products) {
          totalQuantity += (item.quantity || 0) * (item.products[product_key].quantity || 0);
        }
      }
    }

    return totalQuantity;
  },
  packagesTotalCount: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalCount = 0;

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        totalCount += item.quantity * 1;
      }
    }

    return totalCount;
  },
  packagesTotalPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        totalPrice += item.quantity * (item.PRICE >= item.DISCOUNT_PRICE ? item.PRICE : item.DISCOUNT_PRICE);
      }
    }

    totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

    return totalPrice;
  },
  packagesTotalVATPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalVATPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        totalVATPrice += item.quantity * (item.VATPRICE >= item.DISCOUNT_VATPRICE ? item.VATPRICE : item.DISCOUNT_VATPRICE);
      }
    }

    totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

    return totalVATPrice;
  },
  packagesTotalDiscountPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        totalPrice += item.quantity * item.DISCOUNT_PRICE;
      }
    }

    totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

    return totalPrice;
  },
  packagesTotalDiscountVATPrice: (state) => {
    if (!state.cart) {
      return 0;
    }

    let totalVATPrice = 0;

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        totalVATPrice += item.quantity * item.DISCOUNT_VATPRICE;
      }
    }

    totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

    return totalVATPrice;
  },
  packagesTotalDiscount: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalDiscount = getters.packagesTotalVATPrice - getters.packagesTotalDiscountVATPrice;
    totalDiscount = totalDiscount > 0 ? Math.round((totalDiscount + Number.EPSILON) * 100) / 100 : 0;

    return totalDiscount;
  },
  packagesUniqueCount: (state) => {
    if (!state.cart) {
      return 0;
    }

    let packagesIds = [];

    for (const company in state.cart) {
      if (!state.cart[company].packages) {
        continue;
      }
      for (const package_id in state.cart[company].packages) {
        const item = state.cart[company].packages[package_id];
        if (!packagesIds.includes(item.id)) {
          packagesIds.push(item.id);
        }
      }
    }
    let uniqueCount = packagesIds.length;

    return uniqueCount;
  },

  cartTotalQuantity: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalQuantity = 0;

    totalQuantity += getters.productsTotalQuantity + getters.packagesProductsTotalQuantity;

    return totalQuantity;
  },
  cartTotalCount: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalCount = 0;

    totalCount += getters.productsTotalCount + getters.packagesTotalCount;

    return totalCount;
  },
  cartTotalPrice: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalPrice = 0;

    totalPrice += getters.productsTotalPrice + getters.packagesTotalPrice;
    totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

    return totalPrice;
  },
  cartTotalVATPrice: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalVATPrice = 0;

    // totalVATPrice += getters.productsTotalVATPrice + getters.packagesTotalVATPrice;
    // we don't use default products prices now...
    totalVATPrice += getters.productsTotalDiscountVATPrice + getters.packagesTotalVATPrice;
    totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

    return totalVATPrice;
  },
  cartTotalDiscountPrice: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalPrice = 0;

    totalPrice += getters.productsTotalDiscountPrice + getters.packagesTotalDiscountPrice;
    totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

    return totalPrice;
  },
  cartTotalDiscountVATPrice: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let totalVATPrice = 0;

    totalVATPrice += getters.productsTotalDiscountVATPrice + getters.packagesTotalDiscountVATPrice;
    totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

    return totalVATPrice;
  },
  cartTotalDiscountVAT: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let cartTotalVAT = getters.cartTotalDiscountVATPrice - getters.cartTotalDiscountPrice;
    cartTotalVAT = cartTotalVAT > 0 ? Math.round((cartTotalVAT + Number.EPSILON) * 100) / 100 : 0;

    return cartTotalVAT;
  },
  cartTotalVAT: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let cartTotalVAT = getters.cartTotalVATPrice - getters.cartTotalPrice;
    cartTotalVAT = cartTotalVAT > 0 ? Math.round((cartTotalVAT + Number.EPSILON) * 100) / 100 : 0;

    return cartTotalVAT;
  },
  cartTotalDiscountCalcVAT: (state, getters, rootState, rootGetters) => {
    if (!state.cart) {
      return 0;
    }

    let VATPercent = rootGetters['general_settings/VAT_percent'];
    let cartTotalDiscountVATPrice = getters.cartTotalDiscountVATPrice;

    let cartTotalDiscountCalcVAT = cartTotalDiscountVATPrice * VATPercent / (100 + VATPercent);
    cartTotalDiscountCalcVAT = cartTotalDiscountCalcVAT > 0 ? Math.round((cartTotalDiscountCalcVAT + Number.EPSILON) * 100) / 100 : 0;

    return cartTotalDiscountCalcVAT;
  },
  cartTotalCalcVAT: (state, getters, rootState, rootGetters) => {
    if (!state.cart) {
      return 0;
    }

    let VATPercent = rootGetters['general_settings/VAT_percent'];
    let cartTotalVATPrice = getters.cartTotalVATPrice;

    let cartTotalCalcVAT = cartTotalVATPrice * VATPercent / (100 + VATPercent);
    cartTotalCalcVAT = cartTotalCalcVAT > 0 ? Math.round((cartTotalCalcVAT + Number.EPSILON) * 100) / 100 : 0;

    return cartTotalCalcVAT;
  },
  cartTotalDiscount: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let cartTotalDiscount = /*getters.productsTotalDiscount + */getters.packagesTotalDiscount;
    cartTotalDiscount = cartTotalDiscount > 0 ? Math.round((cartTotalDiscount + Number.EPSILON) * 100) / 100 : 0;

    return cartTotalDiscount;
  },
  cartTotalUniqueCount: (state, getters) => {
    if (!state.cart) {
      return 0;
    }

    let cartTotalUniqueCount = getters.productsUniqueCount + getters.packagesUniqueCount;

    return cartTotalUniqueCount;
  },
  createPostOrderData: (state) => {
    if (!state.cart) {
      return 0;
    }

    let postOrderData = JSON.parse(JSON.stringify(state.cart));
    for (const company in postOrderData) {
      for (const ptype in postOrderData[company]) {
        postOrderData[company][ptype] = Object.values(postOrderData[company][ptype]).map(pack => {
          return {id: pack.id, quantity: pack.quantity}
        });
      }
    }

    postOrderData = {
      order: postOrderData
    }

    return postOrderData;
  },

  productsTotalDiscountVATPriceByCompany(state) {
    return function (company) {
      if (!state.cart) {
        return 0;
      }

      let totalVATPrice = 0;

      if (state.cart[company] && state.cart[company].products) {
        for (const product_id in state.cart[company].products) {
          const item = state.cart[company].products[product_id];
          totalVATPrice += item.quantity * (item.qtySteps && item.qtySteps > 0 ? item.qtySteps : 1) * item.DISCOUNT_VATPRICE;
        }
      }

      totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

      return totalVATPrice;
    }
  },
  packagesTotalDiscountVATPriceByCompany(state) {
    return function (company) {
      if (!state.cart) {
        return 0;
      }

      let totalVATPrice = 0;

      if (state.cart[company] && state.cart[company].packages) {
        for (const package_id in state.cart[company].packages) {
          const item = state.cart[company].packages[package_id];
          totalVATPrice += item.quantity * item.DISCOUNT_VATPRICE;
        }
      }

      totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

      return totalVATPrice;
    }
  },
  cartTotalDiscountVATPriceByCompany(state, getters) {
    return function (company) {
      if (!state.cart) {
        return 0;
      }

      let totalVATPrice = 0;

      totalVATPrice += getters.productsTotalDiscountVATPriceByCompany(company) + getters.packagesTotalDiscountVATPriceByCompany(company);
      totalVATPrice = Math.round((totalVATPrice + Number.EPSILON) * 100) / 100;

      return totalVATPrice;
    }
  },
  minOrderAmountError(state, getters, rootState, rootGetters) {
    if (!state.cart) {
      return true;
    }

    const minOrderAmount = rootGetters['shaniv_customer/minOrderAmount'] || 500; // min by company
    let minOrderAmountError = false;

    for (const company in state.cart) {
      if (getters.cartTotalDiscountVATPriceByCompany(company) < minOrderAmount) {
        minOrderAmountError = true;
        break;
      }
    }

    return minOrderAmountError;
  }
};

export const cartSystem = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
