import Vue from 'vue';
import { supplierService } from '../services';
import { productService } from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
    loading: false,
    supplier_items: {},
    suppliers: [],
    suppliers_products: [],
    product_tags: [],
    product_bestseller_tag_id: null,
    product_special_tag_id: null,
    product_internet_tag_id: null,
    product_recommended_tag_id: null,
    supplierData: {},
    special_products: [],
    product_data: {},
    active_sims_data: []
}
const actions = {
    getAll({ commit }, {params} = {}) {
        commit('getAllRequest');

        return supplierService.getAll(params)
            .then( 
                items =>  {
                    commit('getAllSuccess', items)
                    actions.getSuppliersProducts({commit}).then(() => {
                        for (let supplier_type in items) {
                            let supplier_type_items = items[supplier_type]
                            for (let supplier_index in supplier_type_items) {
                                commit('setSupplier', {supplier_id: supplier_type_items[supplier_index].id, supplier_data: supplier_type_items[supplier_index]})
                            }
                        }
                        commit('setLoadingFalse');
                    })
                },
                error => commit('getAllFailure', error)
            );
    },
    getAllSupplierItems({ commit }, {params} = {}) {
        commit('getAllRequest');

        return supplierService.getAll(params)
            .then(
                items => {
                    commit('getAllSuccess', items);
                    commit('setLoadingFalse');
                },
                error => commit('getAllFailure', error)
            );
    },
    getSupplier({ commit }, {supplier_id}) {
        commit('setLoadingTrue');
        supplierService.getSupplier(supplier_id)
            .then(
                supplier_data => {
                    commit('setSupplier', {supplier_id: supplier_id, supplier_data: supplier_data});
                    commit('setLoadingFalse');
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    getSupplierDataById({ commit }, {supplier_id}) {
        commit('setLoadingTrue');
        return supplierService.getSupplierDataById(supplier_id)
            .then(
                supplier_data => {
                    commit('setSupplierData', {supplier_data: supplier_data});
                    commit('setLoadingFalse');
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    async getSuppliersProducts({ commit }) {
        for (let supplier_type in state.supplier_items) {
            let supplier_type_items = state.supplier_items[supplier_type]
            let promises = []
            for (let supplier_index in supplier_type_items) {
                let supplier = supplier_type_items[supplier_index]
                if (supplier && supplier.id) {                    
                    promises.push(actions.getSupplierProducts({commit}, {supplier_id: supplier.id}))
                }
            }
            await Promise.all(promises)
        }
    },
    getSupplierProducts({ commit }, {supplier_id, params}) {
        commit('setLoadingTrue');
        return productService.getSupplierProducts(supplier_id, params)
            .then(
                items => {
                    // commit('setLoadingFalse');
                    commit('setSupplierProducts', {supplier_id, items})
                },
                error => {
                    commit('setSupplierProducts', {supplier_id, items: []})
                    // commit('setLoadingFalse');
                }
            );
    },
    getProductTags({ commit }) {
        productService.getProductTags()
            .then(
                tags => {
                    commit('setProductTags', tags)
                },
                error => commit('setProductTags', [])
            );
    },
    setFavoriteProduct({ commit }, {product_id, supplier_id, favorite}) {
        commit('setLoadingTrue');
        supplierService.setFavoriteProduct(product_id, favorite)
            .then(
                receipt_data => {
                    actions.getSupplierProducts({commit}, {supplier_id: supplier_id})
                    commit('setLoadingFalse');
                    // dispatch('getDestination');
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    updateSupplierDataById({ dispatch, commit }, {supplier_id, supplier_data}) {
        commit('setLoadingTrue');
        return supplierService.updateSupplierDataById(supplier_id, supplier_data)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    // dispatch('alert/success', i18n.t('Operation Success'), {root: true});
                },
                error => {
                    commit('setLoadingFalse');
                    // dispatch('alert/error', (result_data.error || 'Something went wrong'), {root: true});
                }
            );
    },
    getProductsById({ commit }, {supplier_id}) {
        commit('setLoadingTrue');
        return supplierService.getProductsById(supplier_id)
            .then(
                supplier_data => {
                    commit('setSpecialProducts', {products: supplier_data});
                    commit('setLoadingFalse');
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    getProductDataById({ commit }, {product_id}) {
        commit('setLoadingTrue');
        return productService.getProduct(product_id)
            .then(
                product_data => {
                    commit('setProductData', {product_data: product_data});
                    commit('setLoadingFalse');
                },
                error => {
                    commit('setLoadingFalse');
                }
            );
    },
    updateProduct({ dispatch, commit }, {id, params}) {
        commit('setLoadingTrue');
        return productService.updateProduct(id, params)
            .then(
                receipt_data => {
                    dispatch('alert/success', '', {root: true});
                    commit('setLoadingFalse');
                },
                error => {
                    commit('setLoadingFalse');
                    dispatch('alert/error', error, {root: true});
                }
            );
    },
    syncSupplierProducts({ dispatch, commit }, {supplier_id}) {
        try {
            commit('setLoadingTrue');

            return supplierService.syncSupplierProducts(supplier_id)
                .then(
                    data => {
                        commit('setLoadingFalse');
                        dispatch('alert/success', i18n.t('Sync started'), {root: true});
                    },
                    error => {
                        commit('setLoadingFalse');
                        dispatch('alert/error', ('Something went wrong'), {root: true});
                    }
                );
        } catch (e) {
            commit('setLoadingFalse');
        }
    },

    updateOrder({ commit }, params) {
        commit('setLoadingTrue');
        return supplierService.updateOrder(params)
            .then(
                receipt_data => {
                    console.log(receipt_data);
                    commit('setLoadingFalse');
                    Vue.swal({
                        icon: 'success',
                        title: i18n.t(receipt_data.message.title),
                        text: i18n.t(receipt_data.message.text),
                    });
                },
                error => {
                    console.log('error:', error);
                    commit('setLoadingFalse');
                }
            );
    },
    activeSIMRamiLevi({ commit }, params) {
        commit('setLoadingTrue');
        return supplierService.activeSIMRamiLevi(params)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    const response_title = receipt_data.message.title;
                    Vue.swal({
                        icon: response_title == "Success" ? 'success' : 'error',
                        title: i18n.t(response_title),
                        text: i18n.t(receipt_data.message.text),
                    });
                },
                error => {
                    console.log('error:', error);
                    commit('setLoadingFalse');
                }
            );
    },
    sendVerifyCodeRamiLevi({ commit }, params) {
        commit('setLoadingTrue');
        return supplierService.sendVerifyCodeRamiLevi(params)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    const response_title = receipt_data.message.title;
                    Vue.swal({
                        icon: response_title == "Success" ? 'success' : 'error',
                        title: i18n.t(response_title),
                        text: i18n.t(receipt_data.message.text),
                    });
                },
                error => {
                    console.log('error:', error);
                    commit('setLoadingFalse');
                }
            );
    },
    numberPortingRamiLevi({ commit }, params) {
        commit('setLoadingTrue');
        return supplierService.numberPortingRamiLevi(params)
            .then(
                receipt_data => {
                    commit('setLoadingFalse');
                    const response_title = receipt_data.message.title;
                    Vue.swal({
                        icon: response_title == "Success" ? 'success' : 'error',
                        title: i18n.t(response_title),
                        text: i18n.t(receipt_data.message.text),
                    });
                },
                error => {
                    console.log('error:', error);
                    commit('setLoadingFalse');
                }
            );
    },
    getActiveSims({ commit }) {
        commit('setLoadingTrue');
        return supplierService.getActiveSims()
            .then(
                receipt_data => {
                    commit('setActiveSimsData', receipt_data);
                    commit('setLoadingFalse');
                },
                error => {
                    console.log('error:', error);
                    commit('setLoadingFalse');
                }
            );
    },
};

const mutations = {
    setLoadingTrue(state) {
        state.loading = true;
    },
    setLoadingFalse(state) {
        state.loading = false;
    },
    getAllRequest(state) {
        state.loading = true;
    },
    getAllSuccess(state, items) {
        // state.loading = false;
        state.supplier_items = items;
    },
    getAllFailure(state, error) {
        state.loading = false;
        state.supplier_items = [];
    },
    setSupplierProducts(state, {supplier_id, items}) {
        let suppliers_products = state.suppliers_products || {};
        suppliers_products[supplier_id] = items;
        state.suppliers_products = null;
        state.suppliers_products = suppliers_products;
    },
    setProductTags(state, tags) {
        state.product_tags = tags;
        for (let tag_index in tags) {
            if (tags[tag_index].name == 'הנמכרים ביותר' || tags[tag_index].name == 'best sellers'
                || tags[tag_index].name == 'bestsellers') {
                state.product_bestseller_tag_id = tags[tag_index].id;
            } else if (tags[tag_index].name == 'מבצעים' || tags[tag_index].name == 'promotions'
                || tags[tag_index].name == 'special') {
                state.product_special_tag_id = tags[tag_index].id
            } else if (tags[tag_index].name == 'אינטרנט' || tags[tag_index].name == 'internet') {
                state.product_internet_tag_id = tags[tag_index].id
            } else if (tags[tag_index].name == 'מומלצים' || tags[tag_index].name == 'recommended') {
                state.product_recommended_tag_id = tags[tag_index].id
            }
        }
    },
    setSupplier(state, {supplier_id, supplier_data}) {
        let suppliers = state.suppliers || []
        suppliers[supplier_id] = supplier_data
        state.suppliers = null;
        state.suppliers = suppliers;
    },
    setSupplierData(state, {supplier_data}) {
        state.supplierData = supplier_data;
    },
    setSpecialProducts(state, {products}) {
        state.special_products = products;
    },
    setProductData(state, {product_data}) {
        state.product_data = product_data;
    },
    setActiveSimsData(state, data) {
        state.active_sims_data = data;
    }
};

export const supplier = {
    namespaced: true,
    state,
    actions,
    mutations
};