import { tag_managementService } from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
  tags: [],
  loading: false
};

const actions = {
  getTags({ commit }) {
    commit('setLoadingTrue');
    return tag_managementService.getData()
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setTagsData', { receipt_data: receipt_data })
          }
        },
        // error => {}
      );
  },
  createTag({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return tag_managementService.createTag(params)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            dispatch('alert/success', i18n.t('Operation Success'), {root: true});
          }
        },
        error => {
          commit('setLoadingFalse');
          dispatch('alert/error', (error || i18n.t('Error')), {root: true});
        }
      );
  },
  updateTag({ dispatch, commit }, { id, params }) {
    commit('setLoadingTrue');
    return tag_managementService.updateTag(id, params)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            dispatch('alert/success', i18n.t('Operation Success'), {root: true});
          }
        },
        error => {
          commit('setLoadingFalse');
          dispatch('alert/error', (error || i18n.t('Error')), {root: true});
        }
      );
  },
  deleteTag({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return tag_managementService.deleteTag(id)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setUpdatedTagsData', { id: id });
            dispatch('alert/success', i18n.t('Operation Success'), {root: true});
          }
        },
        error => {
          commit('setLoadingFalse');
          dispatch('alert/error', (error || i18n.t('Error')), {root: true});
        }
      );
  },
  filterTag({ commit }, { type }) {
    commit('setLoadingTrue');
    return tag_managementService.filterTag(type)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setTagsData', { receipt_data: receipt_data });
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },

};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setTagsData(state, { receipt_data }) {
    state.tags = receipt_data;
  },
  setUpdatedTagsData(state, { id }) {
    state.tags = state.tags.filter(function( obj ) {
      return obj.id !== id;
    });
  }
};

export const tag_management = {
  namespaced: true,
  state,
  actions,
  mutations
};