import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const tourOrderService = {
    getPassportDataFromImage,
    getTransactionData,
    confirmTransaction
};

function getPassportDataFromImage(scan_images) {
    let params = scan_images;
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/public/parse_passport',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function confirmTransaction(id, cData) {
    let params = cData;
    const config = {
        method: 'POST',
        url: appConfig.apiUrl + '/tour/order/' + id + '/confirmation',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function getTransactionData(id, phoneNumber) {
    let params = {};
    if (phoneNumber != "") {
        params = {
            "phoneNumber": phoneNumber
        };
    }
   
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/tour/order/' + id,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}