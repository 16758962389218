import {generalSettingsService} from '../services';

const state = {
  general_settings: {},
  VAT_percent: 17,
  VAT_percent_loaded: false,
  loading: false
};

const actions = {
  getVATPercent({ commit }) {
    commit('setLoadingTrue');
    return generalSettingsService.getVATPercent()
      .then(
        VATPercent => {
          commit('setLoadingFalse');
          if (VATPercent.vat) {
            commit('setVATPercent', { VATPercent: VATPercent.vat });
            commit('setVATPercentLoadedTrue');
          }

          return VATPercent;
        },
        error => {
          commit('setLoadingFalse');
          commit('setVATPercentLoadedFalse');
          return Promise.reject(error);
        }
      ).catch(error => {
        commit('setLoadingFalse');
        commit('setVATPercentLoadedFalse');
        return Promise.reject(error);
      });
  },
  updateVAT({ dispatch, commit }, params) {
    commit('setLoadingTrue');
    return generalSettingsService.updateVAT(params).then(
      (receipt_data) => {
        commit('setLoadingFalse');
        if (receipt_data) {
          dispatch('alert/success', '', { root: true });
        }
      },
      (error) => {
        commit('setLoadingFalse');
      },
    );
  },
};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setVATPercentLoadedTrue(state) {
    state.VAT_percent_loaded = true;
  },
  setVATPercentLoadedFalse(state) {
    state.VAT_percent_loaded = false;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setSettingData(state, { general_settings }) {
    state.general_settings = general_settings;
  },
  setVATPercent(state, { VATPercent }) {
    state.VAT_percent = VATPercent;
    state.general_settings = {...state.general_settings, VAT_percent: VATPercent};
  }
};

const getters = {
  VAT_percent: (state) => state.VAT_percent,
};

export const general_settings = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};