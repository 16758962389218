import appConfig from '../appConfig';
import axios from 'axios';
import router from '../router';

export const settingService = {
  updateGeneralSetting,
  getData,
  updatePassportSelect,
	getPassportSelect,
};

function updateGeneralSetting(settings) {
  let params = settings;
  const config = {
    method: 'PUT',
    url: appConfig.apiUrl + '/tour/setting',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
};

function getData() {
  let params = {};
  const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/tour/setting/',
    headers: {
      'Content-Type': 'application/json',
    },
    params: params,
    data: params,
    withCredentials: 1,
  };

  return axios(config)
    .then((res) => {
      if (res.status !== 200) {
        const err = (res.data && res.data.message) || res.statusText;
        return Promise.reject(err);
      }

      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        router.push('/login');
      }

      return Promise.reject(err);
    });
};

function updatePassportSelect(settings) {
  const config = {
    method: 'PUT',
    url: appConfig.apiUrl + '/tour/setting/passport',
    headers: {
      'Content-Type': 'application/json',
    },
    data: settings,
    withCredentials: 1,
  };

  return axios(config);
};

function getPassportSelect() {
	const config = {
    method: 'GET',
    url: appConfig.apiUrl + '/tour/setting/passport',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: 1,
  };

  return axios(config);
};
