import { resetService } from "../services";

const state = {
    suffixPhone: "",
    enterNumber: true,
    verificationCode: "",
    code: "",
    newPassword: "",

    error: null
}

const actions = {
    changeSuffix({ commit }, {loginName}) {
        return resetService.changeSuffix(loginName).then(
            (suffixRes) => {
                commit('setSuffixPhone', {suffixPhone: suffixRes});
            },
            (error) => {
                commit('setError', 'error');
            },
        );
    },
    handleApprove({ commit }, {loginName, phone_number}) {
        commit('setEnterPhone')
        console.log(loginName,phone_number)
        return resetService.handleApprove(loginName, phone_number).then(
            (numberRes) => {
                //
            },
            (error) => {
                commit('setError', 'error');
            },
        );
    },
    handleVerificationCode({ commit }, {loginName, verificationCode}) {
        return resetService.handleVerificationCode(loginName, verificationCode).then(
            (codeRes) => {
                commit('getVerification', {code: codeRes});
            },
            (error) => {
                commit('setError', 'error');
            },
        );
    },
    handleNewPassword({ commit }, {loginName, verificationCode, newPassword}) {
        return resetService.handleNewPassword(loginName, verificationCode, newPassword).then(
            (codeRes) => {
               //
            },
            (error) => {
                commit('setError', 'error');
            },
        );
    }
}

const mutations = {
    setSuffixPhone(state, { suffixPhone }) {
        state.suffixPhone = suffixPhone;
    },
    enterPhoneNumber(state, {enterNumber}) {
        state.enterNumber = enterNumber;
    },
    getVerification(state, {code}) {
        state.code = code;
    },
    setNewPassword(state, {code}) {
        state.code = code;
    },
    setEnterPhone(state) {     
        state.enterNumber = false;
    },
    setError(state, {error}) {
        state.error = error;
    }
}

export const reset_module = {
    namespaced: true,
    state,
    actions,
    mutations,
};
