import {bannerService} from '../services';

const state = {
    bannersData: [],
    bannersListData: []
}
const actions = {
    getBanners({ commit }) {
        return bannerService.getBanners()
            .then(
                banners_data => {
                    if (banners_data) {
                        commit('setBannersData', {banners_data: banners_data})
                    }
                },
                // error => {}
            );
    },
    getBannersList({ commit }, {params} = {}) {
        return bannerService.getBannersList(params)
            .then(
                banners_data => {
                    if (banners_data) {
                        commit('setBannersListData', {banners_data: banners_data, params: params})
                    }
                },
                // error => {}
            );
    },
};

const mutations = {
    setBannersData(state, {banners_data}) {
        let bannersData = banners_data || [];
        state.bannersData = null;
        state.bannersData = bannersData;
    },
    setBannersListData(state, {banners_data, params}) {
        banners_data = banners_data || [];
        let locations = params && params.locations ? params.locations : [];
        if (locations && !Array.isArray(locations)) {
            locations = locations.split(',');
        }

        if (locations.length > 0) {
            let bannersListData = state.bannersListData;
            for (let i in bannersListData) {
                if (bannersListData[i].locations.filter(l => locations.includes(l)).length === 0) {
                    continue;
                }

                let newWithSameId = banners_data.find(bd => bd.id === bannersListData[i].id);

                if (newWithSameId) {
                    bannersListData[i] = {...(newWithSameId)};
                    banners_data = banners_data.filter(bd => bd.id !== newWithSameId.id);
                } else {
                    bannersListData[i].locations = bannersListData[i].locations.filter(l => !locations.includes(l));
                }
            }

            bannersListData = bannersListData.concat(banners_data).filter(b => b.locations && b.locations.length > 0);

            state.bannersListData = null;
            state.bannersListData = bannersListData;
        } else {
            state.bannersListData = null;
            state.bannersListData = banners_data;
        }
    }
};

const getters = {
    locationBanners(state) {
        return function(location) {
            return state.bannersListData.filter(banner => banner.locations.includes(location))
        }
    }
};

export const banner = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};