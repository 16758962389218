const state = {
    deferredPrompt: null,
}

const actions = {
    setDeferredPrompt({ commit }, { deferredPrompt }) {
        commit('setDeferredPrompt', deferredPrompt);
    },
};

const mutations = {
    setDeferredPrompt(state, deferredPrompt) {
      state.deferredPrompt = deferredPrompt;
    }
};

export const add_to_home_screen = {
    namespaced: true,
    state,
    actions,
    mutations
};