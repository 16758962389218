import { userService } from '../services';
import Vue from 'vue';
import i18n from '../common/plugins/vue-i18n';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

const sid = Vue.$cookies.get('connect.sid');
const token = localStorage.getItem('token');
const state = {
    loggingIn: false,
    loggedIn: false,
    sid: sid ? sid : null,
    token: token ? token : null,
    user_details: null,
    user_id: "",
    isDistributor: false,
    isAdmin: false,
    isPtAdmin: false,
    isDistributorAdmin: false,
    isDistributorAgent: false,
    isEnduser: false,
    userId: null,
}

const actions = {
    login({ dispatch, commit }, authData) {
        commit('setLoggingInTrue');
    
        return userService.login(authData)
            .then(
                result => {
                    commit('setLoggingInFalse');
                    if (result && result.hasOwnProperty('message') && result.message === 'success') {
                        let sid = Vue.$cookies.get('connect.sid');
                        localStorage.setItem('connection.sid', sid)
                        commit('setLoggedInTrue');
                        commit('setSid', sid);
                        if (result && result.hasOwnProperty('token') && result.token !== '') {
                            commit('setToken', "result.token");
                            localStorage.setItem('token', 'result.token')
                        }
                        if (result && result.hasOwnProperty('is_distributor') && result.is_distributor !== '') {
                            commit('setIsDistributor', result.is_distributor);
                        }
                        if (result && result.hasOwnProperty('is_admin') && result.is_admin !== '') {
                            commit('setIsAdmin', result.is_admin);
                        }
                        if (result && result.hasOwnProperty('is_pt_admin') && result.is_pt_admin !== '') {
                            commit('setIsPtAdmin', result.is_pt_admin);
                        }
                        if (result && result.hasOwnProperty('is_distributor_admin') && result.is_distributor_admin !== '') {
                            commit('setIsDistributorAdmin', result.is_distributor_admin);
                        }
                        if (result && result.hasOwnProperty('is_distributor_agent') && result.is_distributor_agent !== '') {
                            commit('setIsDistributorAgent', result.is_distributor_agent);
                        }
                        if (result && result.hasOwnProperty('is_enduser') && result.is_enduser !== '') {
                            commit('setIsEnduser', result.is_enduser);
                        }
                        if (result && result.hasOwnProperty('id') && result.id !== '') {
                            commit('setUserId', result.id);
                        }
                    } else if (result && result.hasOwnProperty('message') && result.message === 'Check 2fa') {
                        commit('setUserId', result.user_id)
                    } else {
                        commit('setLoggedInFalse');
                    }

                    return result
                },
                error => {
                    if (error.response.status === 401) {
                        dispatch('alert/error', error.response.data.message, {root: true})
                    } else {
                        dispatch('alert/error', 'Something went wrong', {root: true})
                    }
                    commit('setLoggingInFalse');
                    commit('setLoggedInFalse');

                    return Promise.reject(error)
                }
            );
    },
    SendCode({ dispatch, commit }, {codeData, userType}) {
        commit('setLoggingInTrue');
        return userService.SendCode({codeData, userType})
        .then(
            result => {
                commit('setLoggingInFalse');
                return result
            },
            error => {
                commit('setLoggingInFalse');
                Vue.swal({
                    icon: 'error',
                    title: i18n.t(error.response.data.message.title) || i18n.t("Error"),
                    text: i18n.t(error.response.data.message.text),
                });
                return Promise.reject(error)
            }
        );
    },
    confirmVerifyCode({ dispatch, commit }, codeData) {
        commit('setLoggingInTrue');
        return userService.confirmVerifyCode(codeData)
        .then(
            result => {
                commit('setLoggingInFalse');
                if (result && result.hasOwnProperty('message') && result.message === 'success') {
                    commit('setLoggedInTrue');
                    if (result && result.hasOwnProperty('is_distributor') && result.is_distributor !== '') {
                        commit('setIsDistributor', result.is_distributor);
                    }
                    if (result && result.hasOwnProperty('is_admin') && result.is_admin !== '') {
                        commit('setIsAdmin', result.is_admin);
                    }
                    if (result && result.hasOwnProperty('is_pt_admin') && result.is_pt_admin !== '') {
                        commit('setIsPtAdmin', result.is_pt_admin);
                    }
                    if (result && result.hasOwnProperty('is_distributor_admin') && result.is_distributor_admin !== '') {
                        commit('setIsDistributorAdmin', result.is_distributor_admin);
                    }
                    if (result && result.hasOwnProperty('is_distributor_agent') && result.is_distributor_agent !== '') {
                        commit('setIsDistributorAgent', result.is_distributor_agent);
                    }
                    if (result && result.hasOwnProperty('is_enduser') && result.is_enduser !== '') {
                        commit('setIsEnduser', result.is_enduser);
                    }
                }
                return result
            },
            error => {
                commit('setLoggingInFalse');
                Vue.swal({
                    icon: 'error',
                    title: i18n.t(error.response.data.message.title)|| i18n.t("Error"),
                    text: i18n.t(error.response.data.message.text),
                });
                return Promise.reject(error)
            }
        );
    },
    logout({ dispatch, commit }) {
        return userService.logout().then(() => {
            commit('logout');
            if (Vue.$cookies.isKey('connect.sid')) {
                Vue.$cookies.remove('connect.sid');
                localStorage.removeItem("passengers");
            }
            commit('permission/clearPermissionsData', null, { root: true });
            localStorage.removeItem("permissions");
            localStorage.removeItem("user_type_logged_in");
            localStorage.removeItem("Cheapest_deals");
            dispatch('cartSystem/clearCart', null, {root: true});
        });
    },
    isAuth({ dispatch, commit }) {
        return userService.is_auth()
            .then(
              result => {
                    if (result && (result.result === true || result.result === 'true')) {
                        commit('setLoggedInTrue');
                        if (result && result.hasOwnProperty('is_distributor') && result.is_distributor !== '') {
                            commit('setIsDistributor', result.is_distributor);
                        }
                        if (result && result.hasOwnProperty('is_admin') && result.is_admin !== '') {
                            commit('setIsAdmin', result.is_admin);
                        }
                        if (result && result.hasOwnProperty('is_pt_admin') && result.is_pt_admin !== '') {
                            commit('setIsPtAdmin', result.is_pt_admin);
                        }
                        if (result && result.hasOwnProperty('is_distributor_admin') && result.is_distributor_admin !== '') {
                            commit('setIsDistributorAdmin', result.is_distributor_admin);
                        }
                        if (result && result.hasOwnProperty('is_distributor_agent') && result.is_distributor_agent !== '') {
                            commit('setIsDistributorAgent', result.is_distributor_agent);
                        }
                        if (result && result.hasOwnProperty('is_enduser') && result.is_enduser !== '') {
                            commit('setIsEnduser', result.is_enduser);
                        }
                        if (result && result.hasOwnProperty('user_id') && result.user_id !== '') {
                            commit('setUserId', result.user_id);
                        }
                    } else {
                        commit('logout');
                    }

                    return result
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    getUserDetails({ dispatch, commit }, { check_if_user_logged } = {}) {
        if (check_if_user_logged && !state.loggedIn) {
            return false
        }
        userService.user_details()
            .then(
                user_details => {
                    if (user_details) {
                        commit('setUserDetails', user_details.result);
                    } else {
                        commit('setUserDetails', null);
                    }
                },
                error => {
                    commit('setUserDetails', null);
                }
            );
    },
};

const mutations = {
    setLoggingInTrue(state) {
      state.loggingIn = true;
    },
    setLoggingInFalse(state) {
      state.loggingIn = false;
    },
    setLoggedInTrue(state) {
      state.loggedIn = true;
    },
    setLoggedInFalse(state) {
      state.loggedIn = false;
    },
    setSid(state, sid) {
      state.sid = sid;
    },
    setToken(state, token) {
      state.token = token;
    },
    logout(state) {
        state.loggingIn = false;
        state.loggedIn = false;
        state.isDistributor = false;
        state.isAdmin = false;
        state.isPtAdmin = false;
    },
    setUserDetails(state, user_details) {
        state.user_details = user_details;
    },
    setUserId(state, user_id) {
        state.user_id = user_id;
        state.userId = user_id;
    },
    setIsDistributor(state, is_distributor) {
        state.isDistributor = is_distributor;
    },
    setIsAdmin(state, is_admin) {
        state.isAdmin = is_admin;
    },
    setIsPtAdmin(state, is_pt_admin) {
        state.isPtAdmin = is_pt_admin;
    },
    setIsDistributorAdmin(state, is_distributor_admin) {
        state.isDistributorAdmin = is_distributor_admin;
    },
    setIsDistributorAgent(state, is_distributor_agent) {
        state.isDistributorAgent = is_distributor_agent;
    },
    setIsEnduser(state, is_enduser) {
        state.isEnduser = is_enduser;
    },
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};