import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const reseller_detailsService = {
    getPaymentTypes,
    updateBalance
};

function getPaymentTypes() {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/bank/payment_types',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}
function updateBalance(params) {
    const config = {
        method: 'PUT',
        url: appConfig.apiUrl + '/admin/business/update_balance',
        headers: {
            'Content-Type': 'application/json',
        },
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}