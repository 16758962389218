import {tourSupplierService} from '../services';

const state = {
    tourSupplierData_receipts: []
}
const actions = {
    getTourSupplier({ commit }) {
        return tourSupplierService.getTourSupplier()
            .then(
                receipt_data => {
                    if (receipt_data) {
                        commit('setTourSupplierData', {receipt_data: receipt_data})
                    }
                },
                // error => {}
            );
    },
    getTags({ commit }) {
        return tourSupplierService.getTags()
            .then(
                receipt_data => {
                    if (receipt_data) {
                        commit('setTagsData', {receipt_data: receipt_data})
                    }
                },
                // error => {}
            );
    },
    changeTagsState({commit}, {id, tag_data}) {
        commit('changeTagsState', {id: id, tag_data:tag_data})
    },
    addAirportstore({commit}, {id, airport_data}) {
        commit('addAirport', {id: id, airport_data:airport_data})
    },
    save_OneDes({dispatch, commit}, {id}) {
        let update_Data = state.tourSupplierData_receipts.filter(item => {
            return item.id == id;
        });
        tourSupplierService.saveTourSupplier(update_Data[0])
        .then(
            receipt_data => {
                if (receipt_data) {
                    // commit('setTagsData', {receipt_data: receipt_data})
                }
            },
            error => {
                dispatch('alert/error', '', {root: true});
                dispatch('gettourSupplier');
                return Promise.reject(error);

            }
        );
    },

    save_AllDes({dispatch, commit}) {
        // let update_Data = state.tourSupplierData_receipts.filter(item => {
        //     return item.id == id;
        // });
        tourSupplierService.saveAlltourSupplier(state.tourSupplierData_receipts)
        .then(
            receipt_data => {
                if (receipt_data) {
                    // commit('setTagsData', {receipt_data: receipt_data})
                }
            },
            error => {
                dispatch('alert/error', '', {root: true});
                dispatch('gettourSupplier');
                return Promise.reject(error);

            }
        );
    }

    
};

const mutations = {
    setTourSupplierData(state, {receipt_data}) {
        let tourSupplierData_receipts = state.tourSupplierData_receipts || []
        tourSupplierData_receipts = receipt_data
        state.tourSupplierData_receipts = null;
        state.tourSupplierData_receipts = tourSupplierData_receipts;
    },
    setTagsData(state, {receipt_data}) {
        let tagsData_receipts = state.tagsData_receipts || []
        tagsData_receipts = receipt_data
        state.tagsData_receipts = null;
        state.tagsData_receipts = tagsData_receipts;
    },
    changeTagsState(state, {id, tag_data}) {
        state.tourSupplierData_receipts.forEach(item => {
            if(item.id == id) {
                item.tags = tag_data;
            }
        })
    },
    addAirport(state, {id, airport_data}) {
        state.tourSupplierData_receipts.forEach(item => {
            if(item.id == id) {
                item.airports.push(airport_data);
            }
        })
    }

};

export const tourSupplier = {
    namespaced: true,
    state,
    actions,
    mutations
};