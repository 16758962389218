import appConfig from '../appConfig'
import axios from 'axios'
import router from "../router";

export const permissionService = {
    getUserPermissions
};

function getUserPermissions() {
    let params = {};
    const config = {
        method: 'GET',
        url: appConfig.apiUrl + '/permissions/user_permissions',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
        data: params,
        withCredentials: 1
    }

    return axios(config)
        .then(res => {
            if (res.status !== 200) {
                const err = (res.data && res.data.message) || res.statusText;
                return Promise.reject(err);
            }

            return res.data
        })
        .catch(err => {
            if (err.response.status === 401) {
                router.push('/login');
            }

            return Promise.reject(err);
        })
}