import { reseller_settingsService } from '../services';
import i18n from '../common/plugins/vue-i18n';

const state = {
  reseller_settings: [],
  reseller_one_setting: [],
  loading: false
};

const actions = {
  add_reseller_settings({ dispatch, commit }, { params }) {
    commit('setLoadingTrue');
    return reseller_settingsService.addSetting(params)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data == "Success") {
            dispatch('getAllData');
            dispatch('alert/success', '', {root: true});
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  update_reseller_settings({ dispatch, commit }, { id, params }) {
    commit('setLoadingTrue');
    return reseller_settingsService.updateSetting(id, params)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data == "Success") {
            dispatch('getAllData');
            dispatch('alert/success', '', {root: true});
          }
        },
        error => {
          commit('setLoadingFalse');
          console.log(error);
        }
      );
  },
  delete_reseller_settings({ dispatch, commit }, { id }) {
    commit('setLoadingTrue');
    return reseller_settingsService.delSetting(id)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data == "Success") {
            dispatch('getAllData');
            dispatch('alert/success', '', {root: true});
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  getOneSetting({ commit }, { id }) {
    commit('setLoadingTrue');
    return reseller_settingsService.getOneSetting(id)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setOneSettingData', { receipt_data: receipt_data })
          }
        },
        // error => {}
      );
  },
  getAllData({ commit }) {
    commit('setLoadingTrue');
    return reseller_settingsService.getAllData()
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setSettingData', { receipt_data: receipt_data })
          }
        },
        // error => {}
      );
  }
};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setSettingData(state, { receipt_data }) {
    let reseller_settings = state.reseller_settings || []
    reseller_settings = receipt_data
    state.reseller_settings = null;
    state.reseller_settings = reseller_settings;
  },
  setOneSettingData(state, { receipt_data }) {
    state.reseller_one_setting = receipt_data;
  }
};

export const reseller_settings = {
  namespaced: true,
  state,
  actions,
  mutations
};