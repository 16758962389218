import { tourOrderService } from '../services';
import i18n from '../common/plugins/vue-i18n';
import router from '../router/index';

const state = {
  passportData_receipts: [],
  passportManualData: [],
  passportOneData: [],
  passportOneManualData: [],
  passportImageTempData: [],
  transactionData: [],
  confirmData: [],
  loading: false,
  passportDetail: {}
};

const actions = {
  /* manage passport  */
  uploadPassImage({ dispatch, commit }, { id, scan_images }) {
    commit('setLoadingTrue');
    return tourOrderService.getPassportDataFromImage(scan_images)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setPassportImageTempData', { id: id, receipt_data: receipt_data });
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  },
  addUploadedData({commit}) {
    commit('setPassportData');
  },
  changePassImageData({ commit }, { mrz }) {
    commit('showPassportData', { mrz: mrz })
  },
  removePassData({ commit }, { mrz }) {
    commit('removePassportData', mrz)
  },
  addManualData({ commit }, { roomId, mdata }) {
    commit('addManualData', {roomId: roomId, mdata: mdata})
  },
  removePassMData({ commit }, { id }) {
    commit('removePassportMData', id)
  },
  getOneManualData({ commit }, { id }) {
    return commit('getOneManualData', id)
  },
  updatePassportMData({ commit }, { id, mdata }) {
    commit('updatePassportMData', { id:id, mdata:mdata });
  },
  updatePassportData({ commit }) {
    commit('updatePassportData');
  },
  setPassportDetail({ commit }, { pData }) {
    commit('setPassportDetail', { pData: pData })
  },
  /* end manage passport */

  /* get transation data */
  getTransactionData({ commit }, { id, phoneNumber }) {
    commit('setLoadingTrue');
    return tourOrderService.getTransactionData(id, phoneNumber)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            commit('setTransactionData', { receipt_data: receipt_data });
            // dispatch('alert/success', i18n.t('Operation Success'), {root: true});
          }
        },
        // error => {}
      );
  },

 /* confirm data */
  confirmTransaction({ dispatch, commit }, { id, cData }) {
    commit('setLoadingTrue');
    return tourOrderService.confirmTransaction(id, cData)
      .then(
        receipt_data => {
          commit('setLoadingFalse');
          if (receipt_data) {
            if(receipt_data.success) {
              commit('setConfirmData', { receipt_data: receipt_data });
            } else {
              dispatch('alert/error', receipt_data.error.message, {root: true})
            }
          }
        },
        error => {
          commit('setLoadingFalse');
        }
      );
  }
};

const mutations = {
  setLoadingTrue(state) {
    state.loading = true;
  },
  setLoadingFalse(state) {
    state.loading = false;
  },
  setPassportData(state) {
    const roomId = localStorage.getItem("roomId");
    if (typeof state.passportData_receipts[roomId] == "undefined") {
      state.passportData_receipts[roomId] = [];
    }
    state.passportImageTempData.forEach(el => {
      state.passportData_receipts[roomId].push(el);
    });
    const id = localStorage.getItem("transactionId");
    const phoneNumber = localStorage.getItem("phone_number");
    router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
  },
  
  setPassportImageTempData(state, { receipt_data }) {
    let BreakException = {};
    let isWrongImg = false;

    state.passportImageTempData = [];
    try {
      receipt_data.forEach(el => {
        if (el.MRZdata.result && el.MRZdata.result == "Correct MRZ") {
          /* check if dulicated upload passorts */
          let passNumber = 0;
          receipt_data.forEach(item => {
            
            if (item.MRZdata.MRZ) {
              if (el.MRZdata['document no'] == item.MRZdata['document no']) {
                passNumber = passNumber + 1;
                if (passNumber == 2) {
                  isWrongImg = true;
                  state.passportImageTempData = false;
                  throw BreakException;
                }
              }
            } else {
              isWrongImg = true;
              state.passportImageTempData = false;
              throw BreakException;
            }
          })
          /* check uploaded passport in old uploaded data */
          state.passportData_receipts.forEach(room => {
            if (typeof room != "undefined") {
              room.forEach(oldPassport => {
                if(oldPassport.passport_number == el.MRZdata['document no']) {
                  isWrongImg = true;
                  state.passportImageTempData = false;
                  throw BreakException;
                }
              })
            }
          })  
          /* check uploaded passport in old manual data */
          state.passportManualData.forEach(room => {
            if (typeof room != "undefined") {
              room.forEach(oldPassport => {
                if(oldPassport.passport_number == el.MRZdata['document no']) {
                  isWrongImg = true;
                  state.passportImageTempData = false;
                  throw BreakException;
                }
              })
            }
          })  

          el.isUploaded = true;
          let birthday = el.MRZdata['date of birth'];
          // el.birthday = birthday.substring(3, 5) + "-" + birthday.substring(0, 2) + "-" + birthday.substring(6, 8);
          el.birthday = moment(birthday, 'DD-MM-YYYY')._d;
          el.first_name = el.MRZdata['given_names'];
          el.gender = el.MRZdata.sex;
          el.id_number = el.MRZdata['other id'];
          el.last_name = el.MRZdata['last name'];
          el.passport_country = el.MRZdata.country;
          el.passport_number = el.MRZdata['document no'];
          let validity = el.MRZdata['expiry date'];
          // el.validity = validity.substring(3, 5) + "-" + validity.substring(0, 2) + "-" + validity.substring(6, 8);
          if (moment(validity, 'DD-MM-YYYY')._d != "Invalid Date") {
            el.validity = moment(validity, 'DD-MM-YYYY')._d;
          } else {
            el.validity = "";
          }
          
          state.passportImageTempData.push(el);
        } else {
          isWrongImg = true;
          state.passportImageTempData = false;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }

    if (!isWrongImg) {
      router.push({name: 'passport-detail', params: {id: "confirm"}})
    }
  },
  setNewPassportImageTempData(state, passData) {
    let tempData = passData.passData.parsed.fields;
    let stateTemp = [];
    state.passportImageTempData = [];
    stateTemp.birthday = convertDate(tempData.birthDate, 'birth')
    stateTemp.first_name = tempData.firstName;
    stateTemp.gender = tempData.sex == 'male' ? 'M': 'F';
    stateTemp.id_number = tempData.personalNumber.replace(/\s/g, '');
    stateTemp.last_name = tempData.lastName;
    stateTemp.passport_country = tempData.issuingState;
    stateTemp.passport_number = tempData.documentNumber;
    stateTemp.validity = convertDate(tempData.expirationDate, 'expired');
    state.passportImageTempData.push(stateTemp);
    router.push({name: 'passport-detail', params: {id: "confirm"}})
  },
  showPassportData(state, { mrz }) {
    const roomId = localStorage.getItem("roomId");
    const index = state.passportData_receipts[roomId].findIndex(passportData_receipts => passportData_receipts.MRZdata.MRZ == mrz);
    state.passportOneData = state.passportData_receipts[roomId][index];
    router.push({name: 'passport-detail', params: {id: "editUploaded"}});
  },
  removePassportData(state, mrz) {
    const roomId = localStorage.getItem("roomId");
    const index = state.passportData_receipts[roomId].findIndex(passportData_receipts => passportData_receipts.MRZdata.MRZ == mrz);
    state.passportData_receipts[roomId].splice(index, 1)
  },
  addManualData(state, { roomId, mdata}) {
    if (typeof state.passportManualData[roomId] == "undefined") {
      state.passportManualData[roomId] = [];
    } 
    state.passportManualData[roomId].push(mdata);
  },
  removePassportMData(state, id) {
    const roomId = localStorage.getItem("roomId");
    const index = state.passportManualData[roomId].findIndex(passportManualData => passportManualData.passport_number == id);
    state.passportManualData[roomId].splice(index, 1)
  },
  getOneManualData(state, id) {
    const roomId = localStorage.getItem("roomId");
    const index = state.passportManualData[roomId].findIndex(passportManualData => passportManualData.passport_number == id);
    state.passportOneManualData = state.passportManualData[roomId][index];
  },
  updatePassportMData(state, { id, mdata }) {
    const roomId = localStorage.getItem("roomId");
    const index = state.passportManualData[roomId].findIndex(passportManualData => passportManualData.passport_number == id);
    Object.assign(state.passportManualData[roomId][index], mdata);
  },
  updatePassportData(state) {
    const id = localStorage.getItem("transactionId");
    const phoneNumber = localStorage.getItem("phone_number");
    router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
  },
  setTransactionData(state, { receipt_data }) {
    state.transactionData = receipt_data
  },
  setConfirmData(state, { receipt_data }) {
    state.confirmData = receipt_data;
    const id = localStorage.getItem("transactionId");
    const phoneNumber = localStorage.getItem("phone_number");
    router.push({ name: 'tour-order6', params: { transaction_id: id, phone_number: phoneNumber } });
  },
  setPassportDetail(state, { pData }) {
    state.passportDetail = pData;
  }
};
const convertDate = (numDate, dType) => {
  let birthArr = numDate.match(/.{1,2}/g);
    let curYear = new Date().getFullYear();
    if(dType == 'birth'){
      if(parseInt(birthArr[0]) > (curYear-2000)){
        birthArr[0] = '19'+birthArr[0]; 
      } else {
        birthArr[0] = '20'+birthArr[0];
      }
    } else {
      birthArr[0] = '20'+birthArr[0];
    }
    return new Date(`${birthArr[0]}-${birthArr[1]}-${birthArr[2]}`)
}
export const tour_order = {
  namespaced: true,
  state,
  actions,
  mutations
};