//const WebSocket = require('ws');
/// JS SDK States.
var State;
(function(State) {
  State['DISCONNECTED'] = 'DISCONNECTED';
  State['READER_DISCONNECTED'] = 'READER_DISCONNECTED';
  State['NO_CARD'] = 'NO_CARD';
  State['READY'] = 'READY';
})(State || (State = {}));

var RavKavConnection = /** @class */ (function() {
  function RavKavConnection(commit, key, onStateChange, onSuccess, onFailure) {
    this.commit = commit;
    var _this = this;
    this.nextRequestId = 1;
    // We currently have no need for concurrent requests.
    this.pendingRequest = null;
    this.state = State.DISCONNECTED;
    this.onStateChange = onStateChange;
    this.ws = new WebSocket(key);
    this.ws.onopen = function() {
      onSuccess();
    };
    this.ws.onerror = function(e) {
      console.debug('[RAVKAV-LOADER] websocket error:', e);
      // If an error is raised during open it usually means the
      // endpoint is out of reach. Error can also be raised by
      // the ws object when the connection opens.
      if (!_this.isWebSocketClosedOrClosing()) {
        return;
      }
      // console.log('[RAVKAV-LOADER] websocket error:', e);

      _this.setState(State.DISCONNECTED);
      onFailure('CONNECTION_REFUSED' /* CONNECTION_REFUSED */, _this.commit);
    };
    this.ws.onclose = function() {
      _this.setState(State.DISCONNECTED);
    };
    this.ws.onmessage = function(e) {
      var message = JSON.parse(e.data);
      if (!message) {
        // console.log('[RAVKAV-LOADER] bad message:', message);
        return;
      }
      if (message.result) {
        _this.handleResponse(message);
      } else {
        _this.handleRequest(message);
      }
    };
  }
  RavKavConnection.connect = function(
    key,
    timeout,
    onStateChange,
    onSuccess,
    onFailure,
    commit,
  ) {
    var timeoutId = null;
    var timedout = false;
    var connection = new RavKavConnection(
      commit,
      key,
      onStateChange,
      function onConnectionSuccess() {
        if (!timedout) {
          if (timeoutId !== null) {
            clearTimeout(timeoutId);
          }
          onSuccess(connection);
        } else {
          // The connection was made after timeout was reached.
          // Neatly close.
          console.debug('[RAVKAV-LOADER] closing connection post timeout');
          connection.close();
        }
      },
      function onConnectionFailed() {
        if (!timedout) {
          if (timeoutId !== null) {
            clearTimeout(timeoutId);
          }

          onFailure('CONNECTION_REFUSED' /* CONNECTION_REFUSED */, commit);
        } else {
          // The connection failed after timeout was reached.
          // The timeout handler already called the callback.
        }
      },
    );
    timeoutId = setTimeout(function connectionTimeoutHandler() {
      timedout = true;
      onFailure('TIMEOUT' /* TIMEOUT */, commit);
    }, timeout);
  };
  RavKavConnection.prototype.isWebSocketClosedOrClosing = function() {
    return (
      [WebSocket.CLOSED, WebSocket.CLOSING].indexOf(this.ws.readyState) > -1
    );
  };
  RavKavConnection.prototype.setState = function(newState) {
    if (this.state === newState) {
      return;
    }
    this.state = newState;
    this.onStateChange(this.state, this.commit);
  };
  RavKavConnection.prototype.handleResponse = function(message) {
    // NOTE: Some SDKs send back the ID as a *string*, not as a number
    // like we had sent it; that's why have the `+`.
    if (
      this.pendingRequest === null ||
      +message.id !== this.pendingRequest.id
    ) {
      console.warn('[RAVKAV-LOADER] stray response:', message);
      return;
    }
    // The callback can throw, make sure we are clear first.
    clearTimeout(this.pendingRequest.timeoutId);
    var onFinished = this.pendingRequest.onFinished;
    this.pendingRequest = null;
    onFinished(message.result);
  };
  RavKavConnection.prototype.handleRequest = function(message) {
    switch (message.method) {
      case 'VERSION':
        // Ignore.
        break;
      case 'STATE':
        if (!(message.params.state in State)) {
          // console.log('[RAVKAV-LOADER] unknown state:', message);
          break;
        }
        if (message.params.state === 'DISCONNECTED') {
          this.close();
        }
        this.setState(message.params.state);
        break;
    }
  };
  RavKavConnection.prototype.createErrorMessage = function(
    cause,
    cause_args,
    retry_enabled,
  ) {
    return {
      finish_status: 'FAILED',
      cause: cause,
      cause_args: cause_args,
      retry_enabled: retry_enabled,
    };
  };
  RavKavConnection.prototype.execute = function(endpoint, timeout, onFinished) {
    var _this = this;
    if (this.state !== State.READY) {
      onFinished(this.createErrorMessage(1030 /* NotReady */, [], true));
      return;
    }
    if (this.pendingRequest !== null) {
      onFinished(this.createErrorMessage(1031 /* Busy */, [], true));
      return;
    }
    var id = this.nextRequestId++;
    var timeoutMessage = {
      id: id,
      result: this.createErrorMessage(1032 /* Timeout */, [], true),
    };
    var timeoutId = setTimeout(function() {
      _this.handleResponse(timeoutMessage);
    }, timeout);
    this.pendingRequest = {
      id: id,
      onFinished: onFinished,
      timeoutId: timeoutId,
    };
    this.ws.send(
      JSON.stringify({
        id: id,
        method: 'SAMSERVER_ENDPOINT',
        params: {
          endpoint: endpoint,
        },
      }),
    );
  };
  RavKavConnection.prototype.getState = function() {
    return this.state;
  };
  RavKavConnection.prototype.getDesktopActivationLink = function() {
    return 'ravkav:' + this.ws.url;
  };
  RavKavConnection.prototype.close = function() {
    this.onStateChange = function() {}; // Stop notifications
    if (!this.isWebSocketClosedOrClosing()) {
      this.ws.close();
    }
    if (this.pendingRequest) {
      clearTimeout(this.pendingRequest.timeoutId);
      var onFinished = this.pendingRequest.onFinished;
      this.pendingRequest = null;
      onFinished(this.createErrorMessage(1033 /* Disconnected */, [], true));
    }
    this.setState(State.DISCONNECTED);
  };
  return RavKavConnection;
})();

export default RavKavConnection;
